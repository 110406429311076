import { configureStore } from '@reduxjs/toolkit';
import userSlice from '../pages/user/redux/slice';
import listingSlice from './slice/global';
import authSlice from '../pages/auth/redux/slice';
import userTypeSlice from '../pages/user-type/redux/slice';
import packageSlice from '../pages/packages/redux/slice';
import designationSlice from '../pages/designation/redux/slice';
import profileSlice from '../pages/profile-settings/redux/slice';
import clientSlice from '../pages/administrator/redux/slice';
import stammdatenSlice from '../pages/stammdaten/redux/slice';
import lagarSlice from '../pages/lagar-management/redux/slice';
import wareHouseSlice from '../pages/warehouse/redux/slice';
import timeTrackingSlice from '../pages/time-tracking/redux/slice';
import citySlice from '../pages/city/redux/slice';

const store = configureStore({
    reducer: {
        listing: listingSlice,
        auth: authSlice,
        user: userSlice,
        userType: userTypeSlice,
        packages: packageSlice,
        designation: designationSlice,
        profile: profileSlice,
        client: clientSlice,
        stammdaten: stammdatenSlice,
        lagar: lagarSlice,
        wareHouse: wareHouseSlice,
        timeTracking: timeTrackingSlice,
        city: citySlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default store;
