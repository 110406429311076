import React, { Fragment, useEffect, useState } from 'react';

import { Button, Flex, Form, Row, Col, Input, Typography, Select, Divider, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getPackagesListing } from '../../redux/slice/global';
import { createClient, getByIdClient, getClient, updateClient, setIsSuccess, setIsUpdating } from './redux/slice';

import BackIcon from '../../components/icon/BackIcon';

import '../../styles/pages/profile-settings.scss';

const { Title, Text } = Typography;

const AddUpdateClient = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { id } = useParams();
    const { client, listing, auth } = useSelector(state => state);
    const [modalType, setModalType] = useState(false);
    const [packagesList, setPackagesList] = useState([]);

    // Get User Types
    useEffect(() => {
        getPackageLists();
    }, [])

    const getPackageLists = () => {
        dispatch(getPackagesListing());
    };

    useEffect(() => {
        if (listing.listing?.packages?.length > 0) {
            setPackagesList(listing.listing.packages);
        }
    }, [listing.listing.packages]);

    const handleOnFinish = (values) => {
        if (id) {
            dispatch(setIsUpdating(false));
        }
        dispatch(getClient({
            ...values,
            isImmutable: auth?.decodeToken?.organizer ? false : true,
            organizer: auth?.decodeToken?.organizer ? auth?.decodeToken?.organizer : id ? client?.client?.organizer : null,
            designation: auth?.decodeToken?.organizer ? values?.designation : null,
            employeeType: auth?.decodeToken?.organizer ? values?.employeeType : '',
            workType: auth?.decodeToken?.organizer ? values?.workType : '',
            holiday: auth?.decodeToken?.organizer ? values?.holiday : 0,
            weeklyWorkHours: auth?.decodeToken?.organizer ? values?.weeklyWorkHours : 0,
            userLimit: auth?.decodeToken?.organizer ? 0 : Number(values?.userLimit),
            address: { streetNo: values?.streetNo, city: values?.city, zip: values?.zip }
        }));
    };

    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(setIsUpdating(true));
            dispatch(getByIdClient(id));
        }
    }, [id]);

    useEffect(() => {
        if (Object.keys(client.client).length > 0) {
            if (!modalType) {
                dispatch(createClient(client.client));
            } else {
                if (client.isUpdating) {
                    form.setFieldsValue({ ...client.client, streetNo: client?.client?.address?.streetNo, city: client?.client?.address?.city, zip: client?.client?.address?.zip });
                } else {
                    dispatch(updateClient({ ...client.client, id: id }));
                }
            }
        }
        if (client.isSuccess) {
            handleClear();
            navigate('/client');
            setModalType(false);
            dispatch(setIsSuccess(false));
        }
    }, [client.isUpdating, client.client, client.isSuccess]);

    // Clear from 
    const handleClear = () => {
        form.resetFields();
    };

    return (
        <Fragment>
            <Flex vertical gap={24} className='general-settings'>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => navigate('/client')}
                    />
                </Flex>
                <Flex vertical gap={8} className='bg-white padding-2 rounded-1'>
                    <Flex>
                        <Title level={4} type='success'>{id ? 'Edit Client' : 'Add New Client'}</Title>
                    </Flex>
                    <Divider />
                    <Form
                        form={form}
                        name='client'
                        colon={true}
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        layout='vertical'
                        onFinish={handleOnFinish}
                    >
                        <Row gutter={16}>
                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Row gutter={24}>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24} >
                                        <Form.Item
                                            name='company'
                                            label='Company Name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Company is required!'
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder='Company'
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='firstName'
                                            label='First name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Firstname is required!'
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder='Firstname'
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='lastName'
                                            label='Last name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Lastname is required!'
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder='Lastname'
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='phone'
                                            label='Phone number'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Phonenumber is required!'
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder='Phone number'
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='email'
                                            label='Email'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Firstname is required!'
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'Invalid email!'
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder='Email'
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='package'
                                            label='Select Package'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select a package!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder='Please select package'
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={packagesList?.length > 0 && packagesList}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='userLimit'
                                            label='Maximum User'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'User limit is required!'
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                className='w-100'
                                                min={1}
                                                placeholder='Maximum User'
                                            />
                                        </Form.Item>
                                    </Col>
                                    {
                                        auth?.decodeToken?.organizer
                                        && <Fragment>
                                            <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    name='userDesignation'
                                                    label='User designation'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'User designation is required!'
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        placeholder='User designation'
                                                        options={[]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    name='userType'
                                                    label='User type'
                                                >
                                                    <Select
                                                        disabled
                                                        showSearch
                                                        allowClear
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        placeholder='User type'
                                                        options={[]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    name='employeeType'
                                                    label='Employee type'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Employee type is required!'
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        placeholder='Employee type'
                                                        options={[]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    name='holidatAllowance'
                                                    label='Holiday allowance'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Holiday allowance is required!'
                                                        }
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Holiday allowance'
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Fragment>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Title level={5} className='padding-bottom-2'>Address</Title>
                        <Row gutter={24}>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='streetNo'
                                    label='Street Number and Name'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Street number is required!'
                                        }
                                    ]}
                                >
                                    <Input
                                        showCount={false}
                                        placeholder='Street number and Name'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='city'
                                    label='City'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'City is required!'
                                        }
                                    ]}
                                >
                                    <Input
                                        placeholder='City'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='zip'
                                    label='Zip'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Zip code is required!'
                                        }
                                    ]}
                                >
                                    <Input
                                        placeholder='Zip'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            auth?.decodeToken?.organizer
                            && <Fragment>
                                <Title level={5} className='padding-bottom-2'>Shift Management</Title>
                                <Row gutter={24}>
                                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='workType'
                                            label='Work Type'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Wokr type is required!'
                                                }
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                }
                                                placeholder='Work type'
                                                options={[]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='totalWeeklyHours'
                                            label='Total Weekly Hours'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Weekly hours is required!'
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder='Total Weekly Hours'
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Fragment>
                        }
                        <Flex justify='flex-end' gap={10} wrap={true}>
                            <Button danger type='primary' style={{ width: '100%', maxWidth: '7.5rem' }} htmlType='button' onClick={() => navigate(-1)}>Cancel</Button>
                            <Button type='primary' htmlType='submit' loading={client?.loading}>Send Invite</Button>
                        </Flex>
                    </Form>
                </Flex>
            </Flex>
        </Fragment>
    );
};

export default AddUpdateClient;
