import * as React from 'react';

const CloseIcon = ({
    size = 24,
    color = '#A20417',
    role = 'default',
    style = {},
    ...Rest
}) => {

    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={styles}
            {...Rest}
        >
            <path
                d='M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.16992 14.8299L14.8299 9.16992'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.8299 14.8299L9.16992 9.16992'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default CloseIcon;
