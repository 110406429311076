import React, { Fragment, useEffect, useState } from 'react'

import { Button, Col, Divider, Flex, Form, Input, Row, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createStammProject, getStammdatenProjectData, getStammProject, getByIdProject, updateProject, setIsProjectSuccess, deleteProject } from './redux/slice';

import DataTable from '../../components/data-table';

import PlusIcon from '../../components/icon/PlusIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';
import EditIcon from '../../components/icon/EditIcon';
import CommonPopconfirm from '../../components/custom-popconfirm';
import CloseIcon from '../../components/icon/CloseIcon';

import { lightPrimary, lightSecondary } from '../../utils/theme/config';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

const Project = ({ form }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { stammdaten } = useSelector(state => state)

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [modalType, setModalType] = useState(false);
    const [stammProjectList, setStammProjectList] = useState([]);
    const [projectEditId, setProjectEditId] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);

    useEffect(() => {
        getStammProjectListData()
    }, [pageSize, page, sortBy, order, totalRecords])

    const getStammProjectListData = () => {
        if (stammdaten?.getEditStammUserId) {
            dispatch(getStammdatenProjectData({
                sortBy: sortBy,
                order: order,
                search: '',
                page: page,
                limit: pageSize,
                stammUser: stammdaten?.getEditStammUserId
            }))
        }
    }

    useEffect(() => {
        setStammProjectList(stammdaten?.stammdatenProjectDataLists)
        setTotalRecords(stammdaten?.projectTotalRecords)
    }, [stammdaten?.stammdatenProjectDataLists, stammdaten?.projectTotalRecords])

    useEffect(() => {
        if (Object.keys(stammdaten?.stammProject).length > 0) {
            if (!projectEditId) {
                dispatch(createStammProject({ stammUserData: stammdaten?.stammProject, organizerId: stammdaten?.getEditStammUserId }));
            } else {
                if (isUpdate) {
                    form.setFieldsValue({
                        ...stammdaten?.stammProject,
                        otherContact: stammdaten?.stammProject?.otherContact?.length > 0 ? stammdaten?.stammProject?.otherContact : [{ name: '', email: '', phone: '' }],
                        streetNo: stammdaten?.stammProject?.address?.streetNo,
                        city: stammdaten?.stammProject?.address?.city,
                        zip: stammdaten?.stammUser?.address?.zip
                    });
                } else {
                    dispatch(updateProject({ stammUserData: { ...stammdaten.stammProject, id: projectEditId }, organizerId: stammdaten?.getEditStammUserId }));
                }
            }
        }
        if (stammdaten.isProjectSuccess) {
            handleClear();
            setModalType(false);
            setProjectEditId('')
            dispatch(setIsProjectSuccess(false));
        }

    }, [stammdaten.isProjectUpdating, stammdaten.stammProject, stammdaten.isProjectSuccess, isUpdate])

    const handleEdit = (values) => {
        setModalType(true);
        setProjectEditId(values?._id);
        setIsUpdate(true)
        dispatch(getByIdProject(values?._id))
    }

    const columns = [
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'date',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'createdAt'),
            render: (_,record) => dayjs(record?.createdAt).format("DD-MM-YYYY"),
        },
        {
            title: 'Project Name',
            dataIndex: 'name',
            key: 'projectName',
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone',
            key: 'phoneNumber',
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Street name & no.',
            dataIndex: 'streetNo',
            key: 'streetNo',
            width: '250px',
            sorter: (_, __, order) => handleSortFunction(order, 'streetNo'),
            render: (_, record) => {
                return record?.address?.streetNo
            }
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'city'),
            render: (_, record) => {
                return record?.address?.city;
            }
        },
        {
            title: 'Zip',
            dataIndex: 'zip',
            key: 'zip',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'zip'),
            render: (_, record) => {
                return record?.address?.zip
            }
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'status',
            width: '150px',
            sorter: (_, __, order) => handleSortFunction(order, 'status'),
            render: (_, record) => {
                return (
                    <Fragment>
                        {

                            record?.isActive ?
                                <Tag color={lightPrimary} className='text-center'>Active</Tag> :
                                <Tag color={lightSecondary} className='text-center'>Inactive</Tag>
                        }
                    </Fragment>
                );
            }
        },
        {
            title: <Flex justify='center'><Text>Action</Text></Flex>,
            key: 'action',
            render: (_, record) => (
                <Fragment>
                    <Flex gap={8} justify='center' align='center'>
                        <EditIcon role='button' onClick={() => handleEdit(record)} />
                        {
                            record?.isActive ?
                                <CommonPopconfirm
                                    title='Deactivate the Project'
                                    description='Are you sure you want to deactivate this project?'
                                    icon={null}
                                    onConfirm={() => dispatch(deleteProject({
                                        id: record?._id,
                                        sortBy: '',
                                        order: '',
                                        search: '',
                                        page: page,
                                        limit: pageSize,
                                        stammUser: stammdaten?.getEditStammUserId
                                    }))}
                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                >
                                    <Button type='text' className='padding-1'><CloseIcon role='button' /></Button>
                                </CommonPopconfirm>
                                :
                                <CommonPopconfirm
                                    title='Activate the Project'
                                    description='Are you sure you want to activate this project?'
                                    icon={null}
                                    onConfirm={() => dispatch(deleteProject({
                                        id: record?._id,
                                        sortBy: '',
                                        order: '',
                                        search: '',
                                        page: page,
                                        limit: pageSize,
                                        stammUser: stammdaten?.getEditStammUserId
                                    }))}
                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                >
                                    <Button type='text' className='padding-1'><CloseIcon role='button' /></Button>
                                </CommonPopconfirm>

                        }
                    </Flex>
                </Fragment>
            ),
        },
    ];

    const onFinish = (values) => {
        if (modalType) {
            setIsUpdate(false)
        }
        dispatch(getStammProject({
            ...values,
            address: { streetNo: values?.streetNo, city: values?.city, zip: values?.zip },
            otherContact: values?.otherContact.filter(contact => contact.name && contact.phone && contact?.email),
            stammUser: stammdaten?.getEditStammUserId
        }));
    };

    const handleAdd = (add) => {
        const fieldsValue = form.getFieldsValue();
        const contacts = fieldsValue.otherContact || [];

        const lastContact = contacts[contacts.length - 1];

        if (!lastContact) {
            if ((!lastContact?.name || !lastContact?.email || !lastContact?.phone) && contacts?.length !== 0) {
                form.setFields(
                    [
                        {
                            name: ['otherContact', contacts.length - 1, 'name'],
                            errors: !lastContact?.name ? ['Please enter the contact person name'] : [],
                        },
                        {
                            name: ['otherContact', contacts.length - 1, 'email'],
                            errors: !lastContact?.email ? ['Please enter the contact email'] : [],
                        },
                        {
                            name: ['otherContact', contacts.length - 1, 'phone'],
                            errors: !lastContact?.phone ? ['Please enter the contact number'] : [],
                        },
                    ]
                );
            } else {
                add();
            }
        } else {
            add();
            form.setFields(
                [
                    {
                        name: ['otherContact', contacts.length - 1, 'name'],
                        errors: false,
                    },
                    {
                        name: ['otherContact', contacts.length - 1, 'email'],
                        errors: false,
                    },
                    {
                        name: ['otherContact', contacts.length - 1, 'phone'],
                        errors: false,
                    },
                ]
            );
        }
    };

    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    const handleClear = () => {
        form.resetFields();
    };

    return (
        <Fragment>
            <Title level={4} type='success' className='paddingY-1' >{projectEditId ? 'Edit' : 'Add'} Project</Title>
            <Divider />
            <Form
                form={form}
                name='user'
                layout='vertical'
                className='paddingY-2'
                onFinish={onFinish}
                requiredMark={(label, isRule) => {
                    return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                }}
            >
                <Row gutter={18}>
                    <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='name'
                            label='Project Name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Project Name is required!',
                                },
                            ]}
                        >
                            <Input
                                placeholder='Project Name'
                            />
                        </Form.Item>
                    </Col>

                    <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='phone'
                            label='Phone number'
                        >
                            <Input
                                placeholder='Phone number'
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Title level={5} className='padding-bottom-2'>Address</Title>
                <Row gutter={24}>
                    <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='streetNo'
                            label='Street number & Name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Street number & Name is required!'
                                }
                            ]}
                        >
                            <Input
                                showCount={false}
                                placeholder='Street number'
                            />
                        </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='city'
                            label='City'
                            rules={[
                                {
                                    required: true,
                                    message: 'City is required!'
                                }
                            ]}
                        >
                            <Input
                                placeholder='City'
                            />
                        </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='zip'
                            label='Zip'
                            rules={[
                                {
                                    required: true,
                                    message: 'Zip is required!'
                                }
                            ]}
                        >
                            <Input
                                placeholder='Zip'
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />

                <Row>
                    <Col xs={24} sm={24} lg={24} md={24} xl={24} xxl={24}>
                        <Form.List
                            name='otherContact'
                            initialValue={[
                                { name: '', email: '', phone: '' },
                            ]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    <Flex className='paddingY-1' align='center' justify='space-between'>
                                        <Title level={4} type='success' className='paddingY-1' >Add Multiple Contact</Title>

                                        <PlusIcon
                                            role='button'
                                            onClick={() => handleAdd(add)}
                                        />
                                    </Flex>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <Row key={key} gutter={20}>
                                            <Col xxl={7} xl={7} lg={7} md={7} sm={24} xs={24}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'name']}
                                                    label='Contact person name'
                                                >
                                                    <Input
                                                        placeholder='Please Enter'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xxl={7} xl={7} lg={7} md={7} sm={24} xs={24}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'email']}
                                                    label='Contact email'
                                                >
                                                    <Input
                                                        placeholder='Please Enter'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xxl={7} xl={7} lg={7} md={7} sm={24} xs={24}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'phone']}
                                                    label='Contact person number'
                                                >
                                                    <Input
                                                        type='tel'
                                                        placeholder='Please Enter'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xxl={2} xl={2} lg={8} md={2} sm={24} xs={24}>
                                                <Flex align='center' justify='center' className='h-100'>
                                                    <Button
                                                        type='danger'
                                                        icon={<DeleteIcon role='button' />}
                                                        onClick={() => remove(name)}
                                                    />
                                                </Flex>
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                            )}
                        </Form.List>

                        <Flex justify='flex-end' gap={10} wrap={true} className='paddingY-2'>
                            <Button danger type='primary' htmlType='button' onClick={() => navigate(-1)}>Cancel</Button>
                            <Button type='primary' htmlType='submit' loading={stammdaten?.loading}>Submit</Button>
                        </Flex>

                    </Col>
                </Row>
            </Form>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Flex className='padding-top-3'>
                        <DataTable
                            columns={columns}
                            pageSize={pageSize}
                            currentPage={page}
                            total={totalRecords}
                            scrollX={900}
                            showSorterTooltip={false}
                            isPagination={true}
                            handleOnChange={handleOnChange}
                            data={stammProjectList}
                            rowKey={(record) => record?._id}
                        />
                    </Flex>
                </Col>
            </Row>
        </Fragment>
    )
};

export default Project;
