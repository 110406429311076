import { Link } from 'react-router-dom';

import { Image } from 'antd';

import User from '../pages/user/User';
import AddUpdateUser from '../pages/user/AddUpdateUser';
import CheckMail from '../pages/auth/CheckMail';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';
import SignIn from '../pages/auth/SignIn';
import VerifyOTP from '../pages/auth/VerifyOTP';
import SetPassword from '../pages/auth/SetPassword';
import WelcomePage from '../pages/auth/WelcomePage';
import UserType from '../pages/user-type/UserType';
import UpdateUserType from '../pages/user-type/UpdateUserType';
import Packages from '../pages/packages/Packages';
import AddUpdatePackages from '../pages/packages/AddUpdatePackages';
import VerifyEmail from '../pages/auth/VerifyEmail';
import Administrator from '../pages/administrator/Administrator';
import AddUpdateAdministrator from '../pages/administrator/AddUpdateAdministrator';
import Designation from '../pages/designation/Designation';
import ProfileSettings from '../pages/profile-settings/ProfileSettings';
import Stammdaten from '../pages/stammdaten/Stammdaten';
import AddUpdateStammdaten from '../pages/stammdaten/AddUpdateStammdaten';
import EditDocument from '../pages/stammdaten/EditDocument';
import WarehouseManagement from '../pages/warehouse/WarehouseManagement';
import AddUdateWarehouse from '../pages/warehouse/AddUdateWarehouse';
import Lagar from '../pages/lagar-management/Lagar';
import TimeTracking from '../pages/time-tracking';
import City from '../pages/city/City';

import dashboardIcon from '../assets/images/dashboard.svg';
import userManagementIcon from '../assets/images/user-management.svg';
import administratorManagementIcon from '../assets/images/administrator-management.svg';
import packageIcon from '../assets/images/packages.svg';
import stammdatenIcon from '../assets/images/stammdaten.svg';
import whIcon from '../assets/images/WhIcon.svg';
import timeTrackingIcon from '../assets/images/timeTrackingIcon.svg'
import profileSettingsIcon from '../assets/images/profile-settings.svg';
import utilityIcon from '../assets/images/utilityIcon.svg';

export const privateRoutes = [
    {
        to: '/dashboard',
        element: <p>Dashboard</p>,
        children: [],
        label: <Link to='/dashboard'>Dashboard</Link>,
        key: '1',
        menu: 'Dashboard',
        icon: <Image src={dashboardIcon} preview={false} />,
    },
    {
        to: '/user',
        label: 'User Management',
        key: '2',
        menu: 'User Management',
        icon: <Image src={userManagementIcon} preview={false} />,
        children: [
            {
                to: '',
                element: <User />,
                label: <Link to='/user'>Users</Link>,
                menu: 'Users',
                key: '21'
            },
            {
                to: 'add',
                element: <AddUpdateUser />,
                label: false,
                menu: 'Users',
            },
            {
                to: 'edit/:id',
                element: <AddUpdateUser />,
                label: false,
                menu: 'Users',
            },
            {
                to: 'type',
                label: <Link to='/user/type'>User Type</Link>,
                menu: 'User Types',
                key: '22',
                element: <UserType />
            },
            {
                to: 'type/add',
                label: false,
                menu: 'User Types',
                element: <UpdateUserType />
            },
            {
                to: 'type/edit/:id',
                label: false,
                menu: 'User Types',
                element: <UpdateUserType />
            },
            {
                to: 'designation',
                label: <Link to='/user/designation'>Designation</Link>,
                menu: 'Designation',
                key: '23',
                element: <Designation />,
            },
        ],
    },
    {
        to: '/client',
        element: <Administrator />,
        children: [],
        label: <Link to='/client'>Client</Link>,
        key: '3',
        menu: 'Client',
        icon: <Image src={administratorManagementIcon} preview={false} />
    },
    {
        to: '/client/add',
        element: <AddUpdateAdministrator />,
        label: false,
        menu: 'Client',
        children: [],
    },
    {
        to: '/client/edit/:id',
        element: <AddUpdateAdministrator />,
        label: false,
        menu: 'Client',
        children: [],
    },
    {
        to: '/packages',
        element: <Packages />,
        children: [],
        label: <Link to='/packages'>Packages</Link>,
        key: '4',
        menu: 'Packages',
        icon: <Image src={packageIcon} preview={false} />
    },
    {
        to: '/packages/add',
        element: <AddUpdatePackages />,
        label: false,
        menu: 'Packages',
        children: [],
    },
    {
        to: '/packages/edit/:id',
        label: false,
        menu: 'Packages',
        element: <AddUpdatePackages />,
        children: [],
    },
    {
        to: '/stammdaten',
        label: 'Stammdaten',
        key: '5',
        menu: 'Stammdaten',
        icon: <Image src={stammdatenIcon} preview={false} />,
        children: [
            {
                to: '',
                element: <Stammdaten />,
                label: <Link to='/stammdaten'>Stamm User</Link>,
                menu: 'Stamm User',
                key: '51'
            },
            {
                to: 'add',
                element: <AddUpdateStammdaten />,
                label: false,
                menu: 'Stamm User',
            },
            {
                to: 'edit/:id',
                element: <AddUpdateStammdaten />,
                label: false,
                menu: 'Stamm User',
            },
            {
                to: 'document/edit/:id',
                element: <EditDocument />,
                label: false,
                menu: 'Stamm User',
            },
        ],
    },
    {
        to: 'warehouse-management',
        label: 'Warehouse Mgmt.',
        key: '6',
        menu: 'Warehouse',
        icon: <Image src={whIcon} preview={false} />,
        children: [
            {
                to: 'lagar',
                element: <Lagar />,
                label: <Link to='/warehouse-management/lagar'>Lagar</Link>,
                menu: 'Lagar',
                key: '61'
            },
            {
                to: 'wh-place',
                element: <WarehouseManagement />,
                label: <Link to='/warehouse-management/wh-place'>WH Place Mgmt.</Link>,
                menu: 'WH Place Mgmt.',
                key: '62'
            },
            {
                to: 'wh-place/add',
                element: <AddUdateWarehouse />,
                label: false,
                menu: 'WH Place Mgmt.',
            },
            {
                to: 'wh-place/edit/:id',
                element: <AddUdateWarehouse />,
                label: false,
                menu: 'WH Place Mgmt.',
            },
        ]
    },
    {
        to: '/time-tracking',
        element: <TimeTracking />,
        children: [],
        label: <Link to='/time-tracking'>Time Tracking Mgmt.</Link>,
        key: '7',
        menu: 'Time Tracking Mgmt.',
        icon: <Image src={timeTrackingIcon} preview={false} />
    },
    {
        to: '/profile-settings',
        element: <ProfileSettings />,
        children: [],
        label: <Link to='/profile-settings'>Profile Settings</Link>,
        key: '8',
        menu: 'Profile Settings',
        icon: <Image src={profileSettingsIcon} preview={false} />
    },
    {
        to: '/utility',
        label: 'Utility',
        key: '9',
        menu: 'Utility',
        icon: <Image src={utilityIcon} preview={false} />,
        children: [
            {
                to: 'city',
                element: <City />,
                label: <Link to='/utility/city'>City</Link>,
                menu: 'City',
                key: '91'
            },
        ],
    },
];

export const publicRoutes = [
    {
        to: 'user/authentication/sign-in',
        element: <SignIn />
    },
    {
        to: 'user/authentication/forgot-password',
        element: <ForgotPassword />
    },
    {
        to: 'user/authentication/verify-otp/:id',
        element: <VerifyOTP />
    },
    {
        to: 'user/authentication/reset-password/:id/:token',
        element: <ResetPassword />
    },
    {
        to: 'user/authentication/check-email',
        element: <CheckMail />
    },
    {
        to: 'user/authentication/set-password',
        element: <SetPassword />
    },
    {
        to: 'user/welcome',
        element: <WelcomePage />
    },
    {
        to: 'user/authentication/verify-email/:id/:token',
        element: <VerifyEmail />
    }
];