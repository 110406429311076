import React from 'react'

const DocumentIcon = ({
    size = 24,
    color = '#FF0000',
    role = 'default',
    style = {},
    ...Rest
}) => {
    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };
    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={styles}
            {...Rest}
        >
            <path
                d='M13.1658 1.84207C12.8241 1.5004 12.2324 1.73373 12.2324 2.20873V5.11707C12.2324 6.33373 13.2658 7.34207 14.5241 7.34207C15.3158 7.3504 16.4158 7.3504 17.3574 7.3504C17.8324 7.3504 18.0824 6.79207 17.7491 6.45873C16.5491 5.2504 14.3991 3.0754 13.1658 1.84207Z'
                fill={color}
            />
            <path
                d='M17.084 8.49297H14.6757C12.7007 8.49297 11.0923 6.88463 11.0923 4.90964V2.5013C11.0923 2.04297 10.7173 1.66797 10.259 1.66797H6.72565C4.15898 1.66797 2.08398 3.33464 2.08398 6.30964V13.693C2.08398 16.668 4.15898 18.3346 6.72565 18.3346H13.2757C15.8423 18.3346 17.9173 16.668 17.9173 13.693V9.3263C17.9173 8.86797 17.5423 8.49297 17.084 8.49297ZM9.58398 14.793H6.25065C5.90898 14.793 5.62565 14.5096 5.62565 14.168C5.62565 13.8263 5.90898 13.543 6.25065 13.543H9.58398C9.92565 13.543 10.209 13.8263 10.209 14.168C10.209 14.5096 9.92565 14.793 9.58398 14.793ZM11.2507 11.4596H6.25065C5.90898 11.4596 5.62565 11.1763 5.62565 10.8346C5.62565 10.493 5.90898 10.2096 6.25065 10.2096H11.2507C11.5923 10.2096 11.8757 10.493 11.8757 10.8346C11.8757 11.1763 11.5923 11.4596 11.2507 11.4596Z'
                fill={color}
            />
        </svg>

    )
}

export default DocumentIcon