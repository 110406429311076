import * as React from 'react';

const UploadIcon = ({
    size = 24,
    color = '#000000',
    role = 'default',
    style = {},
    ...Rest
}) => {

    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 30 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={styles}
            {...Rest}
        >
            <path
                d='M20.5496 11.125C25.0496 11.5125 26.8871 13.825 26.8871 18.8875V19.05C26.8871 24.6375 24.6496 26.875 19.0621 26.875H10.9246C5.33711 26.875 3.09961 24.6375 3.09961 19.05V18.8875C3.09961 13.8625 4.91211 11.55 9.33711 11.1375'
                stroke={color}
                strokeWidth={1.875}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15 18.7484V4.52344'
                stroke={color}
                strokeWidth={1.875}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M19.1875 7.3125L15 3.125L10.8125 7.3125'
                stroke={color}
                strokeWidth={1.875}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
export default UploadIcon;