import React, { Fragment } from 'react';
import { Provider } from 'react-redux';

import AppRouter from './routing/routes';
import store from './redux/store';

import './App.scss';

const App = () => {
  return (
    <Fragment>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </Fragment>
  );
};

export default App;
