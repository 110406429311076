import React from 'react';

const NextIcon = ({
    size = 24,
    color = '#A20417',
    role = 'default',
    style = {},
    ...Rest
}) => {

    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={styles}
            {...Rest}
        >
            <path
                d='M16.4993 9.00012C16.4993 4.85805 13.1415 1.50023 8.9994 1.50023C4.85733 1.50023 1.49951 4.85804 1.49951 9.00012C1.49951 13.1422 4.85732 16.5 8.9994 16.5C13.1415 16.5 16.4993 13.1422 16.4993 9.00012Z'
                stroke={color}
                strokeWidth={1.12499}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.05469 11.6484L10.6946 9.00098L8.05469 6.35352'
                stroke={color}
                strokeWidth={1.12499}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default NextIcon;
