import React from 'react';

const PlusIcon = ({
    size = 24,
    color = '#A20417',
    role = 'default',
    style = {},
    ...Rest
}) => {

    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={styles}
            {...Rest}
        >
            <path
                d='M8 12H16'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12 16V8'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default PlusIcon;
