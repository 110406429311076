import React from 'react';

const LocationIcon = ({
    size = 24,
    color = '#A20417',
    role = 'default',
    style = {},
    ...Rest
}) => {

    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={styles}
            {...Rest}
        >
            <g clipPath='url(#clip0_2493_605)'>
                <path
                    d='M8.99641 24.6585C8.87402 24.6576 8.76102 24.602 8.69819 24.512C3.32921 16.8199 0.119141 12.4244 0.119141 8.13813C0.119141 4.01378 4.08953 0.509766 8.98724 0.509766H8.99958H8.99986H9.0122C13.9099 0.509766 17.8803 4.01379 17.8803 8.13813C17.8803 12.4244 14.6702 16.8199 9.30126 24.512C9.23722 24.6037 9.12116 24.6596 8.99641 24.6585Z'
                    fill={color}
                />
                <path
                    d='M8.99995 24.8632C8.99848 24.8632 8.99703 24.8632 8.99556 24.8632C8.83268 24.8619 8.67959 24.7866 8.59598 24.6668C8.35884 24.3271 8.12589 23.9937 7.89724 23.6666C5.41278 20.1113 3.45041 17.3032 2.0963 14.8524C0.646522 12.2285 0 10.1893 0 8.24046C0 7.2174 0.237643 6.21755 0.706346 5.26868C1.16002 4.35023 1.80787 3.52223 2.63189 2.80772C3.45969 2.08993 4.42072 1.5255 5.48828 1.13011C6.59969 0.718477 7.77701 0.509766 8.98752 0.509766H9.01248C10.223 0.509766 11.4003 0.718477 12.5117 1.13011C13.5793 1.5255 14.5403 2.08993 15.3681 2.80772C16.1921 3.52223 16.84 4.35023 17.2937 5.26868C17.7623 6.21755 18 7.21741 18 8.24046C18 10.1893 17.3535 12.2285 15.9037 14.8524C14.5496 17.3032 12.5872 20.1114 10.1027 23.6666C9.87409 23.9938 9.64117 24.3271 9.40402 24.6668C9.31931 24.7882 9.16477 24.8632 8.99995 24.8632ZM8.9978 24.6585C8.99852 24.6586 8.99925 24.6586 8.99997 24.6586C9.08119 24.6586 9.15731 24.6216 9.19906 24.5618C9.43621 24.222 9.66917 23.8887 9.89783 23.5615C12.379 20.011 14.3387 17.2066 15.6878 14.7649C17.1217 12.1697 17.7612 10.1575 17.7612 8.24046C17.7612 7.24496 17.5299 6.27194 17.0737 5.34846C16.6319 4.45398 16.0009 3.64756 15.1982 2.95157C14.3921 2.25251 13.4563 1.7029 12.417 1.31796C11.3356 0.917479 10.1902 0.714416 9.01248 0.714416H8.98752C7.8098 0.714416 6.66436 0.917479 5.58303 1.31796C4.5437 1.7029 3.60796 2.25252 2.80178 2.95158C1.99912 3.64756 1.36813 4.45398 0.926305 5.34846C0.470127 6.27194 0.238837 7.24496 0.238837 8.24046C0.238837 10.1575 0.87828 12.1697 2.31215 14.7648C3.6613 17.2066 5.62104 20.011 8.10215 23.5614C8.33082 23.8886 8.56378 24.222 8.80095 24.5618C8.84215 24.6208 8.91758 24.6579 8.9978 24.6585Z'
                    fill='#7D9554'
                />
                <path
                    d='M9.01224 12.8815C11.7788 12.8815 14.0216 10.9597 14.0216 8.58917C14.0216 6.2186 11.7788 4.29688 9.01224 4.29688C6.24567 4.29688 4.00293 6.2186 4.00293 8.58917C4.00293 10.9597 6.24567 12.8815 9.01224 12.8815Z'
                    fill='#FFFAF3'
                />
                <path
                    d='M9.01252 12.9846C8.3202 12.9846 7.64853 12.8684 7.01615 12.6392C6.4054 12.4178 5.85694 12.101 5.38596 11.6974C4.91498 11.2938 4.5452 10.8239 4.28688 10.3006C4.0194 9.7587 3.88379 9.18316 3.88379 8.58993C3.88379 7.99671 4.0194 7.42118 4.28688 6.87932C4.5452 6.35599 4.91498 5.88603 5.38596 5.48247C5.85694 5.0789 6.4054 4.76205 7.01615 4.54071C7.64852 4.31151 8.3202 4.19531 9.01252 4.19531C9.70484 4.19531 10.3765 4.31151 11.0089 4.54071C11.6196 4.76205 12.1681 5.0789 12.6391 5.48247C13.1101 5.88603 13.4798 6.35599 13.7382 6.87932C14.0056 7.42117 14.1412 7.99671 14.1412 8.58993C14.1412 9.18316 14.0056 9.75869 13.7382 10.3006C13.4798 10.8239 13.1101 11.2938 12.6391 11.6974C12.1681 12.101 11.6196 12.4178 11.0089 12.6392C10.3765 12.8684 9.70484 12.9846 9.01252 12.9846ZM9.01252 4.39996C8.35233 4.39996 7.71196 4.51073 7.10919 4.72919C6.52691 4.94022 6.00396 5.24235 5.55485 5.62718C5.10572 6.01201 4.75313 6.46011 4.50685 6.95904C4.25189 7.47554 4.12263 8.02425 4.12263 8.58993C4.12263 9.15562 4.25189 9.70433 4.50685 10.2208C4.75313 10.7198 5.10573 11.1679 5.55485 11.5527C6.00396 11.9375 6.52691 12.2397 7.10919 12.4507C7.71196 12.6691 8.35233 12.7799 9.01252 12.7799C9.6727 12.7799 10.3131 12.6691 10.9158 12.4507C11.4981 12.2397 12.0211 11.9375 12.4702 11.5527C12.9193 11.1679 13.2719 10.7198 13.5182 10.2208C13.7731 9.70433 13.9024 9.15562 13.9024 8.58993C13.9024 8.02425 13.7731 7.47554 13.5182 6.95904C13.2719 6.46011 12.9193 6.01201 12.4702 5.62718C12.0211 5.24234 11.4981 4.94022 10.9158 4.72919C10.3131 4.51074 9.6727 4.39996 9.01252 4.39996Z'
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id='clip0_2493_605'>
                    <rect
                        width={18}
                        height={24.8548}
                        fill='white'
                        transform='translate(0 0.509766)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LocationIcon;
