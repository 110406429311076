import React, { Fragment, useEffect, useState } from 'react';

import { Button, Card, Checkbox, Col, Collapse, Divider, Flex, Form, Input, Row, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';

import { createUserType, getByIdUserType, getUserType, setIsSuccess, setIsUpdating, updateUserType } from './redux/slice';
import { getMenuListing } from '../../redux/slice/global';
import { getByIdPackage } from '../packages/redux/slice';
import { getDecodeToken } from '../auth/redux/slice';

import BackIcon from '../../components/icon/BackIcon';
import ExpandIcon from '../../components/icon/ExpandIcon';
import openNotification from '../../components/notification';

const { Title, Text } = Typography;

const UpdateUserType = () => {
    const [form] = Form.useForm();
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, userType, listing, auth, packages } = useSelector(state => state)
    const [selectAll, setSelectAll] = useState(false);
    const [modalType, setModalType] = useState(false);
    const [permissionMenu, setPermissionMenu] = useState([])
    const [permissionsState, setPermissionsState] = useState([]);
    const [loginUser, setLoginUser] = useState({});

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        if (token) {
            const user = jwtDecode(token);
            dispatch(getDecodeToken(user))
            setLoginUser(user);
        }
        dispatch(getMenuListing())
    }, [])

    useEffect(() => {
        if (auth?.decodeToken?.package) {
            dispatch(getByIdPackage(auth?.decodeToken?.package))
        }
    }, [auth?.decodeToken])

    useEffect(() => {
        if (loginUser?.package) {
            setPermissionMenu(listing?.listing?.menu?.filter(item => packages?.package?.menu?.includes(item._id)));
        } else {
            setPermissionMenu(listing?.listing?.menu);
        }
    }, [listing.listing.menu, packages.package, loginUser?.package])

    useEffect(() => {
        if (!id && !modalType) {
            setPermissionsState(permissionMenu?.map(item => {
                return {
                    ...item,
                    permissions: {
                        hasAccess: auth?.decodeToken?.organizer && (item.name === 'Dashboard') ? true : false,
                        canCreate: auth?.decodeToken?.organizer && (item.name === 'Dashboard') ? true : false,
                        canUpdate: auth?.decodeToken?.organizer && (item.name === 'Dashboard') ? true : false,
                        canDelete: auth?.decodeToken?.organizer && (item.name === 'Dashboard') ? true : false
                    },
                    children: item.children?.map((child, index) => ({
                        ...child,
                        permissions: {
                            hasAccess: false,
                            canCreate: false,
                            canUpdate: false,
                            canDelete: false
                        }
                    }))
                }
            }));
        }
    }, [permissionMenu, id, modalType, auth, loginUser, loginUser?.package]);

    useEffect(() => {
        if (id) {
            dispatch(setIsUpdating(true))
            setModalType(true)
            dispatch(getByIdUserType(id))
            if (user.loginUserPackage) {
                dispatch(getByIdPackage(user.loginUserPackage))
            }
        }
    }, [id])

    useEffect(() => {
        if (Object.keys(userType.userType).length > 0) {
            if (!modalType) {
                dispatch(createUserType({ userTypeData: userType.userType, organizerID: loginUser.organizer }));
            } else {
                if (userType.isUpdating) {
                    form.setFieldsValue(userType.userType)

                    setPermissionsState(
                        userType?.userType.menu?.length > 0
                            ? permissionMenu?.map(item => {
                                // Find the user's permissions for this menu item
                                const userMenuPermissions = userType?.userType?.menu?.find(i => i.menu === item._id)?.permissions || {};

                                return {
                                    ...item,
                                    permissions: {
                                        hasAccess: userMenuPermissions.hasAccess || false,
                                        canCreate: userMenuPermissions.canCreate || false,
                                        canUpdate: userMenuPermissions.canUpdate || false,
                                        canDelete: userMenuPermissions.canDelete || false
                                    },
                                    children: item?.children?.map(child => {
                                        // Check if the parent menu item's ID matches the condition
                                        if (userType?.userType?.menu?.some(i => i.menu === item._id)) {
                                            // Use the parent's permissions for the child
                                            const userChildPermissions = userMenuPermissions;

                                            return {
                                                ...child,
                                                permissions: {
                                                    hasAccess: userChildPermissions.hasAccess || false,
                                                    canCreate: userChildPermissions.canCreate || false,
                                                    canUpdate: userChildPermissions.canUpdate || false,
                                                    canDelete: userChildPermissions.canDelete || false
                                                }
                                            };
                                        } else {
                                            // Default permissions for child if the condition is not met
                                            return {
                                                ...child,
                                                permissions: {
                                                    hasAccess: false,
                                                    canCreate: false,
                                                    canUpdate: false,
                                                    canDelete: false
                                                }
                                            };
                                        }
                                    })
                                };
                            })
                            : permissionMenu?.map(item => ({
                                ...item,
                                permissions: {
                                    hasAccess: false,
                                    canCreate: false,
                                    canUpdate: false,
                                    canDelete: false
                                },
                                children: item.children?.map(child => ({
                                    ...child,
                                    permissions: {
                                        hasAccess: false,
                                        canCreate: false,
                                        canUpdate: false,
                                        canDelete: false
                                    }
                                }))
                            }))
                    );
                } else {
                    dispatch(updateUserType({ userTypeData: { ...userType.userType, id: id }, organizerID: loginUser.organizer }))
                }
            }
        }
        if (userType.isSuccess) {
            handleClear()
            dispatch(setIsSuccess(false))
            setModalType(false)
            navigate('/user/type')
        }
    }, [userType.userType, userType.isSuccess, userType.isUpdating])

    const handleOnFinish = (values) => {
        if (!permissionsState?.find(i => i?.name === 'Dashboard')?.permissions?.hasAccess) {
            openNotification({ message: 'Please give permission for dashboard!', type: 'error' });
        } else {
            if (id) {
                dispatch(setIsUpdating(false));
            }
            dispatch(getUserType({
                ...values,
                organizer: auth?.decodeToken?.organizer ? auth?.decodeToken?.organizer : null,
                menu: permissionsState.map(menuItem => ({
                    menu: menuItem._id,
                    permissions: menuItem.permissions,
                    children: menuItem.children.map(child => ({
                        menu: child._id,
                        permissions: child.permissions,
                        _id: child._id
                    })),
                    _id: menuItem._id
                }))
            }));
        }
    };

    const handleClear = () => {
        form.resetFields();
        setModalType(false)
        setPermissionsState(
            permissionMenu?.map(item => ({
                ...item,
                permissions: {
                    hasAccess: false,
                    canCreate: false,
                    canUpdate: false,
                    canDelete: false
                },
                children: item.children?.map(child => ({
                    ...child,
                    permissions: {
                        hasAccess: false,
                        canCreate: false,
                        canUpdate: false,
                        canDelete: false
                    }
                }))
            }))
        );
        setSelectAll(false);
        navigate(-1)
    };

    const renderCollapseItem = (item, ind, handleChange) => {
        return [{
            key: `${ind + 1}`,
            label: (
                <Row>
                    <Col xxl={9} xl={9} lg={9} md={24} sm={24} xs={24}>
                        <Text>{item.name}</Text>
                    </Col>
                    <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24}>
                        <Flex wrap gap={16} align='center' className='h-100'>
                            <Checkbox
                                checked={item.permissions?.hasAccess}
                                disabled={((item.name?.toLowerCase() === 'dashboard') && auth?.decodeToken?.organizer) || userType?.userType?.isReadOnly}
                                onChange={(e) => handleChange(e, item._id, 'hasAccess')}
                            >Access</Checkbox>
                            <Checkbox
                                checked={item.permissions?.canCreate}
                                disabled={((item.name?.toLowerCase() === 'dashboard') && auth?.decodeToken?.organizer) || userType?.userType?.isReadOnly}
                                onChange={(e) => handleChange(e, item._id, 'canCreate')}
                            >Add New</Checkbox>
                            <Checkbox
                                checked={item.permissions?.canUpdate}
                                disabled={((item.name?.toLowerCase() === 'dashboard') && auth?.decodeToken?.organizer) || userType?.userType?.isReadOnly}
                                onChange={(e) => handleChange(e, item._id, 'canUpdate')}
                            >Edit</Checkbox>
                            <Checkbox
                                checked={item.permissions?.canDelete}
                                disabled={((item.name?.toLowerCase() === 'dashboard') && auth?.decodeToken?.organizer) || userType?.userType?.isReadOnly}
                                onChange={(e) => handleChange(e, item._id, 'canDelete')}
                            >Delete</Checkbox>
                        </Flex>
                    </Col>
                </Row>
            ),
            children: item?.children?.length > 0
                ? item.children.map((x, index) => {
                    return (
                        <Row key={index}>
                            <Col xxl={9} xl={9} lg={9} md={24} sm={24} xs={24}>
                                <Text className='paddingX-1'>{x.name}</Text>
                            </Col>
                            <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24}>
                                <Flex wrap gap={16} align='center' className='h-100 paddingX-3'>
                                    <Checkbox
                                        checked={x.permissions?.hasAccess}
                                        disabled={userType?.userType?.isReadOnly}
                                        onChange={(e) => handleChange(e, x._id, 'hasAccess')}
                                    >Access</Checkbox>
                                    <Checkbox
                                        checked={x.permissions?.canCreate}
                                        disabled={userType?.userType?.isReadOnly}
                                        onChange={(e) => handleChange(e, x._id, 'canCreate')}
                                    >Add New</Checkbox>
                                    <Checkbox
                                        checked={x.permissions?.canUpdate}
                                        disabled={userType?.userType?.isReadOnly}
                                        onChange={(e) => handleChange(e, x._id, 'canUpdate')}
                                    >Edit</Checkbox>
                                    <Checkbox
                                        checked={x.permissions?.canDelete}
                                        disabled={userType?.userType?.isReadOnly}
                                        onChange={(e) => handleChange(e, x._id, 'canDelete')}
                                    >Delete</Checkbox>
                                </Flex>
                            </Col>
                        </Row>
                    );
                })
                : [],
            showArrow: item?.children?.length > 0
        }];
    }

    const handleChange = (e, id, permissionType) => {
        const { checked } = e.target;

        const updatePermissions = (items) => {
            return items?.map(item => {
                if (item._id === id) {
                    const newPermissions = {
                        ...item.permissions,
                        [permissionType]: checked,
                        hasAccess: permissionType === 'hasAccess'
                            ? checked
                            : checked || item.permissions.hasAccess
                    };

                    if (permissionType === 'hasAccess' && !checked) {
                        return {
                            ...item,
                            permissions: {
                                hasAccess: false,
                                canCreate: false,
                                canUpdate: false,
                                canDelete: false
                            },
                            children: item.children?.map(child => ({
                                ...child,
                                permissions: {
                                    hasAccess: false,
                                    canCreate: false,
                                    canUpdate: false,
                                    canDelete: false
                                }
                            }))
                        };
                    }

                    if (permissionType !== 'hasAccess' && checked) {
                        return {
                            ...item,
                            permissions: newPermissions,
                            children: item.children?.map(child => ({
                                ...child,
                                permissions: {
                                    hasAccess: newPermissions?.hasAccess,
                                    canCreate: newPermissions?.canCreate,
                                    canUpdate: newPermissions?.canUpdate,
                                    canDelete: newPermissions?.canDelete
                                }
                            }))
                        };
                    }

                    return {
                        ...item,
                        permissions: newPermissions,
                        children: item.children?.map(child => ({
                            ...child,
                            permissions: {
                                ...child.permissions,
                                [permissionType]: checked,
                                hasAccess: permissionType === 'hasAccess'
                                    ? checked
                                    : checked || child.permissions.hasAccess
                            }
                        }))
                    };
                } else {
                    return {
                        ...item,
                        children: updatePermissions(item.children)
                    };
                }
            });
        };

        setPermissionsState(prevState => updatePermissions(prevState));
    };

    const handleSelectAllChange = (e) => {
        const { checked } = e.target;

        const updateAllPermissions = (items) => {
            return items?.map(item => ({
                ...item,
                permissions: {
                    hasAccess: item.name === 'Dashboard' && auth?.decodeToken?.organizer ? true : checked,
                    canCreate: item.name === 'Dashboard' && auth?.decodeToken?.organizer ? true : checked,
                    canUpdate: item.name === 'Dashboard' && auth?.decodeToken?.organizer ? true : checked,
                    canDelete: item.name === 'Dashboard' && auth?.decodeToken?.organizer ? true : checked
                },
                children: item.children?.map(child => ({
                    ...child,
                    permissions: {
                        hasAccess: checked,
                        canCreate: checked,
                        canUpdate: checked,
                        canDelete: checked
                    }
                }))
            }));
        };

        setSelectAll(checked);
        setPermissionsState(updateAllPermissions(permissionMenu));
    };

    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    return (
        <Fragment>
            <Flex vertical gap={12} className='user-management'>
                <Button
                    type='text'
                    icon={<BackIcon role={'button'} />}
                    className='bg-white paddingX-3'
                    onClick={() => {
                        handleClear()
                        navigate('/user/type')
                    }}
                />
                <Flex>
                    <Card bordered={false} className='w-100'>
                        <Flex vertical gap={24}>
                            <Title level={3} type='success'>{id ? 'Edit' : 'Add'} User Type</Title>
                            <Form
                                form={form}
                                className='user-filter'
                                name='user'
                                colon={false}
                                layout='vertical'
                                disabled={userType?.userType?.isReadOnly}
                                onFinish={handleOnFinish}
                                requiredMark={(label, isRule) => {
                                    return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                                }}
                            >
                                <Row gutter={18}>
                                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='name'
                                            label='User Type Name'
                                            rules={[{ required: true, message: 'User type is required!' }]}
                                        >
                                            <Input placeholder='Please Enter'
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Title level={4} type='danger'>User Type Permissions</Title>

                                <Flex gap={64} align='center' className='paddingY-3'>
                                    <Title level={5}>Access</Title>
                                    <Checkbox
                                        checked={selectAll}
                                        onChange={handleSelectAllChange}
                                    >
                                        Select All
                                    </Checkbox>
                                </Flex>

                                <Divider className='margin-0' />
                                {
                                    permissionsState?.map((i, ind) => (
                                        i?.children?.length > 0
                                            ? <Fragment key={ind}>
                                                <Collapse
                                                    key={ind}
                                                    items={renderCollapseItem(i, ind, handleChange)}
                                                    bordered={false}
                                                    collapsible='icon'
                                                    expandIcon={customExpandIcon}
                                                />
                                                <Divider className='margin-0' />
                                            </Fragment>
                                            : <Fragment key={ind}>
                                                <Row className='paddingY-1'>
                                                    <Col xxl={9} xl={9} lg={9} md={24} sm={24} xs={24} className='paddingY-1'>
                                                        <Text className='paddingX-3'>{i.name}</Text>
                                                    </Col>
                                                    <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24}>
                                                        <Flex wrap gap={16} align='center' className='h-100 paddingX-3'>
                                                            <Checkbox
                                                                checked={i.permissions?.hasAccess}
                                                                disabled={(i.name === 'Dashboard' && auth?.decodeToken?.organizer) || userType?.userType?.isReadOnly}
                                                                onChange={(e) => handleChange(e, i._id, 'hasAccess')}
                                                            >
                                                                Access
                                                            </Checkbox>
                                                            <Checkbox
                                                                checked={i.permissions?.canCreate}
                                                                disabled={(i.name === 'Dashboard' && auth?.decodeToken?.organizer) || userType?.userType?.isReadOnly}
                                                                onChange={(e) => handleChange(e, i._id, 'canCreate')}
                                                            >
                                                                Add New
                                                            </Checkbox>
                                                            <Checkbox
                                                                checked={i.permissions?.canUpdate}
                                                                disabled={(i.name === 'Dashboard' && auth?.decodeToken?.organizer) || userType?.userType?.isReadOnly}
                                                                onChange={(e) => handleChange(e, i._id, 'canUpdate')}
                                                            >
                                                                Edit
                                                            </Checkbox>
                                                            <Checkbox
                                                                checked={i.permissions?.canDelete}
                                                                disabled={(i.name === 'Dashboard' && auth?.decodeToken?.organizer) || userType?.userType?.isReadOnly}
                                                                onChange={(e) => handleChange(e, i._id, 'canDelete')}
                                                            >
                                                                Delete
                                                            </Checkbox>
                                                        </Flex>
                                                    </Col>
                                                </Row>
                                                <Divider className='margin-0' />
                                            </Fragment>
                                    ))
                                }
                                <Flex justify='flex-end' gap={10} wrap={true}>
                                    <Button danger type='primary' htmlType='button' onClick={handleClear}
                                    >Cancel</Button>
                                    <Button type='primary' htmlType='submit' loading={userType?.loading && userType?.loading}
                                    >Submit</Button>
                                </Flex>
                            </Form>
                        </Flex>
                    </Card>
                </Flex>
            </Flex>
        </Fragment>
    );
};

export default UpdateUserType;
