import React, { Fragment } from 'react';

import { Popconfirm } from 'antd';

const CommonPopconfirm = ({
    title = 'No title provided',
    description = 'No description provided',
    onConfirm,
    okText = 'Yes',
    cancelText = 'No',
    children,
    ...rest
}) => {
    return (
        <Fragment>
            <Popconfirm
                title={title}
                icon={null}
                description={description}
                onConfirm={onConfirm}
                okText={okText}
                cancelText={cancelText}
                {...rest}
            >
                {children}
            </Popconfirm>
        </Fragment>
    );
};

export default CommonPopconfirm;
