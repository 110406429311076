import React, { Fragment } from 'react';

import { Button, Col, Flex, Image, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import errorImage from '../../assets/images/401ErrorImage.svg';
import Container from '../../components/container/Container';

const { Title, Text } = Typography;

const Unauthorized = () => {
    const navigate = useNavigate();

    const { auth } = useSelector((state) => state);

    return (
        <Fragment>
            <Container fluid>
                <Flex vertical className='vh-100 paddingY-4'>
                    <Row className='bg-white w-100 h-100 rounded-3' justify={"center"} align={'middle'}>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Flex vertical justify='center' align='center' gap={24}>
                                <Image src={errorImage} preview={false} />
                                <Title level={2}>Unauthorized</Title>

                                <Text className='text-center' type='secondary'>
                                    We're sorry, the page you requested could not be found <br />
                                    please go back to the homepage
                                </Text>
                                <Button type='primary' onClick={() => Object.keys(auth.decodeToken).length > 0 ? navigate('/dashboard') : navigate('/user/authentication/sign-in')}>Go To Home</Button>
                            </Flex>
                        </Col>
                    </Row>
                </Flex>
            </Container>
        </Fragment>
    );
};

export default Unauthorized;
