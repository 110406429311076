import * as React from 'react';

const SearchIcon = ({
    size = 24,
    color = '#000000',
    role = 'default',
    style = {},
    ...Rest
}) => {
    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };
    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={styles}
            {...Rest}
        >
            <path
                d='M9.58317 17.5001C13.9554 17.5001 17.4998 13.9557 17.4998 9.58341C17.4998 5.21116 13.9554 1.66675 9.58317 1.66675C5.21092 1.66675 1.6665 5.21116 1.6665 9.58341C1.6665 13.9557 5.21092 17.5001 9.58317 17.5001Z'
                stroke={color}
                strokeWidth={1.25}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M18.3332 18.3334L16.6665 16.6667'
                stroke={color}
                strokeWidth={1.25}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
export default SearchIcon;