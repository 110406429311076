import React, { Fragment, useEffect, useState } from 'react';

import { Table, Button, Row, Col, DatePicker, Tooltip, Flex, Select, Image, Typography, Modal, Form, Divider, TimePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import HereMap from '../../../components/heremap';

import { createTimeTracking, deleteTimeTracking, getByIdTimeTracking, getTimeTrackings, getWorkHistoryListsData, setIsSuccess, setIsUpdating, updateTimeTracking } from '../redux/slice';

import EditIcon from '../../../components/icon/EditIcon';
import DeleteIcon from '../../../components/icon/DeleteIcon';
import NextIcon from '../../../components/icon/NextIcon';
import BackIcon from '../../../components/icon/BackIcon';
import CalendarIcon from '../../../components/icon/CalendarIcon';
import LocationIcon from '../../../components/icon/LocationIcon';
import CloseIcon from '../../../components/icon/CloseIcon';
import PlusIcon from '../../../components/icon/PlusIcon';
import WatchTime from '../../../assets/images/watch-time.svg';
import WorkingTime from '../../../assets/images/working-hours.svg';
import BreakTime from '../../../assets/images/break-time.svg';

import { black, primary, secondary } from '../../../utils/theme/config';

const { Title, Text } = Typography

const CustomDatePicker = ({ selectedDate, onDateChange }) => {
    const [calendarVisible, setCalendarVisible] = useState(false);
    const datePickerRef = React.useRef(null);

    const handlePrevious = () => {
        onDateChange(selectedDate.subtract(1, 'month'));
    };

    const handleNext = () => {
        onDateChange(selectedDate.add(1, 'month'));
    };

    const handleOpenCalendar = () => {
        setCalendarVisible(true);
    };

    const handleDateChange = (date) => {
        setCalendarVisible(false);
        onDateChange(date || dayjs());
    };

    const handleClickOutside = (event) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setCalendarVisible(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <Row align='middle' gutter={8} style={{ position: 'relative' }}>
            <Col>
                <span style={{ paddingLeft: '40px', fontSize: '12px' }}>Month</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button icon={<BackIcon size={24} color={primary} role='button' />} type='text' onClick={handlePrevious} style={{ margin: '0' }} />
                    <span style={{ margin: '0 8px', fontSize: '18px', fontWeight: '600' }}>{selectedDate.format('MMM YYYY')}</span>
                    <Button icon={<NextIcon size={30} color={primary} />} type='text' onClick={handleNext} style={{ margin: '0', marginTop: '8px' }} />
                    <Button type='text' icon={<CalendarIcon color={secondary} role='button' />} onClick={handleOpenCalendar} style={{ margin: '0', marginLeft: '10px' }} />
                    {calendarVisible && (
                        <DatePicker
                            picker='month'
                            open={calendarVisible}
                            value={selectedDate}
                            onChange={handleDateChange}
                            variant='borderless'
                            allowClear={false}
                            suffixIcon={() => null}
                            onClick={() => setCalendarVisible(true)}
                            onBlur={() => setCalendarVisible(false)}
                            style={{
                                position: 'absolute',
                                zIndex: 1000,
                                marginTop: '8px',
                                width: '0',
                                color: 'transparent'
                            }}
                        />
                    )}
                </div>
            </Col>
        </Row>
    );
};

const IndividualTimeTracking = ({ search, organizerLists, form, userSearch, setUserSearch, permission }) => {
    const dispatch = useDispatch();

    const [individualTimeTrackingDataList, setIndividualTimeTrackingDataList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const { auth, timeTracking } = useSelector(state => state);
    const [month, setMonth] = useState(dayjs().format('MM'));
    const [year, setYear] = useState(dayjs().format('YYYY'));
    const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
    const [totalHours, setTotalHours] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editId, setEditId] = useState(null);
    const [isIndividualUser, setIsIndividualUser] = useState(false)
    const [expandRow, setExpandRow] = useState([]);
    const [isExpand, setIsExpand] = useState(false);
    const [timeSelections, setTimeSelections] = useState([{ start: null, end: null }]);
    // const [minStartTime, setMinStartTime] = useState('08:00');

    const handleDateChange = (date) => {
        setSelectedDate(date || dayjs());
        setYear(dayjs(date).format('YYYY'))
        setMonth(dayjs(date).format('MM'))
    };

    useEffect(() => {
        if (Object.keys(auth?.decodeToken).length > 0 && userSearch) {
            getWorkHistoryData();
        }
    }, [auth, month, year, userSearch, form, isIndividualUser, search])

    const getWorkHistoryData = () => {
        dispatch(getWorkHistoryListsData({
            user: userSearch,
            organizer: auth?.decodeToken?.organizer,
            month: month,
            year: year
        }))
    }

    useEffect(() => {
        setIndividualTimeTrackingDataList(timeTracking?.workHistory);
        setTotalHours(timeTracking?.workHistoryTotalRecords);
    }, [timeTracking?.workHistory, timeTracking?.workHistoryTotalRecords, month, year, userSearch])

    // useEffect(() => {
    //     if (individualTimeTrackingDataList?.length > 0) {
    //         // Extract all start times from the timeEntries
    //         const allStartTimes = individualTimeTrackingDataList?.flatMap(entry => entry?.timeEntries?.map(te => te.start));

    //         // Find the minimum start time
    //         const getMinStartTime = allStartTimes?.reduce((min, current) => current < min ? current : min, allStartTimes[0]);
    //         setMinStartTime(getMinStartTime)
    //     }
    // }, [individualTimeTrackingDataList])

    const renderTimeEntries = (timeEntries) => {
        return (
            <div style={{ display: 'flex', width: '100%' }}>
                {timeEntries.map((entry, index) => {
                    const isWork = entry.type === 'work';
                    const color = isWork ? '#B7E7AF' : '#FFCFC5';
                    const startTime = dayjs(entry.start, 'HH:mm');
                    const endTime = dayjs(entry.end, 'HH:mm');
                    const entryDurationMinutes = endTime.diff(startTime, 'minute');
                    // const startOffsetMinutes = startTime.diff(dayjs(minStartTime && minStartTime, 'HH:mm'), 'minute');
                    const startOffsetMinutes = startTime.diff(dayjs("04:00", 'HH:mm'), 'minute');
                    const widthPercent = (entryDurationMinutes * 100) / (20 * 60);
                    const startOffsetPercent = (startOffsetMinutes * 100) / (20 * 60);

                    return (
                        <Tooltip title={entry.duration} key={index}>
                            <div
                                style={{
                                    backgroundColor: color,
                                    width: `${widthPercent}%`,
                                    marginLeft: index === 0 ? `${startOffsetPercent}%` : '0%',
                                    height: '30px',
                                    textAlign: 'center',
                                    lineHeight: '30px',
                                    fontSize: '12px'
                                }}
                            >
                                {entry.duration >= '01:00' ? entry.duration : ''}
                            </div>
                        </Tooltip>
                    );
                })}
            </div>
        );
    };

    const renderTimeLabels = () => {
        const timeLabels = [];
        // const startTime = minStartTime && minStartTime.split(':')[0];

        for (let i = 4; i <= 23; i++) {
            timeLabels.push(`${i.toString().padStart(2, '0')}:00`);
        }
        // for (let i = 0; i <= 0; i++) {
        //     timeLabels.push(`${i.toString().padStart(2, '0')}:00`);
        // }

        return (
            <Row align='start' className='w-100' justify='space-between'>
                {timeLabels.map((label, index) => (
                    // <Col span={1} key={index}>
                    <div style={{ fontSize: '12px', width: '5%', padding: '6px 0' }}>
                        {label}
                    </div>
                    // </Col>
                ))}
            </Row>
        );
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            fixed: 'left',
            width: '120px',
            render: (_, record) => {
                return dayjs(record?.date).format('DD-MM-YYYY') || '-'
            }
        },
        {
            title: 'Total Hours',
            dataIndex: 'totalHours',
            fixed: 'left',
            key: 'totalHours',
            width: '80px',
        },
        {
            title: 'Break Hours',
            dataIndex: 'breakHours',
            fixed: 'left',
            key: 'breakHours',
            width: '80px',
        },
        {
            title: 'Overtime',
            dataIndex: 'ourTime',
            fixed: 'left',
            key: 'ourTime',
            width: '100px',
        },
        {
            title: (
                <Flex align='center' justify='space-between'>
                    <span style={{ padding: '5px 0' }}>Time</span>
                </Flex>
            ),
            key: 'time',
            children: [
                {
                    title: renderTimeLabels(),
                    dataIndex: 'building',
                    key: 'building',
                    render: (text, record) => (
                        <div>
                            {renderTimeEntries(record.timeEntries)}
                        </div>
                    ),
                },
            ],
        },
    ];

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: '100px',
            render: (text, record) => (
                <Flex justify='center'>
                    {
                        permission?.canUpdate &&
                        <Button
                            type='text'
                            className='padding-1'
                            onClick={() => {
                                setIsModalOpen(true)
                                dispatch(setIsUpdating(true))
                                setEditId(record?._id)
                                dispatch(getByIdTimeTracking(record?._id))
                            }}>
                            <EditIcon role='button' />
                        </Button>
                    }
                    {
                        permission?.canDelete &&
                        <Button
                            type='text'
                            className='padding-1'>
                            <DeleteIcon
                                role='button'
                                onClick={() => dispatch(deleteTimeTracking({
                                    id: record?._id,
                                    organizer: auth?.decodeToken?.organizer,
                                    date: date
                                }))}
                            />
                        </Button>
                    }
                </Flex >
            )
        })
    }

    const handleModalCancel = () => {
        form.resetFields();
        setEditId(null);
        setIsModalOpen(false);
        setTimeSelections([{ start: null, end: null }]);
    }

    const handleOnFinish = (values) => {
        if (editId) {
            dispatch(setIsUpdating(false));
        }
        setIsIndividualUser(true)
        dispatch(getTimeTrackings({
            user: values.user,
            organizer: auth?.decodeToken?.organizer,
            date: dayjs(values.date).format('MM/DD/YYYY'),
            timeEntries: values?.timeEntries?.map((item) => {
                return {
                    start: dayjs(item.start).format('HH:mm'),
                    end: dayjs(item.end).format('HH:mm'),
                    type: item?.type
                }
            })
        }));
    }

    useEffect(() => {
        if (Object.keys(timeTracking?.timeTracking).length > 0) {
            if (!editId && isIndividualUser) {
                dispatch(createTimeTracking({ timeTracking: timeTracking.timeTracking, organizer: auth?.decodeToken?.organizer, date: date }));
            }
            else {
                if (timeTracking.isUpdating) {
                    setTimeSelections([...timeTracking?.timeTracking?.timeEntries?.map(item => {
                        return {
                            start: dayjs(item?.start, 'HH:mm'),
                            end: dayjs(item?.end, 'HH:mm'),
                        }
                    }), { start: null, end: null }]);

                    form.setFieldsValue({
                        user: timeTracking?.timeTracking.user, date: dayjs(timeTracking?.timeTracking?.date),
                        timeEntries: timeTracking?.timeTracking?.timeEntries?.map(item => {
                            return {
                                start: dayjs(item?.start, 'HH:mm'),
                                end: dayjs(item?.end, 'HH:mm'),
                                type: item?.type
                            }
                        })
                    })
                } else if (isIndividualUser) {
                    dispatch(updateTimeTracking({ timeTracking: { ...timeTracking.timeTracking, id: editId }, organizer: auth?.decodeToken?.organizer, date: date }));
                }
            }
        }
        if (timeTracking.isSuccess) {
            setIsModalOpen(false);
            setIsIndividualUser(false)
            handleClear();
            setEditId(null);
            dispatch(setIsSuccess(false));
        }
    }, [timeTracking.isUpdating, timeTracking.timeTracking, timeTracking.isSuccess]);

    const handleClear = () => {
        form.resetFields();
        setEditId(null);
        setTimeSelections([{ start: null, end: null }]);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    // Handle Start Time Change
    const handleStartTimeChange = (index, time) => {
        const newTimeSelections = [...timeSelections];
        newTimeSelections[index].start = time;
        setTimeSelections(newTimeSelections);
    };

    // Handle End Time Change
    const handleEndTimeChange = (index, time) => {
        const newTimeSelections = [...timeSelections];
        newTimeSelections[index].end = time;

        // Automatically add a new row after selecting the end time
        if (index === timeSelections.length - 1) {
            setTimeSelections([...newTimeSelections, { start: null, end: null }]);
        } else {
            setTimeSelections(newTimeSelections);
        }
    };

    // Disable times based on the previous row's end time for the next row's start time
    const disabledStartTime = (index) => {
        const previousEndTime = timeSelections[index - 1]?.end;
        if (!previousEndTime) return {}; // No previous end time to restrict

        return {
            disabledHours: () => Array.from({ length: 24 }, (_, i) => i < previousEndTime.hour() ? i : null).filter(i => i !== null),
            disabledMinutes: (selectedHour) =>
                selectedHour === previousEndTime.hour()
                    ? Array.from({ length: 60 }, (_, i) => i <= previousEndTime.minute() ? i : null).filter(i => i !== null)
                    : []
        };
    };

    // Disable times for the end time based on the selected start time
    const disabledEndTime = (index) => {
        const startTime = timeSelections[index]?.start;
        if (!startTime) return {}; // If no start time selected, no need to disable times

        return {
            disabledHours: () => Array.from({ length: 24 }, (_, i) => i < startTime.hour() ? i : null).filter(i => i !== null),
            disabledMinutes: (selectedHour) =>
                selectedHour === startTime.hour()
                    ? Array.from({ length: 60 }, (_, i) => i <= startTime.minute() ? i : null).filter(i => i !== null)
                    : []
        };
    };

    // Reset the time selections after removing a row
    const resetTimeSelectionsAfterRemove = (removedIndex) => {
        const newTimeSelections = [...timeSelections];

        // Remove the time entry at the removed index
        newTimeSelections.splice(removedIndex, 1);

        // Reset the form values for time entries
        setTimeSelections(newTimeSelections);
    };

    const handleAdd = (add) => {
        const fieldsValue = form.getFieldsValue();
        const times = fieldsValue.timeEntries || [];

        const lastTimeEntries = times[times.length - 1];

        // Validate the last row values before adding a new one
        if (!lastTimeEntries || !lastTimeEntries.type || !lastTimeEntries.start || !lastTimeEntries.end) {
            form.setFields(
                [
                    {
                        name: ['timeEntries', times.length - 1, 'type'],
                        errors: !lastTimeEntries?.type ? ['Type is required!'] : [],
                    },
                    {
                        name: ['timeEntries', times.length - 1, 'start'],
                        errors: !lastTimeEntries?.start ? ['Start time is required!'] : [],
                    },
                    {
                        name: ['timeEntries', times.length - 1, 'end'],
                        errors: !lastTimeEntries?.end ? ['End time is required!'] : [],
                    },
                ]
            );
        } else {
            add();  // Add a new row

            const newTimes = form.getFieldValue('timeEntries');
            if (newTimes.length > 1) {
                const previousEndTime = newTimes[newTimes.length - 2]?.end;

                if (previousEndTime) {
                    // Increment the previous end time by 1 minute to get the new start time
                    const newStartTime = previousEndTime.clone().add(1, 'minute'); // Increment by 1 minute using Moment.js

                    // Update the start time for the new row
                    form.setFieldsValue({
                        timeEntries: [
                            ...newTimes.slice(0, newTimes.length - 1),
                            {
                                ...newTimes[newTimes.length - 1],
                                start: newStartTime,  // Set the start time 1 minute after the previous row's end time
                            },
                        ],
                    });

                    // Manually trigger onChange for the new start time (for the latest row)
                    handleStartTimeChange(newTimes.length - 1, newStartTime); // Trigger the onChange handler
                }
            }

            // Clear any errors
            form.setFields(
                [
                    {
                        name: ['timeEntries', times.length - 1, 'type'],
                        errors: false,
                    },
                    {
                        name: ['timeEntries', times.length - 1, 'start'],
                        errors: false,
                    },
                    {
                        name: ['timeEntries', times.length - 1, 'end'],
                        errors: false,
                    },
                ]
            );
        }
    };

    return (
        <Fragment>
            <div>
                <Flex align='center' justify='space-between' wrap>
                    <Flex align='center' style={{ width: '300px' }}>
                        <Form
                            name='user'
                            colon={true}
                            className='w-100'
                        >
                            <Form.Item
                                name='user'
                                label='User:'
                            >
                                <Select
                                    placeholder='Select User'
                                    onChange={(value) => setUserSearch(value)}
                                    options={organizerLists}
                                />
                            </Form.Item>
                        </Form>
                    </Flex>
                    <CustomDatePicker selectedDate={selectedDate} onDateChange={handleDateChange} />
                    <Flex align='center' gap={8}>
                        <div style={{ paddingRight: '10px' }}>
                            <span style={{ backgroundColor: '#B7E7AF', padding: '0px 10px', borderRadius: '4px' }}></span>
                            <span style={{ marginLeft: '8px' }}><b>Working Hours</b></span>
                        </div>
                        <div>
                            <span style={{ backgroundColor: '#FFCFC5', padding: '0px 10px', borderRadius: '4px' }}></span>
                            <span style={{ marginLeft: '8px' }}><b>Break Hours</b></span>
                        </div>
                        <Flex>
                            {
                                (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                                <Button onClick={() => showModal()}>
                                    Add Time
                                </Button>
                            }
                        </Flex>
                    </Flex>
                    {/* <Row>
                        <Col s={12}>
                            <Title level={5} className='paddingX-1'>Total Hours:</Title>
                        </Col>
                        <Col s={12}>
                            <Title level={5} type='success'>{totalHours} Hours</Title>
                        </Col>
                    </Row> */}
                </Flex>
                <Table
                    columns={columns}
                    dataSource={individualTimeTrackingDataList}
                    pagination={false}
                    scroll={{ x: 2200 }}
                    bordered
                    rowKey={(record) => record?._id}

                    expandable={{
                        expandedRowRender: (record) => (
                            <Row className='paddingY-1' gutter={18}>
                                <Col xs={24} sm={24} md={24} lg={12} xl={14} xxl={17}>
                                    <HereMap isExpand={isExpand} record={record} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={7}>
                                    <Row>
                                        <Col xs={24} style={{ paddingBottom: '10px' }}>
                                            <Row gutter={10}>
                                                <Col xs={24} sm={16}>
                                                    <Flex vertical justify='center' className='h-100'>
                                                        <Flex align='center'>
                                                            <Image src={WatchTime} height={32} preview={false} />
                                                            <Title level={5} className='paddingX-1'>Starting Time: </Title>
                                                            <Text>{record?.timeEntries[0]?.start}</Text>
                                                        </Flex>
                                                        <Flex align='center' className='paddingY-1 paddingX-1'>
                                                            <LocationIcon color={primary} />
                                                            <Text className='paddingX-1' style={{ paddingTop: '6px', fontSize: '15px' }}>
                                                                {`${Number(record?.timeEntries[0]?.lat)?.toFixed(5)}, ${Number(record?.timeEntries[0]?.long)?.toFixed(5)}`}
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                </Col>
                                                <Col xs={24} sm={8}>
                                                    <div className='rounded-2 bg-primary-light padding-1' style={{ border: `1px solid ${primary}` }}>
                                                        <Flex vertical gap={16} align='center'>
                                                            <Flex align='center' gap={8}>
                                                                <Image src={WorkingTime} height={52} preview={false} />
                                                                <Title level={5}>Working Time</Title>
                                                            </Flex>
                                                            <Title level={4}>{record?.workingHours && record?.workingHours}</Title>
                                                        </Flex>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24}>
                                            <Row gutter={10}>
                                                <Col xs={24} sm={16}>
                                                    <Flex vertical justify='center' className='h-100'>
                                                        <Flex align='center'>
                                                            <Image src={WatchTime} height={32} preview={false} />
                                                            <Title level={5} className='paddingX-1'>End Time: </Title>
                                                            <Text>{record?.timeEntries[record?.timeEntries?.length - 1]?.end}</Text>
                                                        </Flex>
                                                        <Flex align='center' className='paddingY-1 paddingX-1'>
                                                            <LocationIcon color={secondary} />
                                                            <Text className='paddingX-1' style={{ paddingTop: '6px', fontSize: '15px' }}>
                                                                {`${Number(record?.timeEntries[record?.timeEntries?.length - 1].lat)?.toFixed(5)}, ${Number(record?.timeEntries[record?.timeEntries?.length - 1]?.long)?.toFixed(5)}`}
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                </Col>
                                                <Col xs={24} sm={8}>
                                                    <div className='rounded-2 bg-secondary-light padding-1' style={{ border: `1px solid ${secondary}` }}>
                                                        <Flex vertical gap={16} align='center'>
                                                            <Flex align='center' gap={8}>
                                                                <Image src={BreakTime} height={52} preview={false} />
                                                                <Title level={5}>Break Time</Title>
                                                            </Flex>
                                                            <Title level={4}>{record?.breakHours && record?.breakHours}</Title>
                                                        </Flex>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ),
                        rowExpandable: () => true,
                        expandedRowKeys: expandRow,
                        onExpand: (e, data) => {
                            setExpandRow(e ? [data?._id] : []);
                            setIsExpand(e);
                        }
                    }}
                />

                <Modal
                    centered
                    width={650}
                    open={isModalOpen}
                    closeIcon={<CloseIcon color={black} role='button' />}
                    title={<Fragment><Title level={4} type='success'>{editId ? 'Edit' : 'Add'} Project Time</Title><Divider /></Fragment>}
                    footer={null}
                    onCancel={handleModalCancel}
                    destroyOnClose
                    modalRender={(dom) => (
                        <Form
                            layout='vertical'
                            form={form}
                            name='lagar-form'
                            requiredMark={(label, isRule) => {
                                return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                            }}
                            initialValues={{ allTime: [''] }}
                            onFinish={(values) => handleOnFinish(values)}
                        >
                            {dom}
                        </Form>
                    )}
                >
                    <Row>
                        <Col xs={24} sm={12} style={{ paddingRight: '10px' }}>
                            <Form.Item
                                name='date'
                                label='Date'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a date!',
                                    },
                                ]}
                            >
                                <DatePicker format={'DD-MM-YYYY'} style={{ padding: '6px', width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name='user'
                                label='User'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a user!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='Select User'
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={organizerLists}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.List
                                name='timeEntries'
                                initialValue={[{ type: '', start: '', end: '' }]}
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }, index) => (
                                            <Row className='w-100' gutter={10} align='center' justify='center'>
                                                <Col xs={7}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'type']}
                                                        label='Work/Break'
                                                        rules={[{ required: true, message: 'Type is required!' }]}
                                                    >
                                                        <Select
                                                            placeholder='Please Select'
                                                            className='w-100'
                                                            allowClear
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={[
                                                                { value: 'work', label: 'Work' },
                                                                { value: 'break', label: 'Break' },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={7}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'start']}
                                                        label='Start Time'
                                                        rules={[{ required: true, message: 'Start time is required!' }]}
                                                    >
                                                        <TimePicker
                                                            format="HH:mm"
                                                            className='w-100'
                                                            disabled={index > 0}
                                                            disabledTime={() => index > 0 ? disabledStartTime(index) : {}}
                                                            onChange={(time) => handleStartTimeChange(index, time)}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={7}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'end']}
                                                        label='End Time'
                                                        rules={[{ required: true, message: 'End time is required!' }]}
                                                    >
                                                        <TimePicker
                                                            format="HH:mm"
                                                            className='w-100'
                                                            disabledTime={() => timeSelections[index]?.disabledEndTime || disabledEndTime(index)}
                                                            onChange={(time) => handleEndTimeChange(index, time)}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={3}>
                                                    <Flex align='end' justify='end' className='h-100'>
                                                        <Form.Item>
                                                            {
                                                                index === fields.length - 1
                                                                    ? <PlusIcon
                                                                        size={28}
                                                                        color={black}
                                                                        role='button'
                                                                        onClick={() => handleAdd(add)}
                                                                    />
                                                                    : <DeleteIcon role='button' onClick={() => { remove(name); resetTimeSelectionsAfterRemove(index); }} />
                                                            }
                                                        </Form.Item>
                                                    </Flex>
                                                </Col>
                                            </Row>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Col>

                        <Col xs={24}>
                            <Form.Item className='margin-0'>
                                <Flex align='center' gap={4} justify='flex-end'>
                                    <Button type='primary' danger htmlType='button' onClick={handleModalCancel}>Cancel</Button>
                                    <Button type='primary' htmlType='submit' loading={timeTracking?.loading}>Submit</Button>
                                </Flex>
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>
            </div>
        </Fragment>
    );
};

export default IndividualTimeTracking;
