import React, { Fragment, useEffect, useState } from 'react'

import { Button, Card, Col, Collapse, Divider, Dropdown, Flex, Form, Input, Row, Select, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { deleteWareHouse, getWareHouseListsData } from './redux/slice';

import SearchIcon from '../../components/icon/SearchIcon';
import DotsMenuICon from '../../components/icon/DotsMenuICon';
import EditIcon from '../../components/icon/EditIcon';
import DeleteIcon from '../../components/icon/BackArrowIcon';
import { lightGreen, lightPrimary } from '../../utils/theme/config';
import ExpandIcon from '../../components/icon/ExpandIcon';
import { getCityListing, getLagarListing } from '../../redux/slice/global';

import '../../styles/pages/warehouse.scss';

const { Title } = Typography;

const WarehouseManagement = () => {
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { wareHouse, auth, listing } = useSelector(state => state);
    const [search, setSearch] = useState('');
    const [cityListing, setCityListing] = useState([]);
    const [wareHouseLists, setWareHouseLists] = useState([]);
    const [lagarOptions, setLagarOptions] = useState([]);
    const [permission, setPermission] = useState({});

    useEffect(() => {
        setPermission(listing?.hasMenuPermission?.Warehouse?.children?.WHPlaceMgmt);
    }, [listing?.hasMenuPermission])

    useEffect(() => {
        dispatch(getCityListing())
    }, [])

    useEffect(() => {
        if (listing.listing?.lagar?.length > 0) {
            setLagarOptions(listing.listing?.lagar);
        }
        if (listing.listing?.city?.length > 0) {
            setCityListing(listing?.listing?.city)
        }
    }, [listing?.listing?.city, listing.listing?.lagar])

    useEffect(() => {
        if (auth?.decodeToken?.organizer) {
            dispatch(getLagarListing(auth?.decodeToken?.organizer));
        }
    }, [auth?.decodeToken?.organizer])

    const items = [];

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate)) {
        items.push({
            label: 'Edit',
            key: '1',
            icon: <EditIcon size={18} />,
        })
    }

    if (Object.keys(permission)?.length > 0 && (permission?.canDelete)) {
        items.push({
            label: 'Delete',
            key: '2',
            icon: <DeleteIcon size={18} />,
        })
    }

    // Get Ware House Data
    useEffect(() => {
        auth?.decodeToken?.organizer && getWareHouseList();
    }, [auth, search]);

    const getWareHouseList = () => {
        dispatch(getWareHouseListsData({
            search: search?.trim(),
            filters: {
                city: [],
                lagar: [],
            },
            organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer
        }))
    };

    // Set WareHouse Data
    useEffect(() => {
        setWareHouseLists(wareHouse.wareHouseList);
    }, [wareHouse.wareHouseList]);

    const handleMenuClick = ({ key }, wareHouseId) => {
        if (key === '1') {
            navigate(`/warehouse-management/wh-place/edit/${wareHouseId}`)
        } else if (key === '2') {
            dispatch(deleteWareHouse({
                id: wareHouseId,
                sortBy: '',
                order: '',
                search: '',
                page: 1,
                limit: 10,
                organizer: auth?.decodeToken?.organizer
            }))
        }
    };

    const handleOnFinish = (values) => {
        if (values?.city || values?.lagar) {
            dispatch(getWareHouseListsData({
                search: search?.trim(),
                filters: {
                    city: values?.city,
                    lagar: values?.lagar,
                },
                organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer
            }))
        }
    };

    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    const filterItems = [
        {
            key: '1',
            label: <Title level={4} type='success' className='text-primary'>Filter</Title>,
            children: <Fragment>
                <Form
                    form={form}
                    className='user-filter'
                    name='filters'
                    colon={false}
                    layout='vertical'
                    onFinish={handleOnFinish}
                >
                    <Row gutter={18}>

                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24} >
                            <Form.Item
                                name='lagar'
                                label='Lagar'
                            >
                                <Select
                                    mode='multiple'
                                    showSearch
                                    allowClear
                                    placeholder='Please Select Lagar'
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={lagarOptions}
                                    tagRender={(props) => (
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                padding: '5px 10px',
                                                borderRadius: '4px',
                                                backgroundColor: '#f0f0f0',
                                                margin: '2px',
                                                color: '#333',
                                            }}
                                        >
                                            {props.label}
                                            {props.closable && (
                                                <span
                                                    onClick={props.onClose}
                                                    style={{
                                                        marginLeft: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    &times;
                                                </span>
                                            )}
                                        </span>
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='city'
                                label='City'
                            >
                                <Select
                                    showSearch
                                    mode='multiple'
                                    allowClear
                                    placeholder='Please Select City'
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={cityListing}
                                    tagRender={(props) => (
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                padding: '5px 10px',
                                                borderRadius: '4px',
                                                backgroundColor: '#f0f0f0',
                                                margin: '2px',
                                                color: '#333',
                                            }}
                                        >
                                            {props.label}
                                            {props.closable && (
                                                <span
                                                    onClick={props.onClose}
                                                    style={{
                                                        marginLeft: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    &times;
                                                </span>
                                            )}
                                        </span>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' htmlType='button' onClick={() => { form.resetFields(); getWareHouseList() }}>Clear All Filter</Button>
                        <Button type='primary' htmlType='submit'>Apply All Filter</Button>
                    </Flex>
                </Form>
            </Fragment >,
        }
    ];

    return (
        <Fragment>
            <Flex vertical gap={28} className='padding-1 warehouse'>
                <Row
                    justify='space-between'
                    align='middle'
                    className='bg-white padding-1 rounded-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={24}
                        md={(Object.keys(permission)?.length > 0 && permission?.canCreate) ? 16 : 24}
                        lg={(Object.keys(permission)?.length > 0 && permission?.canCreate) ? 16 : 24}
                        xl={(Object.keys(permission)?.length > 0 && permission?.canCreate) ? 16 : 24}
                        xxl={(Object.keys(permission)?.length > 0 && permission?.canCreate) ? 16 : 24}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none' xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
                                <Title level={4} type='success'>Warehouse Place Management</Title>
                            </Col>
                            <Col flex='auto' xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                                <Input
                                    onChange={(e) => {
                                        setSearch(e?.target?.value)
                                    }}
                                    size='large'
                                    className='bg-white'
                                    placeholder='Search here...'
                                    prefix={<SearchIcon size={18} color='#A20417' />}
                                />
                            </Col>
                        </Row>
                    </Col>

                    {
                        (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                            <Flex justify='end'>
                                <Button onClick={() => navigate('/warehouse-management/wh-place/add')}>
                                    Add Reck and Self Number
                                </Button>
                            </Flex>
                        </Col>
                    }
                </Row>

                <Flex className='filter-margin-top'>
                    <Collapse
                        items={filterItems}
                        defaultActiveKey={['1']}
                        className='w-100'
                        bordered={false}
                        expandIcon={customExpandIcon}
                        expandIconPosition={'end'}
                    />
                </Flex>

                <Row gutter={[16, 22]}>
                    {wareHouseLists?.map((item, index) => {
                        return <Col xs={24} sm={24} lg={12} md={12} xl={8} xxl={6} key={index}>
                            <Card
                                bordered={false}
                                style={{ backgroundColor: lightPrimary }}
                                className='h-100'
                            >
                                <Flex vertical justify='space-between' className='h-100'>
                                    <Row>
                                        <Col xs={7}>Unique ID </Col>
                                        <Col xs={10} className='text-center'>:</Col>
                                        <Col xs={7}>{item?.uniqueIdentifier}</Col>
                                    </Row>

                                    <Row className='paddingY-1'>
                                        <Col xs={7}>Lagar ID</Col>
                                        <Col xs={10} className='text-center'>:</Col>
                                        <Col xs={7}>{item?.lagarUniqueIdentifire}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={7}>Lagar name</Col>
                                        <Col xs={10} className='text-center'>:</Col>
                                        <Col xs={7}>{item?.lagar}</Col>
                                    </Row>

                                    <Row className='padding-top-1'>
                                        <Col xs={7}>Rack Number</Col>
                                        <Col xs={10} className='text-center'>:</Col>
                                        <Col xs={7} >{item?.rackNumber}</Col>
                                    </Row>

                                    <Flex className='paddingY-2'>
                                        <Divider variant='solid' style={{ color: lightGreen, backgroundColor: lightGreen }} />
                                    </Flex>

                                    <Row>
                                        <Col xs={7}>Total Self</Col>
                                        <Col xs={10} className='text-center'>:</Col>
                                        <Col xs={7}><Flex justify='end'>{item?.rackSelfNumber?.length}</Flex></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={19} sm={22} md={20} lg={20} xl={22} xxl={22} className='paddingY-2'>
                                            <Row gutter={[21, 8]} wrap>
                                                {
                                                    item?.rackSelfNumber?.map((number, ind) => {
                                                        return ind <= 8 && (
                                                            <Col xs={12} sm={6} lg={8} md={12} xl={8} xxl={8}>
                                                                <Tag className='margin-0 w-100 text-center' color={lightGreen}>
                                                                    <Flex align='center' justify='center' gap={10} className='padding-1'>
                                                                        {item?.rackNumber} - {number}
                                                                    </Flex>
                                                                </Tag>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>

                                        {
                                            Object.keys(permission)?.length > 0 && (permission?.canUpdate || permission?.canDelete) &&
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='paddingY-2'>
                                                <Flex className='h-100 w-100' justify='end' align='end'>
                                                    <Dropdown
                                                        menu={{
                                                            items,
                                                            onClick: (key) => handleMenuClick(key, item?._id),
                                                        }}
                                                        trigger={['click']}
                                                    >
                                                        <DotsMenuICon role='button' />
                                                    </Dropdown>
                                                </Flex>
                                            </Col>
                                        }
                                    </Row>
                                </Flex>

                            </Card>
                        </Col>
                    })}

                </Row >

            </Flex >
        </Fragment >
    )
}

export default WarehouseManagement;
