import React, { Fragment, useEffect, useState } from 'react';

import { Layout, Button, Flex, Avatar, Typography, Space, Dropdown, Col, Row, Form, Modal, Divider, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';

import { setLanguage } from '../../redux/slice/global';
import { setIsAuthorize } from '../../pages/auth/redux/slice';

import EnglishIcon from '../../components/icon/EnglishIcon';
import GermanIcon from '../../components/icon/GermanIcon';
import MenuIcon from '../../components/icon/MenuIcon';
import DownArrowIcon from '../../components/icon/DownArrowIcon';
import profilImage from '../../assets/images/avatar.jpg';
import CloseIcon from '../../components/icon/CloseIcon';

import { PasswordRegex } from '../../utils/constraints';

import { black } from '../../utils/theme/config';

import '../../styles/layout/header.scss';

const { Header } = Layout;
const { Text, Title, Link } = Typography;

const HeaderComponent = ({ collapsed, setCollapsed }) => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { auth } = useSelector(state => state)
    const [loginUser, setLoginUser] = useState({});
    const [profile, setProfile] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState({ icon: <EnglishIcon />, language: 'English' });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };


    const handleLogout = () => {
        document.cookie = `token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        dispatch(setIsAuthorize(''))
        navigate('/user/authentication/sign-in');
    };

    // Decode login user token //
    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
            setProfile(user?.profilePicture ? `${process.env.REACT_APP_API_URI}/profiles/${user?.profilePicture}` : profilImage)
        }
    }, []);

    const handleProfileSetting = () => {
        navigate('/profile-settings')
    }

    const handleOnFinish = (values) => {
        setIsModalOpen(false)
    }

    const handleModalCancel = () => {
        form.resetFields()
        setIsModalOpen(false)
    }

    const items = [
        {
            key: '0',
            label: <Text onClick={handleProfileSetting} className='paddingX-1'>Profile Setting</Text>,
        },
        {
            key: '1',
            label: <Text onClick={showModal} className='paddingX-1'>Change Password</Text>,
        },
        {
            key: '2',
            label: <Text type='danger' onClick={handleLogout} className='paddingX-1'>Logout</Text>,
        },
    ];

    // const languageItems = [
    //     {
    //         key: '0',
    //         label: <Fragment>
    //             <Flex align='center' gap={6} className='language-item'>
    //                 <Text className='padding-top-1'><EnglishIcon /></Text>
    //                 <Text level={5} className='margin-0 padding-bottom-1'>English</Text>
    //             </Flex>
    //         </Fragment>,
    //         onClick: () => { setSelectedLanguage({ icon: <EnglishIcon />, language: 'English' }); dispatch(setLanguage('en')) },
    //     },
    //     {
    //         key: '1',
    //         label: <Fragment>
    //             <Flex align='center' gap={6} className='language-item'>
    //                 <Text className='padding-top-1'><GermanIcon /></Text>
    //                 <Text level={5} className='margin-0 padding-bottom-1'>German</Text>
    //             </Flex>
    //         </Fragment>,
    //         onClick: () => { setSelectedLanguage({ icon: <GermanIcon />, language: 'German' }); dispatch(setLanguage('de')) },
    //     }
    // ]

    const capitalizeFirstLetter = (str) => {
        if (!str) return '';
        return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
    };

    return (
        <Fragment>
            <Header className='rounded-top-2 header'>
                <Flex justify='space-between' align='center'>
                    <Button
                        type='text'
                        icon={<MenuIcon role={'button'} />}
                        onClick={() => setCollapsed(!collapsed)}
                        className='h-100 paddingY-1'
                    />
                    <Flex gap={12} className='paddingX-1'>
                        {/* <Flex justify='center' align='center'>
                            <Dropdown
                                menu={{
                                    items: languageItems,
                                    selectable: true,
                                    defaultSelectedKeys: ['0'],
                                    className: 'language-dropdown'
                                }}
                                className='header-dropdown'
                                trigger={['click']}
                            >
                                <Link>
                                    <Space role='button'>
                                        <Flex justify='center' align='center' gap={6}>
                                            <Text className='padding-top-1'>{selectedLanguage?.icon}</Text>
                                            <Title level={5} className='margin-0 padding-bottom-1'>{selectedLanguage?.language}</Title>
                                        </Flex>
                                        <DownArrowIcon role='button' size={20} color={black} />
                                    </Space>
                                </Link>
                            </Dropdown>
                        </Flex> */}
                        <Avatar
                            src={profile}
                            size='large'
                        />
                        <Flex vertical>
                            <Dropdown
                                menu={{
                                    items,
                                    selectable: true,
                                    defaultSelectedKeys: ['0'],
                                }}
                                className='header-dropdown'
                                trigger={['click']}
                            >
                                <Link>
                                    <Space role='button'>
                                        <Title type='success' level={5}>
                                            {Object.keys(loginUser).length > 0 &&
                                                loginUser?.company ? capitalizeFirstLetter(loginUser?.company) :
                                                (loginUser?.firstName
                                                    ? capitalizeFirstLetter(loginUser.firstName)
                                                    : 'User')
                                            }
                                        </Title>
                                        <DownArrowIcon role='button' size={20} />
                                    </Space>
                                </Link>
                            </Dropdown>
                            <Text type='secondary'>{auth?.decodeToken?.permissions?.length && auth?.decodeToken?.permissions[0]?.name}</Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Modal
                    centered
                    open={isModalOpen}
                    closeIcon={<CloseIcon color={black} role='button' />}
                    title={<Fragment><Title level={4} type='success'>Change Password</Title><Divider /></Fragment>}
                    footer={null}
                    onCancel={handleModalCancel}
                    destroyOnClose
                    modalRender={(dom) => (
                        <Form
                            layout='vertical'
                            form={form}
                            name='lagar-form'
                            requiredMark={(label, isRule) => {
                                return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                            }}
                            onFinish={(values) => handleOnFinish(values)}
                        >
                            {dom}
                        </Form>
                    )}
                >
                    <Row gutter={24}>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='currantPassword'
                                label='Currant Password'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Current password is required!'
                                    },
                                ]}
                            >
                                <Input.Password disabled={false} placeholder='Currant Password' />
                            </Form.Item>
                        </Col>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='password'
                                label='New Password'
                                rules={[
                                    {
                                        required: true,
                                        message: 'New password is required!'
                                    },
                                    {
                                        pattern: new RegExp(PasswordRegex),
                                        message: 'Password must include at least one number, one special character, and one uppercase letter!'
                                    }
                                ]}
                            >
                                <Input.Password disabled={false} placeholder='New Password' />
                            </Form.Item>
                        </Col>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='confirmPassword'
                                label='Re-enter Password'
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Confirm password is required!'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Your password and confirmation password do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password disabled={false} placeholder='Re-enter Password' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' style={{ width: '100%', maxWidth: '10rem' }} htmlType='button' disabled={false} onClick={() => { form.resetFields(); setIsModalOpen(false) }}>Cancel</Button>
                        <Button type='primary' htmlType='submit' disabled={false}>Change Passowrd</Button>
                    </Flex>
                </Modal>
            </Header>
        </Fragment>
    );
};

export default HeaderComponent;
