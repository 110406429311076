import React, { Fragment, useEffect, useState } from 'react';

import { Button, Col, Collapse, Flex, Form, Input, Row, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createDesignation, deleteDesignation, getByIdDesignation, getDesignation, getDesignationListsData, setIsSuccess, setIsUpdating, updateDesignation } from './redux/slice';

import DataTable from '../../components/data-table';
import CommonPopconfirm from '../../components/custom-popconfirm';

import ExpandIcon from '../../components/icon/ExpandIcon';
import BackIcon from '../../components/icon/BackIcon';
import EditIcon from '../../components/icon/EditIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';
import CloseIcon from '../../components/icon/CloseIcon';

import { lightPrimary, lightSecondary } from '../../utils/theme/config';

const { Title, Text } = Typography;

const Designation = () => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { designation, auth, listing } = useSelector(state => state);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [designatioLists, setDesignationLists] = useState([]);
    const [modalType, setModalType] = useState(false);
    const [designationEditId, setDesignationEditId] = useState('');
    const [permission, setPermission] = useState({});

    useEffect(() => {
        setPermission(listing?.hasMenuPermission?.UserManagement?.children?.Designations);
    }, [listing?.hasMenuPermission])

    useEffect(() => {
        getDesignationList()
    }, [page, pageSize, auth])

    // Set Designations Data 
    useEffect(() => {
        setDesignationLists(designation.designationLists);
        setTotalRecords(designation.totalRecords);
    }, [designation.designationLists, designation.totalRecords]);

    // Get Designation Data
    const getDesignationList = () => {
        dispatch(getDesignationListsData({
            page: page,
            limit: pageSize,
            organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer
        }))
    }

    const handleOnFinish = (values) => {
        if (modalType) {
            dispatch(setIsUpdating(false));
        }
        dispatch(getDesignation({ ...values, organizer: auth?.decodeToken?.organizer }));
    };

    // Create and Update Designation
    useEffect(() => {
        if (Object.keys(designation.designation).length > 0) {
            if (!modalType) {
                dispatch(createDesignation({ apiData: designation.designation, organizerID: auth?.decodeToken?.organizer }));
            } else {
                if (designation.isUpdating) {
                    form.setFieldsValue(designation?.designation);
                } else {
                    dispatch(updateDesignation({ apiData: { ...designation.designation, id: designationEditId }, organizerID: auth?.decodeToken?.organizer }));
                }
            }
        }
        if (designation.isSuccess) {
            handleClear();
            setModalType(false);
            dispatch(setIsSuccess(false));
        }
    }, [designation.isUpdating, designation.designation, designation.isSuccess, designationEditId]);

    // Form reset function
    const handleClear = () => {
        form.resetFields();
        setDesignationEditId('');
        setModalType(false);
    };

    // Expand Icon
    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    // Pagination function
    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const handleEditDesignation = (designationId) => {
        setModalType(true);
        setDesignationEditId(designationId);
        dispatch(setIsUpdating(true));
        dispatch(getByIdDesignation(designationId));
    }

    // Table columns
    const columns = [
        {
            title: 'Designation',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '250px',
            render: (_, record) => {
                return (
                    <Fragment>
                        {
                            record?.isActive ?
                                <Tag color={lightPrimary} className='text-center'>Active</Tag> :
                                <Tag color={lightSecondary} className='text-center'>Deactivate</Tag>
                        }
                    </Fragment>
                );
            }
        },
    ];

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: <Flex justify='center'><Text>Action</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => {
                return (
                    <Flex justify='center'>
                        {
                            permission?.canUpdate && (
                                <Button
                                    type='text'
                                    className='padding-1'
                                    onClick={() => handleEditDesignation(record?._id)}
                                >
                                    <EditIcon role='button' />
                                </Button>
                            )
                        }
                        {
                            permission?.canDelete && (
                                <Fragment>
                                    {
                                        record?.isActive
                                            ? <CommonPopconfirm
                                                icon={null}
                                                title='Delete Designation'
                                                onConfirm={() => dispatch(deleteDesignation({
                                                    id: record?._id,
                                                    page: page,
                                                    limit: pageSize,
                                                    organizer: auth?.decodeToken?.organizer
                                                }))}
                                                description='Are you sure you want to delete this designation?'
                                                cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                            >
                                                <Button
                                                    type='text'
                                                    className='padding-1'>
                                                    <DeleteIcon role='button' />
                                                </Button>
                                            </CommonPopconfirm>
                                            : <CommonPopconfirm
                                                icon={null}
                                                title='Delete Designation'
                                                onConfirm={() => dispatch(deleteDesignation({
                                                    id: record?._id,
                                                    page: page,
                                                    limit: pageSize,
                                                    organizer: auth?.decodeToken?.organizer
                                                }))}
                                                description='Are you sure you want to delete this designation?'
                                                cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                            >
                                                <Button
                                                    type='text'
                                                    className='padding-1'>
                                                    <CloseIcon role='button' />
                                                </Button>
                                            </CommonPopconfirm>
                                    }
                                </Fragment>
                            )
                        }
                    </Flex>
                );
            },
        });
    }

    const items = [
        {
            key: '1',
            label: <Title level={4} type='success' className='text-primary'>{modalType ? 'Edit User Designation' : 'Add User Designation'}</Title>,
            children: <Fragment>
                <Form
                    form={form}
                    className='user-filter'
                    name='designation'
                    colon={false}
                    layout='vertical'
                    onFinish={handleOnFinish}
                    requiredMark={(label, isRule) => {
                        return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                    }}
                >
                    <Row gutter={18}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24} >
                            <Form.Item
                                name='name'
                                label='User Designation'

                                rules={[{ required: true, message: 'Designation is required!' }]}
                            >
                                <Input placeholder='Please Enter' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' htmlType='button' onClick={handleClear}>Cancel</Button>
                        <Button type='primary' htmlType='submit' loading={designation?.loading}>Submit</Button>
                    </Flex>
                </Form>
            </Fragment>,
        }
    ];

    return (
        <Fragment>
            <Flex vertical gap={12} className='user-management change-tag'>
                <Button
                    type='text'
                    icon={<BackIcon role={'button'} />}
                    className='bg-white paddingX-3'
                    onClick={() => navigate(-1)}
                />
                {
                    (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                    <Flex className='filter-margin-top'>
                        <Collapse
                            items={items}
                            defaultActiveKey={['1']}
                            className='w-100'
                            bordered={false}
                            expandIcon={customExpandIcon}
                            expandIconPosition={'end'}
                        />
                    </Flex>
                }
                <Flex vertical className='bg-white rounded-top-1'>
                    <DataTable
                        isPagination={true}
                        showSorterTooltip={false}
                        total={totalRecords}
                        pageSize={pageSize}
                        currentPage={page}
                        columns={columns}
                        data={designatioLists}
                        handleOnChange={handleOnChange}
                        rowKey={(record) => record?._id}
                    />
                </Flex>
            </Flex>
        </Fragment>
    );
};

export default Designation;
