import React, { Fragment, useEffect, useState } from 'react'

import { Button, Col, Flex, Input, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import SearchIcon from '../../components/icon/SearchIcon';
import EditIcon from '../../components/icon/EditIcon';

import DataTable from '../../components/data-table';
import DeleteIcon from '../../components/icon/DeleteIcon';
import CloseIcon from '../../components/icon/CloseIcon';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStammUser, getStammdatenData } from './redux/slice';
import { setLoginUserId, setLoginUserRole } from '../user/redux/slice';
import CommonPopconfirm from '../../components/custom-popconfirm';

const { Title, Text } = Typography;

const Stammdaten = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { stammdaten, listing } = useSelector(state => state)
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [stammUserList, setStammUserList] = useState([]);
    const [loginUser, setLoginUser] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [search, setSearch] = useState('');
    const [permission, setPermission] = useState({});

    useEffect(() => {
        setPermission(listing?.hasMenuPermission?.Stammdaten?.children?.StammUser);
    }, [listing?.hasMenuPermission])

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
            dispatch(setLoginUserId(user._id))
            dispatch(setLoginUserRole(user.role))
        }
    }, [])

    useEffect(() => {
        getStammdatenUserListData()
    }, [search, pageSize, page, totalRecords, sortBy, order, loginUser])

    const getStammdatenUserListData = () => {
        if (loginUser?.organizer) {
            dispatch(getStammdatenData({
                sortBy: sortBy,
                order: order,
                search: search,
                page: page,
                limit: pageSize,
                organizer: loginUser?.organizer
            }))
        }
    }

    useEffect(() => {
        setStammUserList(stammdaten?.stammdatenDataLists)
        setTotalRecords(stammdaten?.totalRecords)
    }, [stammdaten?.stammdatenDataLists, stammdaten?.totalRecords])

    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'company',
            key: 'company',
            sorter: (_, __, order) => handleSortFunction(order, 'company'),
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (_, __, order) => handleSortFunction(order, 'firstName'),
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (_, __, order) => handleSortFunction(order, 'lastName'),
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Phone number',
            dataIndex: 'phone',
            key: 'phone',
            sorter: (_, __, order) => handleSortFunction(order, 'phone'),
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (_, __, order) => handleSortFunction(order, 'email'),
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            sorter: (_, __, order) => handleSortFunction(order, 'address'),
            render: (text) => <Text>{text?.streetNo}</Text>,
        },
    ];

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: <Flex justify='center'><Text>Action</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => {
                return (
                    <Flex justify='center'>
                        {
                            permission?.canUpdate &&
                            <Button
                                type='text'
                                className='padding-1'
                                onClick={() => navigate(`/stammdaten/edit/${record?._id}`)}
                            >
                                <EditIcon role='button' />
                            </Button>
                        }
                        {
                            permission?.canDelete &&
                            <Fragment>
                                {
                                    record?.isActive
                                        ? <CommonPopconfirm
                                            icon={null}
                                            title='Delete Designation'
                                            onConfirm={() => dispatch(deleteStammUser({
                                                id: record?._id,
                                                search: '', sortBy: '', order: '', page: 1, limit: 10, organizer: loginUser?.organizer
                                            }))}
                                            description='Are you sure you want to delete this designation?'
                                            cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                        >
                                            <Button
                                                type='text'
                                                className='padding-1'>
                                                <DeleteIcon
                                                    role='button'
                                                />
                                            </Button>
                                        </CommonPopconfirm>
                                        :
                                        <CommonPopconfirm
                                            icon={null}
                                            title='Delete Designation'
                                            onConfirm={() => dispatch(deleteStammUser({
                                                id: record?._id,
                                                search: '', sortBy: '', order: '', page: 1, limit: 10, organizer: loginUser?.organizer
                                            }))}
                                            description='Are you sure you want to delete this designation?'
                                            cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                        >
                                            <Button
                                                type='text'
                                                className='padding-1'>
                                                <CloseIcon role='button' />
                                            </Button>
                                        </CommonPopconfirm>
                                }
                            </Fragment>
                        }
                    </Flex >
                );
            },
        })
    }

    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    return (
        <Fragment>
            <Flex vertical gap={24}>
                <Row
                    justify='space-between'
                    align='middle'
                    className='bg-white rounded-1 padding-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none'>
                                <Title level={4} type='success'>Stamm User List</Title>
                            </Col>
                            <Col flex='auto' xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Input
                                    value={search}
                                    placeholder='Search here...'
                                    onChange={(e) => setSearch(e.target.value)}
                                    prefix={<SearchIcon color='#000' size={20} />}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                        <Flex justify='end'>
                            {
                                (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                                <Button onClick={() => navigate('/stammdaten/add')} >Add Stamm User</Button>
                            }
                        </Flex>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <DataTable
                            columns={columns}
                            pageSize={pageSize}
                            currentPage={page}
                            total={totalRecords}
                            scrollX={1000}
                            showSorterTooltip={false}
                            isPagination={true}
                            handleOnChange={handleOnChange}
                            data={stammUserList}
                            rowKey={(record) => record?._id}
                        />
                    </Col>
                </Row>
            </Flex>
        </Fragment>
    )
}

export default Stammdaten;
