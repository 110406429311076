import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    accessToken: '',
    decodeToken: {},
    // clientPackage: '66fd12139aaabb2dc56f55a2',
    loading: false,
    error: null,
    isSuccess: false,
    userEmail: {},
    otpData: {},
    OtpVerifyToken: '',
    isVerifyEmail: false,
    invitationMsg: {},
    isInvitation: false,
    isAuthorize: '',
};

export const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        getAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        getDecodeToken: (state, action) => {
            state.decodeToken = action.payload;
        },
        setOtpVerifyToken: (state, action) => {
            state.OtpVerifyToken = action.payload;
        },
        setEmail: (state, action) => {
            state.userEmail = action.payload;
        },
        setOtp: (state, action) => {
            state.otpData = action.payload;
        },
        setVerifyEmail: (state, action) => {
            state.isVerifyEmail = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.isSuccess = action.payload;
        },
        setInvitationMsg: (state, action) => {
            state.invitationMsg = action.payload;
        },
        setIsInvitation: (state, action) => {
            state.isInvitation = action.payload;
        },
        setIsAuthorize: (state, action) => {
            state.isAuthorize = action.payload;
        }
    },
});
export const onOrganizerBoardring = createAsyncThunk('onOrganizerBoardring', (id, { dispatch }) => {
    axios.get(`/organizer/onboarding?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            if (response.data.isInitialSignIn) {
                dispatch(setIsAuthorize(response.data.isInitialSignIn))
            } else {
                dispatch(setIsAuthorize(''))
            }
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
    })
});

export const onBoardring = createAsyncThunk('onBoardring', (id, { dispatch }) => {
    axios.get(`/user/onboarding?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            if (response.data.isInitialSignIn) {
                dispatch(setIsAuthorize(response.data.isInitialSignIn))
            } else {
                dispatch(setIsAuthorize(''))
            }
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
    })
});

export const resendInvitation = createAsyncThunk('resendInvitation', (userId, { dispatch }) => {
    dispatch(setLoading(true));
    axios.get(`/authentication/resend-invitation?id=${userId}`).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false));
    })
});

export const createPassword = createAsyncThunk('setPassword', (passwordData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/authentication/set-password`, passwordData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false));
    })
});

export const loginUser = createAsyncThunk('signIn', (loginData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/authentication/sign-in`, loginData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getAccessToken(response.data.accessToken));
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false));
    })
});

export const verifyEmailandRequestOtp = createAsyncThunk('requestOTP', (email, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/authentication/request-otp`, email).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(setIsSuccess(true));
            dispatch(setVerifyEmail(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false));
    })
});

export const resendOTP = createAsyncThunk('resendOTP', (email, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/authentication/resend-otp`, email).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false));
    })
});

export const verifyOTP = createAsyncThunk('verifyOTP', (otp, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/authentication/verify-otp`, otp).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: 'OTP verification successful! You can now proceed!', type: 'success' });
            dispatch(setOtpVerifyToken(response.data?.resetPasswordToken));
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false));
    })
});

export const resetPassword = createAsyncThunk('resetPassword', (passwordData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/authentication/reset-password`, passwordData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false));
    })
});

export const checkInvitation = createAsyncThunk('checkInvitation', (userId, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/authentication/check-invitation?id=${userId}`).then((response) => {
        if (response.data.isSuccess && response.data.status === 200) {
            dispatch(setInvitationMsg({ title: response.data.messageOne, description: response.data.messageTwo }));
            dispatch(setIsInvitation(true));
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setInvitationMsg, setIsInvitation, setLoading, setIsSuccess, getAccessToken, setEmail, setOtp, setOtpVerifyToken, setVerifyEmail, getDecodeToken, setIsAuthorize } = slice.actions;

export default slice.reducer;
