import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    profile: {},
    loading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
};

export const slice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.profile = {};
            state.isSuccess = action.payload;
        },
        getProfile: (state, action) => {
            state.profile = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
    },
});

export const getByIdUserProfileData = createAsyncThunk('getByIdUserProfileData', (id, { dispatch }) => {
    axios.get(`/profile/?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getProfile(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    })
});

export const updateUserProfile = createAsyncThunk('updateUserProfile', (userData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/profile/update`, userData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(setIsSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getProfile, setIsUpdating, setIsSuccess } = slice.actions;

export default slice.reducer;
