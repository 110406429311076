import React, { useState, useEffect } from 'react';
import { Layout, Menu, Image, Flex, Typography } from 'antd';
import { useLocation } from 'react-router-dom';

import logo from '../../assets/logo/logo.svg';
import '../../styles/layout/sidebar.scss';

const { Sider } = Layout;
const { Title } = Typography;

const drawerWidth = 260;

const SidebarComponent = ({ collapsed, setCollapsed, filteredRoutes }) => {
    const [openKeys, setOpenKeys] = useState([]);
    const [selectedKey, setSelectedKey] = useState('');

    const location = useLocation();

    const findOpenKeys = (routes, pathname) => {
        for (const route of routes) {
            if (route.children) {
                const childRoute = route.children.find(child => child.label?.props?.to === pathname);
                if (childRoute) {
                    return [route.key];
                } else {
                    const parentKey = findOpenKeys(route.children, pathname);
                    if (parentKey) return parentKey;
                }
            }
        }
        return null;
    };

    useEffect(() => {
        if (filteredRoutes?.length > 0) {
            const currentRoute = filteredRoutes.find(route => route.label?.props?.to === location.pathname);

            if (currentRoute) {
                setSelectedKey(currentRoute.key);
            } else {
                const openKey = findOpenKeys(filteredRoutes, location.pathname);
                if (openKey) {
                    setOpenKeys(openKey);
                    const childRoute = filteredRoutes.flatMap(route => route.children || [])
                        .find(child => child.label?.props?.to === location.pathname);
                    if (childRoute) {
                        setSelectedKey(childRoute.key);
                    }
                }
            }
        }
    }, [location, filteredRoutes]);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    };

    return (
        <Sider
            className='rounded-2'
            breakpoint='lg'
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={drawerWidth}
            onBreakpoint={(broken) => setCollapsed(broken)}
        >
            <Flex align='center' justify='center' gap={16} className='padding-1'>
                <Image src={logo} preview={false} />
                {!collapsed && <Title type='danger' level={3}>Fliesen</Title>}
            </Flex>
            <Menu
                mode='inline'
                theme='light'
                className='rounded-2 padding-top-2 sidebar-menu'
                selectedKeys={[selectedKey]}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                items={filteredRoutes}
            />
        </Sider>
    );
};

export default SidebarComponent;
