import React, { Fragment, useEffect, useState } from 'react';

import { Button, Card, Col, Divider, Flex, Form, Input, Row, Select, Typography, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';

import UploadIcon from '../../components/icon/UploadIcon';

const { Title, Text } = Typography;

const EditDocument = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [showDescription, setShowDescription] = useState(false);

    const onFinish = (values) => {};

    const handleDocumentTypeChange = (value) => {
        if (value === 'others') {
            setShowDescription(true);
        } else {
            setShowDescription(false);
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            documentName: 'Adara Maxwell',
            documentLink: 'Dignissimos vel quia',
            fileName: 'Marsden Benson',
            documentType: 'others',
            description: 'Consequatur Velit'
        })

        //if documentType is 'others' set setShowDescription(true)
    }, [])

    return (
        <Fragment>
            <Card className='bg-white'>

                <Title level={4} type='success' className='paddingY-1' >Edit Document</Title>
                <Divider />

                <Form
                    form={form}
                    name='user'
                    layout='vertical'
                    className='padding-top-2'
                    onFinish={onFinish}
                    requiredMark={false}
                >
                    <Row>
                        <Col xs={24} sm={24} lg={24} md={24} xl={17} xxl={15}>
                            <Row gutter={18}>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='documentName'
                                        label='Document Name:'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Document Name!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder='Please Enter Document Name'
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name='documentLink'
                                        label='Document Link:'
                                    >
                                        <Input
                                            placeholder='Document Link'
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name='fileName'
                                        label='Uploaded File:'
                                    >
                                        <Input
                                            placeholder='Uploaded File'
                                        />
                                    </Form.Item>


                                    <Form.Item
                                        valuePropName='fileList'
                                        label='Uploaded file:'
                                    >
                                        <Upload.Dragger name='files' >
                                            <p className='ant-upload-drag-icon'>
                                                <UploadIcon size={38} />
                                            </p>
                                            <Text className='ant-upload-text' strong>Upload File Here</Text>
                                        </Upload.Dragger>

                                    </Form.Item>

                                </Col>

                                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='documentType'
                                        label='Document Type:'
                                    >
                                        <Select
                                            showSearch
                                            placeholder='Please select'
                                            onChange={handleDocumentTypeChange}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={[
                                                { value: 'passport', label: 'Passport' },
                                                { value: 'personal id', label: 'Personal Id' },
                                                { value: 'driving license', label: 'Driving License' },
                                                { value: 'others', label: 'Others' },
                                            ]}
                                        />
                                    </Form.Item>

                                    {showDescription && (
                                        <Form.Item
                                            name='documentDescription'
                                            label='Document Description:'
                                            rules={[{ required: true, message: 'Please input document description' }]}
                                        >
                                            <Input placeholder='Enter document description' />
                                        </Form.Item>
                                    )}


                                    <Form.Item name='description' label='Description:'>
                                        <TextArea rows={5} placeholder='Description' />
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} lg={24} md={24} xl={24} xxl={24}>
                            <Flex justify='flex-end' gap={10} className='paddingY-2'>
                                <Button danger type='primary' htmlType='button' onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                                <Button type='primary' htmlType='submit'>
                                    Submit
                                </Button>
                            </Flex>
                        </Col>

                    </Row>
                </Form>

            </Card>
        </Fragment>
    )
};

export default EditDocument;
