import * as React from 'react';

const BackArrowIcon = ({
    size = 24,
    color = '#A20417',
    role = 'default',
    style = {},
    ...Rest
}) => {
    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };

    return (
        <svg
            viewBox='0 0 18 18'
            fill='none'
            width={size}
            height={size}
            style={styles}
            {...Rest}
        >
            <path
                d='M7.1775 4.44749L2.625 8.99999L7.1775 13.5525'
                stroke={color}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15.3749 9H2.75244'
                stroke={color}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default BackArrowIcon;
