import React, { Fragment, useEffect, useState } from 'react';

import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { getDecodeToken } from '../auth/redux/slice';
import ClientAddUpdateUser from './ClientAddUpdateUser';
import PlatformAddUpdateUser from './PlatformAddUpdateUser';

const AddUpdateUser = () => {
    const dispatch = useDispatch()
    const [loginUser, setLoginUser] = useState({});

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        if (token) {
            const user = jwtDecode(token);
            dispatch(getDecodeToken(user))
            setLoginUser(user);
        }
    }, [])
    return (
        <Fragment>
            {
                loginUser?.organizer
                    ? <ClientAddUpdateUser />
                    : <PlatformAddUpdateUser />
            }
        </Fragment>
    );
};

export default AddUpdateUser;
