import React, { Fragment, useEffect } from 'react';

import { Button, Checkbox, Col, Flex, Input, Row, Form, Card, Image, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { getAccessToken, loginUser } from './redux/slice';
import { setLoginUserId, setLoginUserRole } from '../user/redux/slice';

import Container from '../../components/container/Container';

import frame from '../../assets/images/sign-In-frame.svg';

const { Title, Text } = Typography;

const SignIn = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { auth } = useSelector(state => state);

    const onFinish = (values) => {
        dispatch(loginUser({ email: values.email, password: values.password }));
    };

    useEffect(() => {
        if (auth?.accessToken) {
            const d = new Date();
            d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
            const expires = `expires=${d.toUTCString()}`;
            document.cookie = `${`token=${auth?.accessToken};${expires};path=/`}`;
            dispatch(getAccessToken(''));
            dispatch(setLoginUserId(null))
            dispatch(setLoginUserRole(''))
            auth?.isAuthorize && auth?.decodeToken?._id === auth?.decodeToken?.organizer ? navigate('/profile-settings') : navigate('/dashboard');
            form.resetFields();
        }
    }, [auth?.accessToken]);

    return (
        <Fragment>
            <Container fluid>
                <Flex vertical className='vh-100' justify='center'>
                    <Card className='padding-1'>
                        <Row gutter={24}>
                            <Col xxl={12} xl={12} lg={0} md={0} sm={0} xs={0}>
                                <Image
                                    className='w-100 h-100'
                                    preview={false}
                                    src={frame}
                                />
                            </Col>
                            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                                <Flex vertical justify='center' className='h-100'>
                                    <Title level={2} className='paddingY-2'>Sign In to Your Account</Title>
                                    <Form
                                        name='basic'
                                        form={form}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        onFinish={onFinish}
                                        autoComplete='off'
                                    >
                                        <Form.Item
                                            label='Email'
                                            name='email'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Email is required!',
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'Invalid email!'
                                                }
                                            ]}
                                        >
                                            <Input placeholder='Enter Your Email' />
                                        </Form.Item>

                                        <Form.Item
                                            label='Password'
                                            name='password'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Passowrd is required!',
                                                },
                                            ]}
                                        >
                                            <Input.Password placeholder='Enter Your Password' />
                                        </Form.Item>

                                        <Flex align='center' justify='space-between' wrap className='padding-1'>
                                            <Form.Item
                                                name='remember'
                                                valuePropName='checked'
                                                className='margin-0'
                                            >
                                                <Checkbox>Keep me logged in</Checkbox>
                                            </Form.Item>

                                            <Link to={'/user/authentication/forgot-password'}><Text strong={true} type='danger'>Forgot your password?</Text></Link >
                                        </Flex>

                                        <Button type='primary' htmlType='submit' block loading={auth?.loading}>
                                            Log in
                                        </Button>
                                    </Form>
                                </Flex>
                            </Col>
                        </Row>
                    </Card>
                </Flex>
            </Container>
        </Fragment >
    );
};

export default SignIn;
