import React, { Fragment } from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const ContenetComponent = ({ children }) => {
    return (
        <Fragment>
            <Content className='w-100 h-100 bg-gray rounded-bottom-2 padding-1'>
                <div className='h-100 rounded-2 padding-1 y-auto'>
                    {children}
                </div>
            </Content>
        </Fragment>
    );
};

export default ContenetComponent;
