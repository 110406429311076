import React, { Fragment, useEffect, useState } from 'react';

import { Button, Col, Flex, Input, Row, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { deletePackage, getPackageListData } from './redux/slice';

import DataTable from '../../components/data-table';
import CommonPopconfirm from '../../components/custom-popconfirm';

import SearchIcon from '../../components/icon/SearchIcon';
import EditIcon from '../../components/icon/EditIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';

const { Title, Text } = Typography;

const Packages = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { packages, listing } = useSelector(state => state);

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [packageList, setPackageList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [search, setSearch] = useState('');
    const [permission, setPermission] = useState({});

    useEffect(() => {
        setPermission(listing?.hasMenuPermission?.Packages?.permissions);
    }, [listing?.hasMenuPermission])

    useEffect(() => {
        getPackageList();
    }, [page, pageSize, search, order, sortBy]);

    const getPackageList = () => {
        dispatch(getPackageListData({
            sortBy,
            order,
            search,
            page: page,
            limit: pageSize
        }))
    };

    // Set Packages
    useEffect(() => {
        setPackageList(packages.packageList);
        setTotalRecords(packages.totalRecords);
    }, [packages.packageList, packages.totalRecords, search]);

    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    const columns = [
        {
            title: 'Package Name',
            dataIndex: 'name',
            key: 'name',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'name'),
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Module List',
            dataIndex: 'menu',
            width: '400px',
            key: 'menu',
            render: (text) => {
                return (
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3
                    }}>
                        {
                            text?.map((value, index) => {
                                return <Tag key={index} className='text-center'><Text>{value}</Text></Tag>
                            })
                        }
                    </div>
                )
            },
        },
    ];

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: <Flex justify='center'><Text>Action</Text></Flex>,
            key: 'action',
            width: '70px',
            render: (_, record) => (
                <Flex justify='center'>
                    {
                        permission?.canUpdate &&
                        <Button
                            type='text'
                            className='padding-1'
                            onClick={() => navigate(`/packages/edit/${record?._id}`)}>
                            <EditIcon role='button' />
                        </Button>
                    }
                    {
                        permission?.canDelete &&
                        <CommonPopconfirm
                            title='Delete the package'
                            icon={null}
                            description='Are you sure you want to delete this package?'
                            onConfirm={() => dispatch(deletePackage({
                                id: record?._id,
                                sortBy: '',
                                order: '',
                                search: '',
                                page: page,
                                limit: pageSize,
                            }))}
                            cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                        >
                            <Button
                                type='text'
                                className='padding-1'>
                                <DeleteIcon
                                    role='button'
                                />
                            </Button>
                        </CommonPopconfirm>
                    }
                </Flex>
            ),
        })
    }

    return (
        <Fragment>
            <Flex vertical gap={24} className='change-tag'>
                <Row
                    justify='space-between'
                    align='middle'
                    className='bg-white padding-1 rounded-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none'>
                                <Title level={4} type='success'>Package List</Title>
                            </Col>
                            <Col flex='auto' xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Input
                                    size='large'
                                    placeholder='Search here...'
                                    onChange={(e) => setSearch(e.target.value)}
                                    prefix={<SearchIcon size={18} />}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                        <Flex justify='end'>
                            {
                                (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                                <Button onClick={() => navigate('/packages/add')} >Add New Package</Button>
                            }
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <DataTable
                            columns={columns}
                            scrollX={900}
                            pageSize={pageSize}
                            currentPage={page}
                            total={totalRecords}
                            isPagination={true}
                            data={packageList}
                            showSorterTooltip={false}
                            handleOnChange={handleOnChange}
                            rowKey={(record) => record?._id}
                        />
                    </Col>
                </Row>
            </Flex>
        </Fragment >
    )
};

export default Packages;
