import React, { Fragment, useEffect } from 'react';

import { Col, Flex, Input, Row, Form, Card, Image, Button, Checkbox, Typography } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { resetPassword, setIsSuccess } from './redux/slice';

import Container from '../../components/container/Container';

import { PasswordRegex } from '../../utils/constraints';

import frame from '../../assets/images/reset-password-frame.svg';
import BackArrowIcon from '../../components/icon/BackArrowIcon';

const { Title, Text } = Typography;

const ResetPassword = () => {
    const navigate = useNavigate();
    const { id, token } = useParams();
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { auth } = useSelector(state => state);

    const onFinish = (values) => {
        dispatch(resetPassword({ password: values.password, id: id, token: token }));
    };
    
    useEffect(() => {
        if (auth.isSuccess) {
            form.resetFields();
            navigate('/user/authentication/sign-in');
            dispatch(setIsSuccess(false));
        }
    }, [auth.isSuccess]);

    return (
        <Fragment>
            <Container fluid>
                <Flex vertical className='vh-100' justify='center'>
                    <Card className='padding-2'>
                        <Row gutter={34}>
                            <Col xxl={12} xl={12} lg={0} md={0} sm={0} xs={0}>
                                <Image
                                    className='w-100 h-100'
                                    preview={false}
                                    src={frame}
                                />
                            </Col>
                            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                                <Flex vertical justify='center' className='h-100'>
                                    <Title level={3} className='margin-0'>Reset Password</Title>
                                    <Text type='secondary'>Enter Your New Password for your account.</Text>
                                    <Form
                                        name='basic'
                                        form={form}
                                        labelCol={{ span: 24, }}
                                        wrapperCol={{ span: 24, }}
                                        onFinish={onFinish}
                                        autoComplete='off'
                                        className='paddingY-3'
                                    >
                                        <Form.Item
                                            name='password'
                                            label='Password'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Password required!',
                                                },
                                                {
                                                    pattern: new RegExp(PasswordRegex),
                                                    message: 'Password must include at least one number, one special character, and one uppercase letter!'
                                                }
                                            ]}
                                            hasFeedback
                                        >
                                            <Input.Password placeholder='Password' />
                                        </Form.Item>

                                        <Form.Item
                                            name='confirmPassword'
                                            label='Re-enter Password'
                                            dependencies={['password']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: ' Confirmpassword required!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Your password and confirmation password do not match!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password placeholder='Confirm Password' />
                                        </Form.Item>


                                        <Form.Item
                                            name='remember'
                                            valuePropName='checked'
                                        >
                                            <Checkbox>Remember Me</Checkbox>
                                        </Form.Item>

                                        <Button type='primary' htmlType='submit' block loading={auth?.loading}>
                                            Set Password
                                        </Button>

                                        <Link to={'/user/authentication/sign-in'}>
                                            <Flex gap={10} align='center' className='paddingY-2'>
                                                <BackArrowIcon color='#a20417' role='button' />
                                                <Text type='danger' strong>Back To Login In</Text>
                                            </Flex>
                                        </Link>
                                    </Form>
                                </Flex>
                            </Col>
                        </Row>
                    </Card>
                </Flex>
            </Container>
        </Fragment>
    );
};

export default ResetPassword;
