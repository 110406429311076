import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    lagarsList: [],
    lagar: {},
    lagarCityOptions: [],
    totalRecords: 0,
    loading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
};

export const slice = createSlice({
    name: 'lagar',
    initialState,
    reducers: {
        getLagarList: (state, action) => {
            state.lagar = {};
            state.isUpdating = false;
            state.lagarsList = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.lagar = {};
            state.isSuccess = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        getLagar: (state, action) => {
            state.lagar = action.payload;
        },
        getLagarCityOptions: (state, action) => {
            state.lagarCityOptions = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
    },
});

export const getLagarListData = createAsyncThunk('getLagarListData', (filterData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/lagar/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getLagarList(response.data.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            dispatch(getLagarList([]));
            dispatch(setTotalrecords(0))
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createLagar = createAsyncThunk('createlagar', (lagarData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/lagar/insert`, lagarData?.lagarData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getLagarListData({ search: '', sortBy: '', order: '', page: 1, limit: 10, filters: { city: [] }, organizer: lagarData?.organizer }))
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdLagar = createAsyncThunk('getByIdLagar', (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/lagar?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getLagar(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const updateLagar = createAsyncThunk('updatelagar', (lagarData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/lagar/update`, lagarData?.lagarData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getLagarListData({ search: '', sortBy: '', order: '', page: 1, limit: 10, filters: { city: [] }, organizer: lagarData?.organizer }))
            dispatch(setIsSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteLagar = createAsyncThunk('deletelagar', (object, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/lagar/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getLagarListData(object));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getLagar, setIsUpdating, setIsSuccess, getLagarList, setTotalrecords, getLagarCityOptions } = slice.actions;

export default slice.reducer;
