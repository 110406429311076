import React, { Fragment } from 'react';

import { Button, Col, Divider, Flex, Image, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import Container from '../../components/container/Container';

import logo from '../../assets/logo/logo.svg';

const { Title, Text } = Typography;

const WelcomePage = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <Container className='vh-100' justify='center' align='center'>
                <Row justify={'center'} align={'middle'} className='vh-100'>
                    <Col xxl={6} xl={8} lg={12} md={16} sm={16} xs={24} className='bg-white padding-2 rounded-2'>
                        <Flex justify='space-between' align='center'>
                            <Title level={4} type='danger' className='paddingY-1'>BFD GmbH</Title>
                            <Image preview={false} src={logo} />
                        </Flex>
                        <Divider />
                        <Flex vertical gap={16}>
                            <Title level={5}>Welcome To BFD GmbH</Title>
                            <Text>
                                Your Account has been successfully created.Lorem ipsum dolor sit amet consectetur. Feugiat rutrum commodo lorem convallis sagittis erat. Egestas massa egestas tempor volutpat. Sagittis diam gravida aliquam sapien. Ut pharetra cursus dignissim morbi mauris.
                            </Text>
                        </Flex>
                        <Divider />
                        <Button type='primary' onClick={() => navigate('/user/authentication/sign-in')}>Start Now</Button>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default WelcomePage;
