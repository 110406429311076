import React, { Fragment, useEffect, useState } from 'react'

import { Button, Col, Divider, Flex, Form, Input, Row, Select, Tag, Typography, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createStammDocument, deleteDocument, getStammdatenDocumentData, getStammDocument, getStammProjectListing, setDocumentsData, setIsDocumentSuccess } from './redux/slice';

import DataTable from '../../components/data-table';
import CommonPopconfirm from '../../components/custom-popconfirm';

import UploadIcon from '../../components/icon/UploadIcon';
import DocumentIcon from '../../components/icon/DocumentIcon';
import CloseIcon from '../../components/icon/CloseIcon';

import { lightPrimary, lightSecondary } from '../../utils/theme/config';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

const Documents = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { stammdaten } = useSelector(state => state)

    const [form] = Form.useForm();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [documentLists, setDocumentList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [document, setDocument] = useState({});
    const [modalType, setModalType] = useState(false);
    const [documentType, setDocumentType] = useState('')
    const [projectListing, setProjectListing] = useState([])

    // Get Stammdaten Document Data
    useEffect(() => {
        getStammDocumentListData()
    }, [pageSize, page, sortBy, order])

    const getStammDocumentListData = () => {
        if (stammdaten?.getEditStammUserId) {
            dispatch(getStammdatenDocumentData({
                sortBy: sortBy,
                order: order,
                search: '',
                page: page,
                limit: pageSize,
                stammUser: stammdaten?.getEditStammUserId
            }))
            dispatch(getStammProjectListing(stammdaten?.getEditStammUserId))
        }
    }

    useEffect(() => {
        setDocumentList(stammdaten?.stammdatenDocumentLists)
        setTotalRecords(stammdaten?.documentTotalRecords)
        setProjectListing(stammdaten?.stammProjectListing)
    }, [stammdaten?.stammdatenDocumentLists, stammdaten?.documentTotalRecords, stammdaten?.stammProjectListing])

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    const columns = [
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'createdAt'),
            render: (_, record) => dayjs(record?.createdAt).format("DD-MM-YYYY"),
        },
        {
            title: 'Document Name',
            dataIndex: 'name',
            key: 'documentName',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'name'),
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Document Type',
            dataIndex: 'type',
            key: 'documentType',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'type'),
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Project Name',
            dataIndex: 'project',
            key: 'project',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'project'),
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Uploaded File',
            dataIndex: 'file',
            key: 'file',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'file'),
            render: (_, record) => {
                return <Flex align='center' gap={3}>
                    <DocumentIcon />
                    <Text>{record?.name}</Text>
                </Flex>
            },
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'status',
            width: '100px',
            sorter: (_, __, order) => handleSortFunction(order, 'status'),
            render: (_, record) => {
                return (
                    <Fragment>
                        {

                            record?.isActive ?
                                <Tag color={lightPrimary} className='text-center'>Active</Tag> :
                                <Tag color={lightSecondary} className='text-center'>Inactive</Tag>
                        }
                    </Fragment>
                );
            }
        },
        {
            title: <Flex justify='center'><Text>Action</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => (
                <Fragment>
                    <Flex gap={8} justify='center' align='center'>
                        <a href={`${process.env.REACT_APP_API_URI}/documents/${record?.document}`} download target='_blank'>
                            <UploadIcon size={24} style={{
                                transform: 'rotate(180deg)',
                            }}
                                role='button'
                            />
                        </a>
                        {
                            record?.isActive ?
                                <CommonPopconfirm
                                    title='Deactivate the Document'
                                    description='Are you sure you want to deactivate this document?'
                                    icon={null}
                                    onConfirm={() => dispatch(deleteDocument({
                                        id: record?._id,
                                        sortBy: '',
                                        order: '',
                                        search: '',
                                        page: page,
                                        limit: pageSize,
                                        stammUser: stammdaten?.getEditStammUserId
                                    }))}
                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                >
                                    <Button type='text' className='padding-1'><CloseIcon role='button' /></Button>
                                </CommonPopconfirm>
                                :
                                <CommonPopconfirm
                                    title='Activate the Document'
                                    description='Are you sure you want to activate this document?'
                                    icon={null}
                                    onConfirm={() => dispatch(deleteDocument({
                                        id: record?._id,
                                        sortBy: '',
                                        order: '',
                                        search: '',
                                        page: page,
                                        limit: pageSize,
                                        stammUser: stammdaten?.getEditStammUserId
                                    }))}
                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                >
                                    <Button type='text' className='padding-1'><CloseIcon role='button' /></Button>
                                </CommonPopconfirm>

                        }
                    </Flex>
                </Fragment>
            ),
        },
    ];

    const onFinish = (values) => {
        if (fileList?.length < 0) {
            form.setFields([
                {
                    name: 'files',
                    errors: ['Please upload a file!'], // Clear any previous errors
                },
            ]);
        }
        dispatch(getStammDocument({
            ...values,
            stammUser: stammdaten?.getEditStammUserId
        }));
        setModalType(true);
    };

    useEffect(() => {
        if (Object.keys(stammdaten?.stammDocument).length > 0) {
            if (modalType) {
                const formData = new FormData();
                Object.keys(document)?.length > 0 && formData.append('document', document)
                Object.entries(stammdaten.stammDocument).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                dispatch(createStammDocument({ stammUserData: formData, organizerId: stammdaten?.getEditStammUserId }));
            }
        }
        if (stammdaten.isDocumentSuccess) {
            form.resetFields();
            setFileList([]);
            setDocument({});
            setModalType(false);
            dispatch(setIsDocumentSuccess(false));
        }
    }, [stammdaten.stammDocument, stammdaten.isDocumentSuccess, modalType])

    const handleDocumentTypeChange = (value) => {
        setDocumentType(value)
    };

    // const onUploadChange = (info) => {
    //     setFileList(info?.file?.originFileObj)
    // };
    const onUploadChange = ({ file, fileList }) => {

        // Keep only the last uploaded file in the list
        if (fileList.length > 1) {
            fileList.splice(0, fileList.length - 1);
        }

        // Update the file list state
        setFileList(fileList);
        setDocument(file?.originFileObj)

        // Manually trigger validation to clear the error if the file is valid
        form.setFields([
            {
                name: 'files',
                errors: [], // Clear any previous errors
            },
        ]);
    };

    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const onFormValuesChange = (_, allValues) => {
        dispatch(setDocumentsData(allValues));
    };

    return (
        <Fragment>
            <Title level={4} type='success' className='paddingY-1' >Upload Document</Title>
            <Divider />

            <Form
                form={form}
                name='user'
                layout='vertical'
                className='padding-top-2'
                onFinish={onFinish}
                requiredMark={(label, isRule) => {
                    return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                }}
                onValuesChange={onFormValuesChange}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        <Row gutter={28} justify={'space-between'}>
                            <Col xxl={11} xl={11} md={12} lg={12} sm={24} xs={24}>
                                <Form.Item
                                    name='name'
                                    label='Document Name'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter document name!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Please Enter Document Name'
                                    />
                                </Form.Item>

                                <Form.Item
                                    name='type'
                                    label='Document Type'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select document sub type!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder='Please select'
                                        onChange={handleDocumentTypeChange}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={[
                                            { value: 'general', label: 'General' },
                                            { value: 'project', label: 'Project' },
                                        ]}
                                    />
                                </Form.Item>

                                {
                                    documentType === 'project'
                                    && (
                                        <Form.Item
                                            name='project'
                                            label='Project'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select project!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder='Please select'
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={projectListing}
                                            />
                                        </Form.Item>
                                    )
                                }
                            </Col>
                            <Col xxl={12} xl={12} md={12} lg={12} sm={24} xs={24}>
                                <Form.Item
                                    name='files'
                                    rules={[{ required: fileList?.length <= 0, message: 'Please upload a file!' }]}
                                    valuePropName='files'
                                    getValueFromEvent={(e) => e && e.fileList}
                                >
                                    <Upload.Dragger multiple={false} name='files' fileList={fileList} onChange={onUploadChange}  >
                                        <p className='ant-upload-drag-icon'>
                                            <UploadIcon size={38} />
                                        </p>
                                        <Text className='ant-upload-text' strong>Upload File Here</Text>
                                    </Upload.Dragger>

                                    <Flex vertical gap={12} className='paddingY-1'>
                                        <Text type='secondary'>Allowed Extensions are PDF, JPG, PNG, GIF, DOC, DOCX, XLS, XLSX, PPT, PPTX, ZIP, RAR, MP4, Wmv, MSG.</Text>

                                        <Text type='secondary'>
                                            Max File size is 05 MB.
                                        </Text>
                                    </Flex>
                                </Form.Item>

                                <Flex justify='flex-end' gap={10} wrap className='paddingY-2'>
                                    <Button danger type='primary' htmlType='button' onClick={() => navigate(-1)}>Cancel</Button>
                                    <Button type='primary' htmlType='submit'>Submit</Button>
                                </Flex>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <Flex className='padding-top-3'>
                                    <DataTable
                                        columns={columns}
                                        pageSize={pageSize}
                                        currentPage={page}
                                        total={totalRecords}
                                        scrollX={900}
                                        showSorterTooltip={false}
                                        isPagination={true}
                                        handleOnChange={handleOnChange}
                                        data={documentLists}
                                        rowKey={(record) => record?._id}
                                    />
                                </Flex>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )
};

export default Documents;
