import React from 'react';

const EyeIcon = ({
    size = 24,
    color = '#A20417',
    role = 'default',
    style = {},
    ...Rest
}) => {

    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={styles}
            {...Rest}
        >
            <path
                d='M11.6849 8.99945C11.6849 10.4845 10.4849 11.6845 8.99994 11.6845C7.51494 11.6845 6.31494 10.4845 6.31494 8.99945C6.31494 7.51445 7.51494 6.31445 8.99994 6.31445C10.4849 6.31445 11.6849 7.51445 11.6849 8.99945Z'
                stroke={color}
                strokeWidth={1.125}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.99988 15.2035C11.6474 15.2035 14.1149 13.6435 15.8324 10.9435C16.5074 9.88602 16.5074 8.10852 15.8324 7.05102C14.1149 4.35102 11.6474 2.79102 8.99988 2.79102C6.35238 2.79102 3.88488 4.35102 2.16738 7.05102C1.49238 8.10852 1.49238 9.88602 2.16738 10.9435C3.88488 13.6435 6.35238 15.2035 8.99988 15.2035Z'
                stroke={color}
                strokeWidth={1.125}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default EyeIcon;
