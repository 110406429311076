import React, { Fragment, useEffect, useState } from 'react';

import { Button, Divider, Flex, Typography, Row, Col, Form, Input, Select, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { createWareHouse, getByIdWareHouse, getWareHouse, setIsSuccess, setIsUpdating, updateWareHouse } from './redux/slice';
import { getCityListing, getLagarListing } from '../../redux/slice/global';

import BackIcon from '../../components/icon/BackIcon';
import PlusIcon from '../../components/icon/PlusIcon';
import EditIcon from '../../components/icon/EditIcon';
import CloseIcon from '../../components/icon/CloseIcon';

import { black } from '../../utils/theme/config';

const { Title, Text } = Typography;

const AddUdateWarehouse = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { wareHouse, auth, listing } = useSelector(state => state);
    const [isRackUpdate, setIsRackUpdate] = useState(false);
    const [modalType, setModalType] = useState(false);
    const [loginUser, setLoginUser] = useState({});
    const [lagarOptions, setLagarOptions] = useState([]);
    const [reckNumber, setReckNumber] = useState('')
    const [self, setSelf] = useState('')
    const [selfNumbers, setSelfNumbers] = useState([])
    const [editSelfIndex, setEditSelfIndex] = useState(null)
    const [cityListing, setCityListing] = useState([])

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
        }

        dispatch(getCityListing())
    }, [])

    const handleOnFinish = (values) => {
        delete values?.self;
        if (id) {
            dispatch(setIsUpdating(false));
        }
        dispatch(getWareHouse({ ...values, rackSelfNumber: selfNumbers, organizer: auth?.decodeToken?.organizer }));
    };

    // Get Lagar Lists
    useEffect(() => {
        if (loginUser?.organizer) {
            dispatch(getLagarListing(loginUser?.organizer));
        }
    }, [loginUser])

    useEffect(() => {
        if (listing.listing?.lagar?.length > 0) {
            setLagarOptions(listing.listing?.lagar);
        }
        setCityListing(listing?.listing?.city)
    }, [listing.listing?.lagar, listing?.listing?.city, loginUser])

    // Get Edit WareHouse Data
    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(setIsUpdating(true));
            dispatch(getByIdWareHouse(id));
        }
    }, [id]);

    // Create & Update Warehouse
    useEffect(() => {
        if (Object.keys(wareHouse.wareHouse).length > 0) {
            if (!modalType) {
                dispatch(createWareHouse({ wareHouse: wareHouse.wareHouse, organizer: auth?.decodeToken?.organizer }));
            } else {
                if (wareHouse.isUpdating) {
                    setSelfNumbers(wareHouse?.wareHouse?.rackSelfNumber)
                    setReckNumber(wareHouse?.wareHouse?.rackNumber)
                    form.setFieldsValue({ ...wareHouse.wareHouse, self: '' });
                } else {
                    dispatch(updateWareHouse({ wareHouse: { ...wareHouse?.wareHouse, id: id }, organizer: auth?.decodeToken?.organizer }));
                }
            }
        }
        if (wareHouse.isSuccess) {
            handleClear();
            setSelf('')
            setReckNumber('')
            setSelfNumbers([])
            setEditSelfIndex(null)
            setModalType(false);
            navigate('/warehouse-management/wh-place');
            dispatch(setIsSuccess(false));
        }
    }, [wareHouse.isUpdating, wareHouse.wareHouse, wareHouse.isSuccess]);

    const handleClear = () => {
        navigate(-1);
        form.resetFields();
    }

    // Toggle Add & Update Self number 
    const handleEditRackNumber = (item, index) => {
        setIsRackUpdate(true);
        setEditSelfIndex(index)
        setSelf(item)
        form.setFieldsValue({
            'self': item,
        });
    }

    const handleAddSelfNumber = () => {
        if (self === '') {
            return;
        }
        else if (self !== '' && selfNumbers?.find((item, index) => item === self && index !== editSelfIndex)) {
            form.setFields([
                {
                    name: 'self',
                    errors: ['Self number already exist!'],
                },
            ])
        } else {
            if (selfNumbers?.find((item, index) => index === editSelfIndex)) {
                setSelfNumbers(selfNumbers?.map((i, key) => {
                    return key === editSelfIndex ? self : i
                }))
            } else {
                setSelfNumbers([...selfNumbers, self]);
            }
            setSelf('');
            setEditSelfIndex(null)
            setIsRackUpdate(false);
            form.resetFields(['self']);
        }
    }

    return (
        <Fragment>
            <Flex vertical gap={24}>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => navigate(-1)}
                    />
                </Flex>
                <Flex vertical gap={8} className='bg-white padding-2'>
                    <Flex>
                        <Title level={4} type='success'>{id ? 'Edit Warehouse Place' : 'Add New Warehouse Place '}</Title>
                    </Flex>
                    <Divider />
                    <Form
                        form={form}
                        name='warehouse'
                        colon={true}
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        layout='vertical'
                        onFinish={handleOnFinish}
                    >
                        <Row gutter={18}>
                            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='city'
                                    label='City'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select city!',
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={isRackUpdate}
                                        showSearch
                                        allowClear
                                        placeholder='City'
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={cityListing}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='lagar'
                                    label='Lagar name'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select lagar name!',
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={isRackUpdate}
                                        showSearch
                                        allowClear
                                        placeholder='Lagar name'
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={lagarOptions}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='rackNumber'
                                    label='Rack number'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Rack number is required!',
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            setReckNumber(e.target.value)
                                        }}
                                        disabled={isRackUpdate}
                                        placeholder='Rack number'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='self'
                                    label='Self Number'
                                >
                                    <Input
                                        onChange={(e) => {
                                            setSelf(e.target.value);
                                        }}
                                        placeholder='Self Number'
                                    />
                                </Form.Item>
                            </Col>
                            {
                                isRackUpdate ?
                                    <Col xxl={4} xl={5} lg={24} md={24} sm={24} xs={24} >
                                        <Flex align='center' justify='end' gap={10} wrap={true} className='h-100'>
                                            <Button danger type='primary' htmlType='button' onClick={() => {
                                                setIsRackUpdate(false)
                                                setSelf('');
                                                setEditSelfIndex(null)
                                                setIsRackUpdate(false);
                                                form.resetFields(['self']);
                                            }}>
                                                Cancel
                                            </Button>
                                            <Button type='primary' loading={wareHouse?.loading} onClick={() => handleAddSelfNumber()}>Update</Button>
                                        </Flex>
                                    </Col>
                                    :
                                    <Col xxl={1} xl={1} lg={24} md={24} sm={24} xs={24} >
                                        <Flex justify='end' align='center' className='h-100'>
                                            <PlusIcon
                                                role='button'
                                                color={black}
                                                size={24}
                                                onClick={() => handleAddSelfNumber()}
                                            />
                                        </Flex>
                                    </Col>
                            }
                        </Row>
                        <Row gutter={[24, 24]} className='padding-top-2'>
                            {
                                selfNumbers?.map((item, index) => {
                                    return <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} key={item}>
                                        <Card
                                            title={null}
                                            extra={<Flex>
                                                <Button
                                                    type='text'
                                                    className='padding-1'
                                                    onClick={() => handleEditRackNumber(item, index)}
                                                >
                                                    <EditIcon role='button' />
                                                </Button>
                                                <Button
                                                    type='text'
                                                    className='padding-1'
                                                    onClick={() => { setSelfNumbers(selfNumbers?.filter((_, i) => i !== index)); form.resetFields(['self']) }}
                                                ><CloseIcon role='button' color={black} /></Button>
                                            </Flex>
                                            }
                                            className='bg-primary-light '
                                        >
                                            <Flex justify='center' align='center' className='padding-bottom-3'>
                                                <Title level={4} className='padding-bottom-3'>{reckNumber} - {item}</Title>
                                            </Flex>
                                        </Card>
                                    </Col>
                                })
                            }
                        </Row>
                        <Flex justify='flex-end' gap={10} wrap={true} className='padding-top-3'>
                            <Button danger type='primary' htmlType='button' onClick={handleClear}>Cancel</Button>
                            <Button type='primary' htmlType='submit' loading={wareHouse?.loading}>Submit</Button>
                        </Flex>
                    </Form>
                </Flex>
            </Flex >
        </Fragment >
    );
};

export default AddUdateWarehouse;
