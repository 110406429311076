import * as React from 'react';

const ExpandIcon = ({
    size = 24,
    color = '#007144',
    role = 'default',
    style = {},
    ...Rest
}) => {

    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 26 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={styles}
            {...Rest}
        >
            <path
                d='M13.0004 23.8332C18.9836 23.8332 23.8339 18.9829 23.8339 12.9997C23.8339 7.01659 18.9836 2.16629 13.0004 2.16629C7.01729 2.16629 2.16699 7.01659 2.16699 12.9997C2.16699 18.9829 7.01729 23.8332 13.0004 23.8332Z'
                stroke={color}
                strokeWidth={1.62502}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.17578 11.6348L13 15.4482L16.8242 11.6348'
                stroke={color}
                strokeWidth={1.62502}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default ExpandIcon;
