import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    cityLists: [],
    city: {},
    totalRecords: 0,
    loading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
};

export const slice = createSlice({
    name: 'city',
    initialState,
    reducers: {
        getCityLists: (state, action) => {
            state.city = {};
            state.isUpdating = false;
            state.cityLists = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.city = {};
            state.isSuccess = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        getCity: (state, action) => {
            state.city = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
    },
});

export const getCityListsData = createAsyncThunk('getCityListsData', (filterData, { dispatch }) => {
    axios.post(`/city/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getCityLists(response.data.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createCity = createAsyncThunk('createCity', (CityData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/city/insert`, CityData?.apiData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getCityListsData({ page: 1, limit: 10, sortBy: '', order: '', search: '' }))
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdCity = createAsyncThunk('getByIdCity', (id, { dispatch }) => {
    axios.get(`/city/?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getCity(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const updateCity = createAsyncThunk('updateCity', (CityData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/city/update`, CityData.apiData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getCityListsData({ page: 1, limit: 10, sortBy: '', order: '', search: '' }))
            dispatch(setIsSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getCity, setIsUpdating, setIsSuccess, getCityLists, setTotalrecords } = slice.actions;

export default slice.reducer;
