export const NumberRegex = /^\d{10}$/;
export const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
export const PasswordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}:'<>?])[A-Za-z\d!@#$%^&*()_+{}:'<>?]{8,}$/;

export const menuRoute = [
    {
        "menu": "Dashboard",
        "permissions": {
            "hasAccess": true,
            "canCreate": true,
            "canUpdate": true,
            "canDelete": true
        },
    },
    {
        "menu": "User Management",
        "permissions": {
            "hasAccess": true,
            "canCreate": true,
            "canUpdate": true,
            "canDelete": true
        },
        "children": [
            {
                "menu": "Users",
                "permissions": {
                    "hasAccess": true,
                    "canCreate": true,
                    "canUpdate": true,
                    "canDelete": true
                }
            },
            {
                "menu": "User Types",
                "permissions": {
                    "hasAccess": true,
                    "canCreate": true,
                    "canUpdate": true,
                    "canDelete": true
                }
            },
            {
                "menu": "Designation",
                "permissions": {
                    "hasAccess": true,
                    "canCreate": true,
                    "canUpdate": true,
                    "canDelete": true
                },
            },
        ]
    },
    {
        "menu": "Client",
        "permissions": {
            "hasAccess": true,
            "canCreate": true,
            "canUpdate": true,
            "canDelete": true
        },
    },
    {
        "menu": "Packages",
        "permissions": {
            "hasAccess": true,
            "canCreate": true,
            "canUpdate": true,
            "canDelete": true
        },
    },
    {
        "menu": "Time Tracking Mgmt.",
        "permissions": {
            "hasAccess": true,
            "canCreate": true,
            "canUpdate": true,
            "canDelete": true
        },
    },
    {
        "menu": "stammdaten",
        "permissions": {
            "hasAccess": true,
            "canCreate": true,
            "canUpdate": true,
            "canDelete": true
        },
        "children": [
            {
                "menu": "Stamm User",
                "permissions": {
                    "hasAccess": true,
                    "canCreate": true,
                    "canUpdate": true,
                    "canDelete": true
                }
            }
        ]
    },
    {
        "menu": "Profile Settings",
        "permissions": {
            "hasAccess": true,
            "canCreate": true,
            "canUpdate": true,
            "canDelete": true
        },
    }
]