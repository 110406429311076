import React, { Fragment, useEffect, useState } from 'react';

import {
    Route,
    Routes,
    Navigate,
    Outlet,
    useLocation,
    Link
} from 'react-router-dom';

import { privateRoutes, publicRoutes } from '../navigations/navigations';

import Wrapper from '../layout/Wrapper';
import NotFound from '../pages/404';

import { useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { menuRoute } from '../utils/constraints';
import Designation from '../pages/designation/Designation';
import Unauthorized from '../pages/401';
import InternalServerError from '../pages/500';

const RequireAuth = () => {
    const location = useLocation();
    const { auth } = useSelector(state => state);
    const decodedCookie = decodeURIComponent(document.cookie);
    const getToken = decodedCookie.split('=')[1];

    if (!getToken) {
        return <Navigate to='/user/authentication/sign-in' replace />;
    }

    if (getToken && (location.pathname === '/user/authentication/sign-in' || location.pathname === '/')) {
        return <Navigate to='/dashboard' replace />;
    }

    if ((getToken && auth?.isAuthorize) && location.pathname !== '/profile-settings') {
        return <Navigate to='/profile-settings' replace />;
    }
    return <Outlet />;
};

const CheckIsLoggedIn = ({ element }) => {
    const location = useLocation();

    const decodedCookie = decodeURIComponent(document.cookie);
    const getToken = decodedCookie.split('=')[1];

    if (!getToken) {
        return element;
    }
    if (getToken && (publicRoutes.filter((e) => e.to.includes(location.pathname.split('/')?.[1])) || location.pathname === '/')) {
        return <Navigate to='/dashboard' replace />;
    }
    return <Outlet />;
};

const AppRouter = () => {
    const { user, auth } = useSelector(state => state)
    const [accessibleRoutes, setAccessibleRoutes] = useState([]);


    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        const user = token && jwtDecode(token);
        user?.organizer && user?.permissions[0]?.menu?.push({
            menu: "Profile Settings",
            permissions: {
                hasAccess: true,
                canCreate: true,
                canUpdate: true,
                canDelete: true
            },
            children: []
        })


        const filterRoutes = (routes, permissions) => {

            return routes?.map(route => {
                const matchingPermission = permissions?.find(permission => permission.menu === route.menu && permission?.permissions?.hasAccess);

                if (matchingPermission) {
                    if (route.children && route.children.length > 0) {
                        const filteredChildren = route.children?.filter(child =>
                            matchingPermission.children &&
                            matchingPermission.children?.some(permissionChild => {
                                return permissionChild.menu === child.menu
                            }
                            )
                        );
                        (user?.organizer && route?.menu === 'User Management') && filteredChildren?.push({
                            to: 'designation',
                            label: <Link to='/user/designation'>Designation</Link>,
                            menu: 'Designation',
                            key: '23',
                            element: <Designation />,
                        })

                        return {
                            ...route,
                            children: filteredChildren
                        };
                    }
                    return route;
                }
                return null;
            }).filter(route => route !== null);
        };
        const allRoutes = filterRoutes(privateRoutes, user?.permissions[0]?.menu);
        setAccessibleRoutes(allRoutes);

    }, [user, privateRoutes, auth]);

    return (
        <Routes>
            <Route
                path='*'
                element={
                    (
                        <NotFound />
                    )
                }
            />
            <Route
                path='/unauthorized'
                element={
                    (
                        <Unauthorized />
                    )
                }
            />
            <Route
                path='/internal-server'
                element={
                    (
                        <InternalServerError />
                    )
                }
            />
            {
                publicRoutes.map((routes) => {
                    return (
                        <Route
                            key={routes.to}
                            element={<CheckIsLoggedIn element={routes.element} />}
                            path={routes.to}
                        />
                    );
                })
            }
            <Route element={<RequireAuth />} path='/'>
                {
                    accessibleRoutes?.map((route) => {
                        return (
                            <Fragment key={route.to}>
                                {
                                    route.children?.length <= 0 &&
                                    <Route
                                        key={route.to}
                                        element={<Wrapper>{route.element}</Wrapper>}
                                        path={route.to}
                                    />
                                }
                                {
                                    route.children?.length > 0 &&
                                    <Route
                                        key={route.to}
                                        path={route.to}
                                    >
                                        {
                                            route.children?.map((children) => {
                                                return (
                                                    <Route
                                                        key={children.to}
                                                        element={<Wrapper>{children.element}</Wrapper>}
                                                        path={children.to}
                                                    />
                                                )

                                            })
                                        }
                                    </Route>
                                }
                            </Fragment>
                        );
                    })
                }
            </Route>
        </Routes>
    );
};

export default AppRouter;
