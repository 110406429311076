import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    timeTrackingsList: [],
    timeTracking: {},
    workHistory: [],
    totalRecords: 0,
    workHistoryTotalRecords: 0,
    loading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
};

export const slice = createSlice({
    name: 'timeTracking',
    initialState,
    reducers: {
        getTimeTrackingsList: (state, action) => {
            state.timeTracking = {};
            state.workHistory = [];
            state.isUpdating = false;
            state.timeTrackingsList = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.timeTracking = {};
            state.isSuccess = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        setWorkHistoryTotalrecords: (state, action) => {
            state.workHistoryTotalRecords = action.payload;
        },
        getTimeTrackings: (state, action) => {
            state.timeTracking = action.payload;
        },
        getWorkHistory: (state, action) => {
            state.workHistory = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        }
    },
});

export const getTimeTrackingsListData = createAsyncThunk('getTimeTrackingsListData', (filterData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/timetracking/get-by-day?organizer=${filterData?.organizer}&date=${filterData?.date}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getTimeTrackingsList(response?.data?.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            dispatch(getTimeTrackingsList([]))
            dispatch(setTotalrecords(0));
            // openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getWorkHistoryListsData = createAsyncThunk('getTimeTrackingsListData', (filterData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/timetracking/get-by-user?user=${filterData?.user}&organizer=${filterData?.organizer}&month=${filterData?.month}&year=${filterData?.year}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getWorkHistory(response.data.records));
            dispatch(setWorkHistoryTotalrecords(response.data.totalHours))
        } else {
            dispatch(getWorkHistory([]));
            dispatch(setWorkHistoryTotalrecords(0))
            // openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createTimeTracking = createAsyncThunk('createTimeTracking', (TimeTrackingData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/timetracking/insert`, TimeTrackingData?.timeTracking).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getTimeTrackingsListData({ organizer: TimeTrackingData?.organizer, date: TimeTrackingData?.date }))
            dispatch(setIsSuccess(true))
            openNotification({ message: response.data.message, type: 'success' });
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdTimeTracking = createAsyncThunk('getByIdTimeTracking', (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/timetracking/get?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getTimeTrackings(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const updateTimeTracking = createAsyncThunk('updateTimeTracking', (TimeTrackingData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/timetracking/update`, TimeTrackingData?.timeTracking).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getTimeTrackingsListData({ organizer: TimeTrackingData?.organizer, date: TimeTrackingData?.date }))
            dispatch(setIsSuccess(true))
            openNotification({ message: response.data.message, type: 'success' });
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteTimeTracking = createAsyncThunk('deleteTimeTracking', (object, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/timetracking/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            object?.user ? dispatch(getWorkHistoryListsData(object)) :
                dispatch(getTimeTrackingsListData(object))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getTimeTracking, setIsUpdating, getWorkHistory, setWorkHistoryTotalrecords, setIsSuccess, getTimeTrackings, getTimeTrackingsList, setTotalrecords } = slice.actions;

export default slice.reducer;
