import React, { Fragment } from 'react';

import { Col, Flex, Row, Card, Image, Typography } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { resendOTP } from './redux/slice';

import Container from '../../components/container/Container';

import frame from '../../assets/images/check-mail-frame.svg';
import BackArrowIcon from '../../components/icon/BackArrowIcon';

const { Title, Text } = Typography;

const CheckMail = () => {
    const location = useLocation();

    const dispatch = useDispatch();

    return (
        <Fragment>
            <Container fluid>
                <Flex vertical className='vh-100' justify='center'>
                    <Card className='padding-2'>
                        <Row>
                            <Col xxl={12} xl={12} lg={0} md={0} sm={0} xs={0}>
                                <Image
                                    className='w-100 h-100'
                                    preview={false}
                                    src={frame}
                                />
                            </Col>
                            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                                <Flex align='center' className='h-100 w-100'>
                                    <div>
                                        <Title level={2} className='paddingY-2 margin-0'>Check Your E-mail</Title>

                                        <Text type='secondary' strong>We sent a password reset link to</Text>
                                        <Title level={2} type='success' className='margin-0'>{location.state && location.state}</Title>

                                        <div className='paddingY-2'>
                                            <Text type='secondary' strong>
                                                Didn’t receive the email?
                                                <Text
                                                    type='danger'
                                                    className='cursor-pointer'
                                                    onClick={() => dispatch(resendOTP({ email: location.state && location.state }))}>
                                                    Click to resend
                                                </Text>
                                            </Text>
                                        </div>

                                        <Flex gap={20} align='center' wrap>
                                            <Link to={'/user/authentication/sign-in'}>
                                                <Flex gap={10} align='center'>
                                                    <BackArrowIcon color='#a20417' role='button' size={20} />
                                                    <Text type='danger' strong>Back To Login In</Text>
                                                </Flex>
                                            </Link>

                                            <Link to={'/user/authentication/forgot-password'}>
                                                <Flex gap={10} align='center'>
                                                    <BackArrowIcon color='#a20417' role='button' size={20} />
                                                    <Text type='danger' strong>Back To Forgot Password</Text>
                                                </Flex>
                                            </Link>
                                        </Flex>
                                    </div>
                                </Flex>
                            </Col>
                        </Row>
                    </Card>
                </Flex>
            </Container>
        </Fragment>
    );
};

export default CheckMail;
