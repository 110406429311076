import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    designationLists: [],
    designation: {},
    totalRecords: 0,
    loading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
};

export const slice = createSlice({
    name: 'designation',
    initialState,
    reducers: {
        getDesignationLists: (state, action) => {
            state.designation = {};
            state.isUpdating = false;
            state.designationLists = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.designation = {};
            state.isSuccess = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        getDesignation: (state, action) => {
            state.designation = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
    },
});

export const getDesignationListsData = createAsyncThunk('getDesignationListsData', (filterData, { dispatch }) => {
    axios.post(`/designation/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getDesignationLists(response.data.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createDesignation = createAsyncThunk('createDesignation', (designationData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/designation/insert`, designationData?.apiData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getDesignationListsData({ organizer: designationData?.organizerID, page: 1, limit: 10 }))
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdDesignation = createAsyncThunk('getByIdDesignation', (id, { dispatch }) => {
    axios.get(`/designation/?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getDesignation(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const updateDesignation = createAsyncThunk('updateDesignation', (designationData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/designation/update`, designationData.apiData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getDesignationListsData({ organizer: designationData?.organizerID, page: 1, limit: 10 }))
            dispatch(setIsSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteDesignation = createAsyncThunk('deleteDesignation', (object, { dispatch }) => {
    axios.post(`/designation/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getDesignationListsData(object));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getDesignation, setIsUpdating, setIsSuccess, getDesignationLists, setTotalrecords } = slice.actions;

export default slice.reducer;
