import React, { Fragment, useEffect, useState } from 'react';

import { Col, Flex, Input, Row, Card, Image, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { setIsSuccess, setOtp, setOtpVerifyToken, verifyOTP } from './redux/slice';

import Container from '../../components/container/Container';

import frame from '../../assets/images/verify-otp-frame.svg';
import BackArrowIcon from '../../components/icon/BackArrowIcon';

const { Title, Text } = Typography;

const VerifyOTP = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();
    const { auth } = useSelector(state => state);

    const [isOtp, setIsOtp] = useState(false);

    const onChange = (values) => {
        dispatch(setOtp({ otp: +values, id: id && id }));
        setIsOtp(true);
    };

    useEffect(() => {
        if (isOtp && Object.keys(auth.otpData).length > 0 && !auth.isSuccess) {
            dispatch(verifyOTP(auth.otpData));
        }
        if (auth.isSuccess) {
            navigate(`/user/authentication/reset-password/${id && id}/${auth?.OtpVerifyToken && auth?.OtpVerifyToken}`);
            dispatch(setIsSuccess(false));
            dispatch(setOtpVerifyToken(''));
            dispatch(setOtp({}));
            setIsOtp(false)
        }
    }, [auth.isSuccess, auth.otpData, isOtp, auth.OtpVerifyToken]);

    const sharedProps = {
        onChange,
    };

    return (
        <Fragment>
            <Container fluid>
                <Flex vertical className='vh-100' justify='center'>
                    <Card className='padding-2'>
                        <Row>
                            <Col xxl={12} xl={12} lg={0} md={0} sm={0} xs={0}>
                                <Image
                                    className='w-100 h-100'
                                    preview={false}
                                    src={frame}
                                />
                            </Col>
                            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                                <Flex align='center' className='h-100 w-100'>
                                    <div>
                                        <Title level={1} className='margin-0'>Verify Code</Title>
                                        <Text type='secondary' className='paddingY-1'>Enter your verification code that we sent you through your e-mail.</Text>

                                        <div className='paddingY-2'>
                                            <Input.OTP length={6}{...sharedProps} size='large' />
                                        </div>

                                        <Link to={'/user/authentication/forgot-password'}>
                                            <Flex gap={10} align='center' className='paddingY-2'>
                                                <BackArrowIcon color='#a20417' role='button' />
                                                <Text type='danger' >Back To Forgot Password</Text>
                                            </Flex>
                                        </Link>
                                    </div>
                                </Flex>
                            </Col>
                        </Row>
                    </Card>
                </Flex>
            </Container>
        </Fragment>
    );
};

export default VerifyOTP;
