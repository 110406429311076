import React, { Fragment } from 'react';

import { Button, Col, Flex, Form, Row, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getStammProject } from './redux/slice';

import GeneralInformation from './GeneralInformation';
import Project from './Project';
import Documents from './Documents';

import BackIcon from '../../components/icon/BackIcon';

const AddUpdateStammdaten = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { id } = useParams();

    return (
        <Fragment>
            <Flex vertical gap={24} className='change-tag'>
                <Button
                    type='text'
                    icon={<BackIcon role={'button'} />}
                    className='bg-white paddingX-3'
                    onClick={() => navigate('/stammdaten')}
                />

                <Row>
                    <Col xs={24} className='bg-white padding-2 rounded-2'>
                        <Tabs
                            type='card'
                            size='large'
                            onChange={() => { dispatch(getStammProject({})); form.resetFields() }}
                            defaultActiveKey='1'
                            items={[
                                {
                                    label: 'General Information',
                                    key: '1',
                                    children: <GeneralInformation />,
                                },
                                {
                                    label: 'Project',
                                    key: '2',
                                    disabled: !id,
                                    children: <Project form={form} />,
                                },
                                {
                                    label: 'Documents',
                                    key: '3',
                                    disabled: !id,
                                    children: <Documents />,
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Flex>
        </Fragment>
    )
};

export default AddUpdateStammdaten;
