import * as React from 'react';

const DeleteIcon = ({
    size = 24,
    color = '#A20417',
    role = 'default',
    style = {},
    ...Rest
}) => {

    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={styles}
            {...Rest}
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4 7.5H20L18.5 22H5.5L4 7.5Z'
                stroke={color}
                strokeLinejoin='round'
            />
            <path d='M10.001 12.5012V17.5013' stroke={color} strokeLinecap='round' />
            <path d='M14.001 12.4998V17.4986' stroke={color} strokeLinecap='round' />
            <path
                d='M6 7.49995L14.1621 1.5L18 7.5'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default DeleteIcon;
