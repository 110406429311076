import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../axios/interceptor';
import openNotification from '../../components/notification';

const initialState = {
    listing: {
        userType: [],
        userTypeByID: [],
        menu: [],
        packages: [],
        menuForPackage: [],
        designation: [],
        lagar: [],
        organizer: [],
        city: [],
    },
    language: "en",
    hasMenuPermission: {},
    loading: false,
    error: null,
};

export const slice = createSlice({
    name: 'listing',
    initialState,
    reducers: {
        getList: (state, action) => {
            const { key, value } = action.payload
            state.error = null;
            state.isUpdating = false;
            state.listing[key] = value;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        setMenuPermission: (state, action) => {
            state.hasMenuPermission = action.payload;
        },
    },
});

export const getUserTypeListing = createAsyncThunk('getUserTypeListing', (_, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`usertype/options?id=`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'userType', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getUserTypeByIDListing = createAsyncThunk('getUserTypeByIDListing', (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`usertype/options?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'userTypeByID', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getDesignationListing = createAsyncThunk('getDesignationListing', (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`designation/options?organizer=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'designation', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getMenuListing = createAsyncThunk("getMenuListing", (_, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/menu/get`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'menu', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getMenuForPackageListing = createAsyncThunk("getMenuForPackageListing", (_, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/menu/options`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'menuForPackage', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getPackagesListing = createAsyncThunk("getPackagesListing", (_, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/package/options`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'packages', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getLagarListing = createAsyncThunk("getLagarListing", (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/lagar/options?organizer=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'lagar', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getOrganizerListing = createAsyncThunk("getOrganizerListing", (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/organizer/options?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'organizer', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getCityListing = createAsyncThunk("getCityListing", (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/city/options`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'city', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { getList, setError, setLoading, setIsUpdating, setLanguage,setMenuPermission } = slice.actions;

export default slice.reducer;
