import React, { Fragment, useEffect, useState } from 'react';

import { Button, Flex, Form, Row, Col, Input, Typography, Select, Divider, InputNumber } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';

import { createClient, getClient, setIsUpdating as clientIsUpdating, setIsSuccess as clientIsSuccess, updateClient, getByIdClient } from '../administrator/redux/slice';
import { getDesignationListing, getPackagesListing, getUserTypeByIDListing } from '../../redux/slice/global';
import { getDecodeToken } from '../auth/redux/slice';

import BackIcon from '../../components/icon/BackIcon';

import '../../styles/pages/profile-settings.scss';

const { Title, Text } = Typography;

const ClientAddUpdateUser = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { id } = useParams();
    const { user, listing, auth, client } = useSelector(state => state);
    const [loginUser, setLoginUser] = useState({});
    const [modalType, setModalType] = useState(false);
    const [userTypesByIDList, setUserTypesByIDList] = useState([]);
    const [designationListing, setDesignationListing] = useState([])

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        if (token) {
            const user = jwtDecode(token);
            dispatch(getDecodeToken(user))
            setLoginUser(user);
        }
    }, [])

    const getPackageLists = () => {
        dispatch(getPackagesListing());
    };

    useEffect(() => {
        if (listing.listing?.designation?.length > 0) {
            setDesignationListing(listing.listing?.designation)
        }
    }, [listing.listing.designation]);

    const handleOnFinish = (values) => {
        if (id) {
            dispatch(clientIsUpdating(false));
        }
        dispatch(getClient({
            ...values,
            isImmutable: false,
            organizer: id ? client?.client?.organizer : auth?.decodeToken?.organizer,
            package: id ? client?.client?.package : auth?.decodeToken?.package,
            userLimit: 0,
            address: { streetNo: values?.streetNo, city: values?.city, zip: values?.zip }
        }));
    }

    useEffect(() => {
        getPackageLists();
        if (loginUser?.organizer) {
            dispatch(getUserTypeByIDListing(loginUser?.organizer));
        }

        if (loginUser?.organizer) {
            dispatch(getDesignationListing(loginUser?.organizer))
        }
    }, [loginUser])

    useEffect(() => {
        if (listing.listing?.userTypeByID?.length > 0) {
            setUserTypesByIDList(listing.listing.userTypeByID);
        }
    }, [listing.listing.userTypeByID, loginUser])

    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(clientIsUpdating(true))
            dispatch(getByIdClient(id));
        }
    }, [id]);

    useEffect(() => {
        if (Object.keys(client.client).length > 0) {
            if (!modalType) {
                dispatch(createClient(client.client));
            } else {
                if (client.isUpdating) {
                    form.setFieldsValue({ ...client.client, streetNo: client?.client?.address?.streetNo, city: client?.client?.address?.city, zip: client?.client?.address?.zip });
                } else {
                    dispatch(updateClient({ ...client.client, id: id }));
                }
            }
        }
        if (client.isSuccess) {
            handleClear();
            navigate('/user');
            setModalType(false);
            dispatch(clientIsSuccess(false));
        }
    }, [client.isSuccess, client?.client, client.isUpdating]);

    const handleClear = () => {
        form.resetFields();
    };

    return (
        <Fragment>
            <Flex vertical gap={24} className='user-management general-settings'>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => navigate('/user')}
                    />
                </Flex>
                <Flex vertical gap={8} className='bg-white padding-2'>
                    <Flex>
                        <Title level={4} type='success'>{id ? 'Edit User' : 'Add User'}</Title>
                    </Flex>
                    <Divider />
                    <Form
                        form={form}
                        name='user'
                        colon={true}
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        layout='vertical'
                        onFinish={handleOnFinish}
                    >
                        <Fragment>
                            <Row gutter={16}>
                                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Row gutter={24}>
                                        {
                                            auth?.decodeToken?._id !== auth?.decodeToken?.organizer &&
                                            <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24} >
                                                <Form.Item
                                                    name='company'
                                                    label='Company Name'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Company is required!'
                                                        }
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Company'
                                                    />
                                                </Form.Item>
                                            </Col>
                                        }
                                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='firstName'
                                                label='First name'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Firstname is required!'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Firstname'
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='lastName'
                                                label='Last name'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Lastname is required!'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Lastname'
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='phone'
                                                label='Phone number'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Phonenumber is required!'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Phone number'
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='email'
                                                label='Email'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Firstname is required!'
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: 'Invalid email!'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Email'
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='designation'
                                                label='User designation'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'User designation is required!'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    placeholder='User designation'
                                                    options={designationListing}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='userType'
                                                label='User type'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'User type is required!'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    placeholder='User type'
                                                    options={userTypesByIDList}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='employeeType'
                                                label='Employee type'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Employee type is required!'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    placeholder='Employee type'
                                                    options={[
                                                        {
                                                            value: 'internal',
                                                            label: 'Internal'
                                                        },
                                                        {
                                                            value: 'external',
                                                            label: 'External'
                                                        }
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='holiday'
                                                label='Holiday allowance'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Holiday allowance is required!'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Holiday allowance'
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Title level={5} className='padding-bottom-2'>Address</Title>
                            <Row gutter={24}>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='streetNo'
                                        label='Street Number and Name'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Street number is required!'
                                            }
                                        ]}
                                    >
                                        <Input
                                            showCount={false}
                                            placeholder='Street number and Name'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='city'
                                        label='City'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'City is required!'
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder='City'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='zip'
                                        label='Zip'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Zip code is required!'
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder='Zip'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Title level={5} className='padding-bottom-2'>Shift Management</Title>
                            <Row gutter={24}>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='workType'
                                        label='Work Type'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Wokr type is required!'
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            placeholder='Work type'
                                            options={[
                                                {
                                                    value: 'full time',
                                                    label: 'Full Time'
                                                },
                                                {
                                                    value: 'part time',
                                                    label: 'Part Time'
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='weeklyWorkHours'
                                        label='Total Weekly Hours'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Weekly hours is required!'
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            className='w-100'
                                            placeholder='Total Weekly Hours'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Fragment>
                        <Flex justify='flex-end' gap={10} wrap={true}>
                            <Button danger type='primary' htmlType='button' onClick={() => navigate(-1)}>Cancel</Button>
                            <Button type='primary' htmlType='submit' loading={client?.loading}>Submit</Button>
                        </Flex>
                    </Form>
                </Flex>
            </Flex>
        </Fragment>
    );
};

export default ClientAddUpdateUser;
