import React, { Fragment, useEffect, useState } from 'react';

import { Button, Col, Flex, Input, Row, Form, Card, Image, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setEmail, setIsSuccess, setVerifyEmail, verifyEmailandRequestOtp } from './redux/slice';

import Container from '../../components/container/Container';

import frame from '../../assets/images/forgot-password-frame.svg';
import BackArrowIcon from '../../components/icon/BackArrowIcon';

const { Title, Text } = Typography;

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { auth } = useSelector(state => state);

    const [isEmail, setIsEmail] = useState(false);

    const onFinish = (values) => {
        dispatch(setEmail({ email: values.email }));
        setIsEmail(true);
    };

    useEffect(() => {
        if (isEmail && Object.keys(auth.userEmail).length > 0 && !auth.isVerifyEmail) {
            dispatch(verifyEmailandRequestOtp(auth.userEmail));
        }

        if (auth.isSuccess && auth.isVerifyEmail) {
            form.resetFields();
            navigate('/user/authentication/check-email', { state: auth?.userEmail?.email });
            dispatch(setIsSuccess(false));
            setIsEmail(false);
            dispatch(setVerifyEmail(false));
            dispatch(setEmail({}));
        }
    }, [auth.isSuccess, auth.userEmail, isEmail, auth.isVerifyEmail]);

    return (
        <Fragment>
            <Container fluid>
                <Flex vertical className='vh-100' justify='center'>
                    <Card className='padding-2'>
                        <Row>
                            <Col xxl={12} xl={12} lg={0} md={0} sm={0} xs={0}>
                                <Image
                                    className='w-100 h-100'
                                    preview={false}
                                    src={frame}
                                />
                            </Col>
                            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                                <Flex vertical justify='center' className='h-100'>
                                    <Title level={2} className='margin-0'>Forgot Password?</Title>
                                    <Text type='secondary'>No worries, we’ll send you reset instructions.</Text>
                                    <Form
                                        name='basic'
                                        form={form}
                                        labelCol={{ span: 24, }}
                                        wrapperCol={{ span: 24, }}
                                        onFinish={onFinish}
                                        autoComplete='off'
                                        className='paddingY-3'
                                    >
                                        <Form.Item
                                            label='Email'
                                            name='email'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Email is required!',
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'Invalid email!'
                                                }
                                            ]}
                                        >
                                            <Input placeholder='Enter Your Email' />
                                        </Form.Item>

                                        <Button type='primary' htmlType='submit' block loading={auth?.loading}>
                                            Send Email
                                        </Button>

                                        <Link to={'/user/authentication/sign-in'}>
                                            <Flex gap={10} align='center' className='paddingY-2'>
                                                <BackArrowIcon role='button' />
                                                <Text type='danger' >Back To Login In</Text>
                                            </Flex>
                                        </Link>
                                    </Form>
                                </Flex>
                            </Col>
                        </Row>
                    </Card>
                </Flex>
            </Container>
        </Fragment>
    );
};

export default ForgotPassword;
