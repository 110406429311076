import * as React from 'react';

const MenuIcon = ({
    size = 24,
    color = '#292D32',
    role = 'default',
    style = {},
    ...Rest
}) => {

    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={styles}
            {...Rest}
        >
            <path
                d='M3 7H21'
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap='round'
            />
            <path
                d='M3 12H21'
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap='round'
            />
            <path
                d='M3 17H21'
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap='round'
            />
        </svg>
    );
};

export default MenuIcon;
