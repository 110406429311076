import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    wareHouseList: [],
    wareHouse: {},
    totalRecords: 0,
    loading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
};

export const slice = createSlice({
    name: 'wareHouse',
    initialState,
    reducers: {
        getWareHouseList: (state, action) => {
            state.wareHouse = {};
            state.isUpdating = false;
            state.wareHouseList = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.wareHouse = {};
            state.isSuccess = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        getWareHouse: (state, action) => {
            state.wareHouse = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
    },
});

export const getWareHouseListsData = createAsyncThunk('getWareHouseListsData', (filterData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/whplace/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getWareHouseList(response.data.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            dispatch(getWareHouseList([]));
            dispatch(setTotalrecords(0))
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createWareHouse = createAsyncThunk('createWareHouse', (wareHouseData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/whplace/insert`, wareHouseData?.wareHouse).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getWareHouseListsData({ search: '', filters: { city: [], lagar: [] }, organizer: wareHouseData?.organizer }))
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdWareHouse = createAsyncThunk('getByIdWareHouse', (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/whplace?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getWareHouse(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const updateWareHouse = createAsyncThunk('updateWareHouse', (wareHouseData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/whplace/update`, wareHouseData?.wareHouse).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getWareHouseListsData({ search: '', filters: { city: [], lagar: [] }, organizer: wareHouseData?.organizer }))
            dispatch(setIsSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteWareHouse = createAsyncThunk('deleteWareHouse', (object, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/whplace/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getWareHouseListsData(object));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getWareHouse, setIsUpdating, setIsSuccess, getWareHouseList, setTotalrecords, } = slice.actions;

export default slice.reducer;
