import React, { Fragment, useEffect } from 'react';

import { Button, Checkbox, Col, Divider, Form, Input, Row, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setIsSuccess, createPassword } from './redux/slice';

import Container from '../../components/container/Container';

import { PasswordRegex } from '../../utils/constraints';

const { Title, Text } = Typography;

const SetPassword = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { auth } = useSelector(state => state);

    const onFinish = (values) => {
        dispatch(createPassword({ password: values.password, id: state?.id && state.id, token: state?.token && state?.token }));
    };

    useEffect(() => {
        if (auth.isSuccess) {
            form.resetFields();
            navigate('/user/welcome');
            dispatch(setIsSuccess(false));
        }
    }, [auth.isSuccess]);

    return (
        <Fragment>
            <Container className='vh-100' justify='center' align='center'>
                <Row justify={'center'} align={'middle'} className='vh-100'>
                    <Col xxl={6} xl={8} lg={12} md={16} sm={16} xs={24} className='bg-white padding-2 rounded-2'>
                        <Title level={4} type='success' className='paddingY-1'>Set Up Your Password</Title>
                        <Text>
                            Please create a secure password including <br /> the following criteia below
                        </Text>
                        <Divider />
                        <Form
                            name='setpassword'
                            onFinish={onFinish}
                            layout='vertical'
                            requiredMark={false}
                            form={form}
                        >
                            <Form.Item
                                name='password'
                                label='New Password'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Password required!',
                                    },
                                    {
                                        pattern: new RegExp(PasswordRegex),
                                        message: 'Invalid password!'
                                    }
                                ]}
                                hasFeedback
                            >
                                <Input.Password placeholder='Password' />
                            </Form.Item>

                            <Form.Item
                                name='confirmPassword'
                                label='Confirm Password'
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: ' Confirmpassword required!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Your password and confirmation password do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder='Confirm Password' />
                            </Form.Item>
                            <Text type='secondary'>
                                Your password should contain at least one number, one special character and a letter in uppercase.
                            </Text>
                            <Divider />
                            <Form.Item
                                name='acceptConditions'
                                valuePropName='checked'
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms and Conditions!')),
                                    },
                                ]}
                            >
                                <Checkbox>I agree to the Terms and Conditions</Checkbox>
                            </Form.Item>

                            <Button type='primary' htmlType='submit' loading={auth?.loading}>
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default SetPassword;
