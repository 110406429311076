import React, { Fragment, useEffect, useState } from 'react';

import { Button, Col, Flex, Input, Row, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { checkInvitation, resendInvitation, setInvitationMsg, setIsInvitation, setIsSuccess } from '../auth/redux/slice';

import DataTable from '../../components/data-table';
import CommonPopconfirm from '../../components/custom-popconfirm';
import { getPackagesListing } from '../../redux/slice/global';
import { deleteClient, getClientsListData } from './redux/slice';

import DeleteIcon from '../../components/icon/DeleteIcon';
import EditIcon from '../../components/icon/EditIcon';
import SearchIcon from '../../components/icon/SearchIcon';
import CloseIcon from '../../components/icon/CloseIcon';
import EmailIcon from '../../components/icon/EmailIcon';

import { lightPrimary, lightSecondary, lightYellow } from '../../utils/theme/config';

const { Title, Text } = Typography;

const Client = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { client, auth, listing } = useSelector(state => state);

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [administratorsList, setAdministratorsList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [search, setSearch] = useState('');
    const [userId, setUserId] = useState('')
    const [visiblePopconfirm, setVisiblePopconfirm] = useState({});
    const [packagesList, setPackagesList] = useState([]);
    const [permission, setPermission] = useState({});

    useEffect(() => {
        setPermission(listing?.hasMenuPermission?.Client?.permissions);
    }, [listing?.hasMenuPermission])

    useEffect(() => {
        getAdministrators();
    }, [pageSize, page, sortBy, order, search]);

    useEffect(() => {
        getPackageLists()
    }, [])

    const getPackageLists = () => {
        dispatch(getPackagesListing());
    };

    useEffect(() => {
        if (listing.listing?.packages?.length > 0) {
            setPackagesList(listing.listing.packages);
        }
    }, [listing.listing.packages]);

    const getAdministrators = () => {
        dispatch(getClientsListData({
            sortBy: sortBy,
            order: order,
            search: search?.trim(),
            page: page,
            limit: pageSize,
            organizer: auth.decodeToken?.organizer ? auth.decodeToken?.organizer : null
        }))
    };

    useEffect(() => {
        setAdministratorsList(client.clientsList);
        setTotalRecords(client?.totalRecords);
    }, [client.clientsList, client.totalRecords, search,]);

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    useEffect(() => {
        document.documentElement.scrollTop = document.documentElement.administratorHeight;
        document.documentElement.scrollLeft = document.documentElement.administratorWidth;
    }, []);

    const handleCheckInvitation = (userId) => {
        setUserId(userId);
        dispatch(checkInvitation(userId))
            .then((result) => {
                if (result.meta.requestStatus === 'fulfilled') {
                    if (Object.keys(auth?.invitationMsg).length > 0) {
                        setVisiblePopconfirm(prevState => ({
                            ...prevState,
                            [userId]: true
                        }));
                    }
                }
            });
    };

    const handleCancel = (userId) => {
        setVisiblePopconfirm(prevState => ({
            ...prevState,
            [userId]: false
        }));
        setUserId('');
    };

    const handleConfirm = (userId) => {
        dispatch(resendInvitation(userId));
        dispatch(setInvitationMsg({}));
        handleCancel(userId);
    };

    useEffect(() => {
        if (Object.keys(auth?.invitationMsg).length > 0 && auth?.isInvitation && auth.isSuccess) {
            setVisiblePopconfirm(prevState => ({
                ...prevState,
                [userId]: true
            }));
            setUserId('');
            dispatch(setIsSuccess(false));
            dispatch(setIsInvitation(false));
        }
    }, [auth?.invitationMsg, auth?.isInvitation, auth.isSuccess]);

    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'company',
            key: 'company',
            width: '150px',
            sorter: (_, __, order) => handleSortFunction(order, 'company'),
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '150px',
            sorter: (_, __, order) => handleSortFunction(order, 'email'),
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Subscription Name',
            dataIndex: 'package',
            width: '200px',
            key: 'package',
            sorter: (_, __, order) => handleSortFunction(order, 'package'),
        },
        {
            title: 'User Limit',
            dataIndex: 'userLimit',
            width: '100px',
            key: 'maxUsers',
            sorter: (_, __, order) => handleSortFunction(order, 'userLimit'),
            render: (_, record) => record?.userLimit || '-'
        },
        {
            title: 'User type',
            dataIndex: 'userType',
            key: 'userType',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'userType'),
            render: (type) => {
                return <Tag color={lightPrimary} className='text-center'>{type}</Tag>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '250px',
            sorter: (_, __, order) => handleSortFunction(order, 'status'),
            render: (_, record) => {
                return (
                    <Fragment>
                        {
                            Number(record.status) === 1 ?
                                <Tag color={lightPrimary} className='text-center'>Active</Tag> :
                                Number(record.status) === 2 ?
                                    <Tag color={lightYellow} className='text-center'>Waiting For Confirmation</Tag> :
                                    <Tag color={lightSecondary} className='text-center'>Deactivate</Tag>
                        }
                    </Fragment>
                );
            }
        },
    ];

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: <Flex justify='center'><Text>Action</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => {
                return (
                    < Flex justify='center'>
                        {
                            Number(record?.status) === 2 &&
                            <Fragment>
                                <CommonPopconfirm
                                    visible={!!visiblePopconfirm[record?._id]}
                                    title={Object.keys(auth?.invitationMsg).length > 0 && auth?.invitationMsg?.title}
                                    description={Object.keys(auth?.invitationMsg).length > 0 && auth?.invitationMsg?.description}
                                    onConfirm={() => handleConfirm(record?._id)}
                                    onCancel={() => handleCancel(record?.id)}
                                    icon={null}
                                    okText='OK'
                                    cancelText='Cancel'
                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                    onVisibleChange={(visible) => !visible && handleCancel(record?._id)}
                                >
                                    <Button
                                        type='text'
                                        className='padding-1'
                                        onClick={() => handleCheckInvitation(record._id)}
                                    >
                                        <EmailIcon role='button' />
                                    </Button>
                                </CommonPopconfirm>
                                {
                                    permission?.canUpdate &&
                                    <Button
                                        type='text'
                                        className='padding-1'
                                        onClick={() => navigate(`/client/edit/${record?._id}`)}>
                                        <EditIcon role='button' />
                                    </Button>
                                }
                                {
                                    permission?.canDelete &&
                                    <Fragment>
                                        {
                                            Number(record?.status) !== 3 ?
                                                <CommonPopconfirm
                                                    title='Deactivate the administrator'
                                                    description='Are you sure you want to deactivate this administrator?'
                                                    onConfirm={() => dispatch(deleteClient({
                                                        id: record?._id,
                                                        sortBy: '',
                                                        order: '',
                                                        search: '',
                                                        page: page,
                                                        limit: pageSize,
                                                        organizer: auth.decodeToken?.organizer ? auth.decodeToken?.organizer : null
                                                    }))}
                                                    icon={null}
                                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                                >
                                                    <Button
                                                        type='text'
                                                        className='padding-1'>
                                                        <DeleteIcon
                                                            role='button'
                                                        />
                                                    </Button>
                                                </CommonPopconfirm>
                                                :
                                                <CommonPopconfirm
                                                    icon={null}
                                                    title='Activate the administrator'
                                                    description='Are you sure you want to activate this administrator?'
                                                    onConfirm={() => dispatch(deleteClient({
                                                        id: record?._id,
                                                        sortBy: '',
                                                        order: '',
                                                        search: '',
                                                        page: page,
                                                        limit: pageSize,
                                                        organizer: auth.decodeToken?.organizer ? auth.decodeToken?.organizer : null
                                                    }))}
                                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                                >
                                                    <Button
                                                        type='text'
                                                        className='padding-1'
                                                    >
                                                        <CloseIcon role='button' />
                                                    </Button>
                                                </CommonPopconfirm>
                                        }
                                    </Fragment>
                                }
                            </Fragment>

                        }
                        {
                            Number(record?.status) !== 2 &&
                            <Fragment>
                                {
                                    permission?.canUpdate &&
                                    <Button
                                        type='text'
                                        className='padding-1'
                                        onClick={() => navigate(`/client/edit/${record?._id}`)}>
                                        <EditIcon role='button' />
                                    </Button>

                                }
                                {
                                    permission?.canDelete &&
                                    <Fragment>
                                        {
                                            Number(record?.status) !== 3 ?
                                                <CommonPopconfirm
                                                    title='Deactivate the administrator'
                                                    description='Are you sure you want to deactivate this administrator?'
                                                    onConfirm={() => dispatch(deleteClient({
                                                        id: record?._id,
                                                        sortBy: '',
                                                        order: '',
                                                        search: '',
                                                        page: page,
                                                        limit: pageSize,
                                                        organizer: auth.decodeToken?.organizer ? auth.decodeToken?.organizer : null
                                                    }))}
                                                    icon={null}
                                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                                >
                                                    <Button
                                                        type='text'
                                                        className='padding-1'>
                                                        <DeleteIcon
                                                            role='button'
                                                        />
                                                    </Button>
                                                </CommonPopconfirm>
                                                :
                                                <CommonPopconfirm
                                                    icon={null}
                                                    title='Activate the administrator'
                                                    description='Are you sure you want to activate this administrator?'
                                                    onConfirm={() => dispatch(deleteClient({
                                                        id: record?._id,
                                                        sortBy: '',
                                                        order: '',
                                                        search: '',
                                                        page: page,
                                                        limit: pageSize,
                                                        organizer: auth.decodeToken?.organizer ? auth.decodeToken?.organizer : null
                                                    }))}
                                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                                >
                                                    <Button
                                                        type='text'
                                                        className='padding-1'
                                                    >
                                                        <CloseIcon role='button' />
                                                    </Button>
                                                </CommonPopconfirm>
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </Flex >
                );
            },
        })
    }

    // Pagination function 
    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    return (
        <Fragment>
            <Flex vertical gap={24} className='change-tag'>
                <Row
                    justify='space-between'
                    align='middle'
                    className='bg-white padding-1 rounded-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none'>
                                <Title level={4} type='success'>Client List</Title>
                            </Col>
                            <Col flex='auto' xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Input
                                    value={search}
                                    placeholder='Search here...'
                                    onChange={(e) => setSearch(e.target.value)}
                                    prefix={<SearchIcon color='#000' size={20} />}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                        <Flex justify='end'>
                            {
                                (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                                <Button onClick={() => navigate('/client/add')} >Add New Client</Button>
                            }
                        </Flex>
                    </Col>
                </Row>
                <Flex>
                    <DataTable
                        columns={columns}
                        pageSize={pageSize}
                        currentPage={page}
                        total={0}
                        scrollX={900}
                        showSorterTooltip={false}
                        isPagination={true}
                        handleOnChange={handleOnChange}
                        data={administratorsList}
                        rowKey={(record) => record?._id}
                    />
                </Flex>
            </Flex>
        </Fragment>
    );
};

export default Client;
