import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    packageList: [],
    package: {},
    totalRecords: 0,
    loading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
};

export const slice = createSlice({
    name: 'packages',
    initialState,
    reducers: {
        getPackageList: (state, action) => {
            state.package = {};
            state.isUpdating = false;
            state.packageList = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.package = {};
            state.isSuccess = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        getPackage: (state, action) => {
            state.package = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
    },
});

export const getPackageListData = createAsyncThunk('getPackageListData', (filterData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/package/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getPackageList(response.data.records));
            dispatch(setTotalrecords(response.data.totalRecords));
        } else {
            dispatch(getPackageList([]));
            dispatch(setTotalrecords(0))
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createPackages = createAsyncThunk('createPackages', (packageData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/package/insert`, packageData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getPackageListData({ search: '', sortBy: '', order: '', page: 1, limit: 10 }))
            dispatch(setIsSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdPackage = createAsyncThunk('getByIdPackage', (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/package?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getPackage(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const updatePackage = createAsyncThunk('updatePackage', (packageData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/package/update`, packageData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getPackageListData({ search: '', sortBy: '', order: '', page: 1, limit: 10 }))
            dispatch(setIsSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deletePackage = createAsyncThunk('deletePackage', (object, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/package/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getPackageListData(object))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getPackage, setIsUpdating, setIsSuccess, getPackageList, setTotalrecords } = slice.actions;

export default slice.reducer;
