import React, { Fragment, useEffect, useState } from 'react'

import { Button, Col, Divider, Flex, Form, Input, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { createStammUser, getByIdStammUser, getStammUser, setGetEditStammUserId, setIsSuccess, setIsUpdating, updateStammUser } from './redux/slice';
import { setLoginUserId, setLoginUserRole } from '../user/redux/slice';

import DeleteIcon from '../../components/icon/DeleteIcon';
import PlusIcon from '../../components/icon/PlusIcon';

const { Title, Text } = Typography;

const GeneralInformation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { stammdaten } = useSelector(state => state)

    const [form] = Form.useForm();
    const { id } = useParams();
    const [modalType, setModalType] = useState(false);

    const [loginUser, setLoginUser] = useState({});

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
            dispatch(setLoginUserId(user._id))
            dispatch(setLoginUserRole(user.role))
        }
    }, [])

    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(setIsUpdating(true));
            dispatch(setGetEditStammUserId(id))
            dispatch(getByIdStammUser(id));
        }
    }, [id]);

    const handleAdd = (add) => {
        const fieldsValue = form.getFieldsValue();
        const contacts = fieldsValue.otherContact || [];

        const lastContact = contacts[contacts.length - 1];

        if (!lastContact) {
            if ((!lastContact?.name || !lastContact?.email || !lastContact?.phone) && contacts?.length !== 0) {
                form.setFields(
                    [
                        {
                            name: ['otherContact', contacts.length - 1, 'name'],
                            errors: !lastContact?.name ? ['Please enter the contact person name'] : [],
                        },
                        {
                            name: ['otherContact', contacts.length - 1, 'email'],
                            errors: !lastContact?.email ? ['Please enter the contact email'] : [],
                        },
                        {
                            name: ['otherContact', contacts.length - 1, 'phone'],
                            errors: !lastContact?.phone ? ['Please enter the contact number'] : [],
                        },
                    ]
                );
            } else {
                add();
            }
        } else {
            add();
            form.setFields(
                [
                    {
                        name: ['otherContact', contacts.length - 1, 'name'],
                        errors: false,
                    },
                    {
                        name: ['otherContact', contacts.length - 1, 'email'],
                        errors: false,
                    },
                    {
                        name: ['otherContact', contacts.length - 1, 'phone'],
                        errors: false,
                    },
                ]
            );
        }
    };

    const handleOnFinish = (values) => {
        if (id) {
            dispatch(setIsUpdating(false));
        }
        dispatch(getStammUser({
            ...values,
            address: { streetNo: values?.streetNo, city: values?.city, zip: values?.zip },
            otherContact: values?.otherContact.filter(contact => contact.name && contact.phone && contact?.email),
            organizer: loginUser?.organizer
        }));
    }

    useEffect(() => {
        if (Object.keys(stammdaten?.stammUser).length > 0) {
            if (!modalType) {
                dispatch(createStammUser({ stammUserData: stammdaten?.stammUser, organizerId: loginUser?.organizer }));
            } else {
                if (stammdaten.isUpdating) {
                    form.setFieldsValue({ ...stammdaten?.stammUser, streetNo: stammdaten?.stammUser?.address?.streetNo, city: stammdaten?.stammUser?.address?.city, zip: stammdaten?.stammUser?.address?.zip });
                } else {
                    dispatch(updateStammUser({ stammUserData: { ...stammdaten.stammUser, id: id }, organizerId: loginUser?.organizer }));
                }
            }
        }
        if (stammdaten.isSuccess) {
            handleClear();
            navigate('/stammdaten');
            setModalType(false);
            dispatch(setIsSuccess(false));
        }
    }, [stammdaten.isUpdating, stammdaten.stammUser, stammdaten.isSuccess]);

    const handleClear = () => {
        form.resetFields();
    };

    return (
        <Fragment>
            <Title level={4} type='success' className='paddingY-1'>{id ? 'Edit Stamm User' : 'Add New Stamm User'}</Title>
            <Divider />
            <Form
                form={form}
                layout='vertical'
                className='paddingY-2'
                onFinish={handleOnFinish}
                requiredMark={(label, isRule) => {
                    return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                }}
            >
                <Row gutter={24}>
                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24} >
                        <Form.Item
                            name='company'
                            label='Company Name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Company is required!'
                                }
                            ]}
                        >
                            <Input
                                placeholder='Company'
                            />
                        </Form.Item>
                    </Col>
                    <Col xxl={8} xl={12} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='firstName'
                            label='First Name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your First Name!',
                                },
                            ]}
                        >
                            <Input placeholder='Please Enter First Name' />
                        </Form.Item>
                    </Col>

                    <Col xxl={8} xl={12} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='lastName'
                            label='Last Name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Last Name!',
                                },
                            ]}
                        >
                            <Input placeholder='Please Enter Last Name' />
                        </Form.Item>
                    </Col>

                    <Col xxl={8} xl={12} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='email'
                            label='Email'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Email!',
                                },
                            ]}
                        >
                            <Input placeholder='Please Enter Email' />
                        </Form.Item>
                    </Col>

                    <Col xxl={8} xl={12} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item name='phone' label='Phone number'>
                            <Input placeholder='Please Enter Phone number' />
                        </Form.Item>
                    </Col>
                </Row>
                <Title level={5} className='padding-bottom-2'>Address</Title>
                <Row gutter={24}>
                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='streetNo'
                            label='Street number & Name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Street number & Name is required!'
                                }
                            ]}
                        >
                            <Input
                                showCount={false}
                                placeholder='Street number'
                            />
                        </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='city'
                            label='City'
                            rules={[
                                {
                                    required: true,
                                    message: 'City is required!'
                                }
                            ]}
                        >
                            <Input
                                placeholder='City'
                            />
                        </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='zip'
                            label='Zip'
                            rules={[
                                {
                                    required: true,
                                    message: 'Zip is required!'
                                }
                            ]}
                        >
                            <Input
                                placeholder='Zip'
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />

                <Row>
                    <Col xs={24} sm={24} lg={24} md={24} xl={24} xxl={24}>
                        <Form.List
                            name='otherContact'
                            initialValue={[{ name: '', email: '', phone: '' }]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    <Flex className='paddingY-1' align='center' justify='space-between'>
                                        <Title level={4} type='success' className='paddingY-1'>
                                            Add Multiple Contacts
                                        </Title>

                                        <PlusIcon
                                            role='button'
                                            onClick={() => handleAdd(add)}
                                        />
                                    </Flex>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <Row key={key} gutter={20}>
                                            <Col xxl={7} xl={7} lg={12} md={11} sm={22} xs={22}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'name']}
                                                    label='Person name'
                                                >
                                                    <Input
                                                        placeholder='Person name'
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xxl={7} xl={7} lg={12} md={11} sm={22} xs={22}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'email']}
                                                    label='Person email'
                                                >
                                                    <Input
                                                        placeholder='Person email'
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xxl={7} xl={7} lg={12} md={11} sm={22} xs={22}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'phone']}
                                                    label='Person contact number'
                                                >
                                                    <Input
                                                        type='tel'
                                                        placeholder='Person contact number'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                                                <Flex align='center' justify='center' className='h-100'>
                                                    <Button
                                                        type='danger'
                                                        icon={<DeleteIcon role='button' />}
                                                        onClick={() => {
                                                            remove(name);

                                                        }}
                                                    />
                                                </Flex>
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Col>

                    <Col xs={24} sm={24} lg={24} md={24} xl={24} xxl={24}>
                        <Flex justify='flex-end' gap={10} className='paddingY-2'>
                            <Button danger type='primary' htmlType='button' onClick={() => navigate(-1)}>
                                Cancel
                            </Button>
                            <Button type='primary' htmlType='submit'>
                                Submit
                            </Button>
                        </Flex>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    );
};

export default GeneralInformation;
