import * as React from 'react';

const DownArrowIcon = ({
    size = 17,
    color = '#A20417',
    role = 'default',
    style = {},
    ...Rest
}) => {

    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 16 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={styles}
            {...Rest}
        >
            <path
                d='M4 6.12549L8 10.1639L12 6.12549'
                stroke={color}
                strokeWidth={1.33333}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default DownArrowIcon;
