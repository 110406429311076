import { Divider, Flex, notification, Typography } from 'antd';

import CloseIcon from '../icon/CloseIcon';

import '../../styles/components/notification/notification.scss';

const { Text } = Typography

const openNotification = ({ placement = 'bottomRight', message = 'No message provided', type = 'info' } = {}) => {
    const api = notification;

    api.open({
        message: (
            <Flex justify='space-between' align='center'>
                <Text>{message}</Text>
                <Divider type='vertical' className='notification-divider' />
            </Flex>
        ),
        placement,
        closeIcon: <CloseIcon color='black' role='button' />,
        className: type === 'success' ? 'success-notification' : 'error-notification',
    });
};

export default openNotification;
