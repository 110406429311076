import React, { Fragment, useEffect, useState } from 'react';

import { Button, Checkbox, Col, Flex, Form, Input, Row, List, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import openNotification from '../../components/notification';

import { createPackages, getByIdPackage, getPackage, setIsSuccess, setIsUpdating, updatePackage } from './redux/slice';
import { getMenuForPackageListing } from '../../redux/slice/global';

import BackIcon from '../../components/icon/BackIcon';

const { Text } = Typography;

const AddUpdatePackages = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { packages, listing } = useSelector(state => state);
    const { id } = useParams();

    const [checkedValues, setCheckedValues] = useState([]);
    const [modalType, setModalType] = useState(false);
    const [menuOptionList, setMenuOptionList] = useState([]);
    const defaultLabels = ['Dashboard', 'User Management'];

    useEffect(() => {
        if (!id) {
            const defaultChecked = menuOptionList
                .filter(item => defaultLabels.includes(item.label))
                .map(item => item.value);
            setCheckedValues(defaultChecked);
        }
    }, [menuOptionList])

    const onFinish = (values) => {
        const dashboardChecked = checkedValues.some(value =>
            menuOptionList.find(option => option.value === value)?.label.toLowerCase() === 'dashboard'
        );

        if (!dashboardChecked) {
            openNotification({ message: 'Please select dashboard!', type: 'error' });
        } else {
            if (id) {
                dispatch(setIsUpdating(false));
            }

            dispatch(getPackage({
                name: values.name,
                menu: [...checkedValues]
            }));
        }
    };

    useEffect(() => {
        getMenuOptionLists();
    }, [])

    // Get Menu Options
    const getMenuOptionLists = () => {
        dispatch(getMenuForPackageListing());
    }

    // Set Menu Options
    useEffect(() => {
        if (listing.listing?.menuForPackage?.length > 0) {
            setMenuOptionList(listing.listing.menuForPackage);
        }
    }, [listing.listing.menuForPackage])

    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(setIsUpdating(true));
            dispatch(getByIdPackage(id));
        }
    }, [id]);

    // Create and Update Packages
    useEffect(() => {
        if (Object.keys(packages.package).length > 0) {
            if (!modalType) {
                dispatch(createPackages(packages.package));
            } else {
                if (packages.isUpdating) {
                    form.setFieldsValue({
                        name: packages?.package?.name,
                    });
                    setCheckedValues(packages?.package?.menu);
                } else {
                    dispatch(updatePackage({ ...packages?.package, id: id }));
                }
            }
        }
        if (packages.isSuccess) {
            handleClear();
            navigate('/packages');
            setModalType(false);
            dispatch(setIsSuccess(false));
        }
    }, [packages.isUpdating, packages.package, packages.isSuccess]);

    // Clear Form 
    const handleClear = () => {
        form.resetFields();
        setCheckedValues([]);
        navigate(-1)
    };

    // Get Checkbox Values
    const handleCheckboxChange = (checked, key) => {
        setCheckedValues(prev =>
            checked ? [...prev, key] : prev.filter(item => item !== key)
        );
    };

    return (
        <Fragment>
            <Flex vertical gap={24}>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => navigate('/packages')}
                    />
                </Flex>

                <Flex vertical gap={16} className='bg-white padding-2'>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form
                                form={form}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                onFinish={onFinish}
                                autoComplete='off'
                                requiredMark={(label, isRule) => {
                                    return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                                }}
                            >
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                        <Row>
                                            <Col>
                                                <Form.Item
                                                    label={id ? 'Edit Package name' : 'Add Package name'}
                                                    name='name'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Enter your name!',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder='Please enter' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                                        <List
                                            className='bg-gray padding-2 rounded-2'
                                            dataSource={menuOptionList}
                                            renderItem={item => {
                                                const isDisabled = ['Dashboard', 'User Management'].includes(item.label);

                                                return (
                                                    <List.Item>
                                                        <List.Item.Meta title={item.label} className='paddingX-1' />
                                                        <Checkbox
                                                            onChange={e => handleCheckboxChange(e.target.checked, item.value)}
                                                            checked={checkedValues.includes(item.value)}
                                                            disabled={isDisabled} // Conditionally disable the checkbox
                                                        >
                                                        </Checkbox>
                                                    </List.Item>
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Flex justify='flex-end' gap={10} wrap={true} className='paddingY-2'>
                                    <Button danger type='primary' htmlType='button' onClick={handleClear}>Cancel</Button>
                                    <Button type='primary' htmlType='submit' loading={packages?.loading && packages?.loading}>Submit</Button>
                                </Flex>
                            </Form>
                        </Col>
                    </Row>
                </Flex>
            </Flex>
        </Fragment >
    )
};

export default AddUpdatePackages;
