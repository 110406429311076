import React, { Fragment, useEffect, useState } from 'react';
import { Layout } from 'antd';

import SidebarComponent from './sidebar';
import HeaderComponent from './header';
import ContenetComponent from './content';

import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';

import { setLoginUserId, setLoginUserPackage, setLoginUserRole } from '../pages/user/redux/slice';
import { getDecodeToken, onOrganizerBoardring, setIsAuthorize } from '../pages/auth/redux/slice';

import { privateRoutes } from '../navigations/navigations';
import Designation from '../pages/designation/Designation';
import { Link } from 'react-router-dom';
import { setMenuPermission } from '../redux/slice/global';

const Wrapper = ({ children }) => {
    const dispatch = useDispatch()
    const { auth } = useSelector(state => state)
    const [collapsed, setCollapsed] = useState(false);
    const [loginUser, setLoginUser] = useState({});
    const [filteredRoutes, setFilteredRoutes] = useState([]);

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        if (token) {
            const user = jwtDecode(token);
            if (user._id && auth.isAuthorize !== '') {
                user.organizer && dispatch(onOrganizerBoardring(user._id))
            }

            dispatch(getDecodeToken(user))
            setLoginUser(user);
            dispatch(setIsAuthorize(user.isInitialSignIn))
            dispatch(setLoginUserId(user._id))
            dispatch(setLoginUserRole(user.role))
            dispatch(setLoginUserPackage(user.package))
        }
    }, [])

    useEffect(() => {
        if (loginUser) {
            const getCookie = (name) => {
                const value = `; ${document.cookie}`;
                const parts = value?.split(`; ${name}=`);
                if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
                return null;
            }

            const token = getCookie('token');
            const user = jwtDecode(token);

            if (user._id && auth.isAuthorize !== '') {
                user?.organizer && dispatch(onOrganizerBoardring(user._id));
            }

            if (user) {
                const menuObject = user?.permissions[0]?.menu?.reduce((acc, current) => {
                    const menuKey = current?.menu?.replace(/[\s.]+/g, "");
                    acc[menuKey] = {
                        permissions: current.permissions,
                        children: current?.children?.reduce((childAcc, child) => {
                            const childKey = child?.menu?.replace(/[\s.]+/g, "");
                            childAcc[childKey] = child?.permissions;
                            return childAcc;
                        }, {}),
                    };
                    return acc;
                }, {});
                
                dispatch(setMenuPermission(menuObject))
            }


            const filterRoutesByPermission = (routes, permissions) => {
                return routes.map(route => {
                    const matchingPermission = permissions.find(permission => route?.label && (permission.menu === route.menu && permission?.permissions?.hasAccess));

                    if (matchingPermission) {
                        if (route.children && route.children.length > 0) {
                            const filteredChildren = route.children.filter(child =>
                                matchingPermission.children &&
                                matchingPermission.children.some(permissionChild =>
                                    permissionChild.menu === child.menu && child.label
                                )
                            );

                            (user?.organizer && route?.menu === 'User Management') && filteredChildren?.push({
                                to: 'designation',
                                label: <Link to='/user/designation'>Designation</Link>,
                                menu: 'Designation',
                                key: '23',
                                element: <Designation />,
                            })

                            return {
                                ...route,
                                children: filteredChildren
                            };
                        }
                        return route;
                    }
                    return null;
                }).filter(route => route !== null);
            };

            const filteredRoutes = filterRoutesByPermission(privateRoutes, user?.permissions[0]?.menu);

            if (user?.organizer && auth?.isAuthorize) {
                setFilteredRoutes(filteredRoutes?.map((item) => {
                    if (item.label?.props?.children === 'Profile Settings') {
                        return item;
                    }
                    else {
                        return {
                            ...item,
                            disabled: auth?.isAuthorize,
                            label: auth?.isAuthorize ? item.label?.props?.children ? item.label?.props?.children : item.label : item.label
                        }
                    }
                })?.map(i => {
                    const { children, ...route } = i
                    return {
                        ...route,
                        ...(children?.length > 0) && { children: children }
                    }
                })
                );
            } else {
                setFilteredRoutes(filteredRoutes?.map(i => {
                    const { children, ...route } = i
                    return {
                        ...route,
                        ...(children?.length > 0) && { children: children }
                    }
                })
                );
            }
        }

    }, [loginUser, auth?.isAuthorize]);

    return (
        <Fragment>
            <Layout className='vh-100 flex gap-1 padding-2'>
                <SidebarComponent collapsed={collapsed} setCollapsed={setCollapsed} filteredRoutes={filteredRoutes} />
                <Layout>
                    <HeaderComponent collapsed={collapsed} setCollapsed={setCollapsed} />
                    <ContenetComponent children={children} />
                </Layout>
            </Layout>
        </Fragment>
    )
}

export default Wrapper;
