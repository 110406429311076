import React, { Fragment, useEffect, useRef } from 'react'

import H from '@here/maps-api-for-javascript';

import openNotification from '../notification';

import workIcon from '../../assets/images/workIcon.svg';
import breakIcon from '../../assets/images/breakIcon.svg';

const HereMap = ({ isExpand, record }) => {
    const mapRef = useRef(null);
    const map = useRef(null);
    const platform = useRef(null);

    useEffect(() => {
        const initMap = async () => {
            try {
                if (!map.current && Object.keys(record).length > 0) {
                    platform.current = new H.service.Platform({
                        apikey: process.env.REACT_APP_API_KEY,
                    });

                    const defaultLayers = platform.current.createDefaultLayers({
                        pois: true,
                    });

                    const newMap = new H.Map(
                        mapRef.current,
                        defaultLayers.vector.normal.map,
                        {
                            zoom: 14,
                            center: {
                                lat: record?.timeEntries[0]?.lat,
                                lng: record?.timeEntries[0]?.long
                            }

                        }
                    );

                    new H.mapevents.Behavior(
                        new H.mapevents.MapEvents(newMap)
                    );

                    H.ui.UI.createDefault(newMap, defaultLayers);

                    map.current = newMap;

                    const createMarker = (lat, lng, svgMarkup) => {
                        const icon = new H.map.Icon(svgMarkup);
                        const marker = new H.map.Marker({ lat, lng }, { icon });
                        newMap.addObject(marker);
                    }

                    record?.timeEntries?.forEach(item => {
                        let svgMarkup;

                        // Check the type and set the appropriate SVG
                        if (item?.type === 'work') {
                            svgMarkup = workIcon;
                        } else if (item?.type === 'break') {
                            svgMarkup = breakIcon;
                        }

                        // Add marker with the chosen SVG markup
                        createMarker(item?.lat, item?.long, svgMarkup);
                    });
                }
            } catch (error) {
                openNotification({ message: error?.message, type: 'error' })
            }
        };

        initMap();
    }, [isExpand, record]);

    return (
        <Fragment>
            <div style={{ height: '300px' }} ref={mapRef} className='rounded-2 bg-primary-light'></div>
        </Fragment>
    )
}

export default HereMap