import React from 'react';

import '../../styles/components/container/container.scss';

const Container = ({
    children,
    fluid = false
}) => {
    return (
        <div className={!fluid ? 'container-fluid' : 'container'}>
            {children}
        </div>
    );
};

export default Container;
