import React, { Fragment } from 'react';

import { Col, Flex, Row, Tabs } from 'antd';
import { useSelector } from 'react-redux';

import GeneralSetting from './GeneralSetting';
import AddNewVaction from './AddNewVaction';
import WorkHistory from './WorkHistory';
import PlatformUserGeneralSettings from './PlatformUserGeneralSettings';

const ProfileSettings = () => {
    const { auth } = useSelector(state => state)

    return (
        <Fragment>
            <Flex className='bg-white h-100 padding-1 rounded-2' style={{ overflow: 'auto' }}>
                <Row className='w-100'>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        {
                            auth?.decodeToken?.organizer ?
                                <Tabs
                                    type='card'
                                    size='large'
                                    defaultActiveKey='1'
                                    items={[
                                        {
                                            label: 'General Setting',
                                            key: '1',
                                            children: <GeneralSetting />,
                                        },
                                        {
                                            label: 'Add New Vaction',
                                            key: '2',
                                            children: <AddNewVaction />,
                                            disabled: auth?.decodeToken?.organizer && auth?.isAuthorize
                                        },
                                        {
                                            label: 'Work History',
                                            key: '3',
                                            children: <WorkHistory />,
                                            disabled: auth?.decodeToken?.organizer && auth?.isAuthorize
                                        }
                                    ]}
                                />
                                :
                                <Tabs
                                    defaultActiveKey='1'
                                    items={[
                                        {
                                            label: 'General Setting',
                                            key: '1',
                                            children: <PlatformUserGeneralSettings />,
                                        },
                                    ]}
                                />
                        }
                    </Col>
                </Row>
            </Flex>
        </Fragment>
    );
};

export default ProfileSettings;
